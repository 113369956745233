import { Alert, Col, Form, Heading, Row, TextInput } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { updateUserProfile } from '../../api/UpdateProfileApi';
import useAuthentication from '../../hooks/useAuthentication';
import useExperience from '../../hooks/useExperience';
import getDisplayText from '../../utils/languageDisplay';
import { compareEmails, formatName, globalPhoneNumberFormat, isNotEmpty, phoneNumberValidator, validateEmail, } from '../../utils/validationUtils';
import { FormButton } from '../extraFormControls/FormButton';
import { UserProfileInformationDisplayTextEn as LanguageEn } from './language/UserProfileInformationDisplayTextEn';
import { UserProfileInformationDisplayTextEs as LanguageEs } from './language/UserProfileInformationDisplayTextEs';
import usePortalMessenger from '../../hooks/usePortalMessenger';
//  @ts-ignore
import Styles from './UserProfileInformation.scss';
import { TEALIUM_EVENT } from '../../constants/tealiumConstants';
import useTealium from '../../hooks/useTealium';
const StyledEmployerNameLabel = styled('label') ``;
const StyledRowEmployerName = styled(Row) `
  margin-bottom: 25px;
`;
const StyledSubHeading = styled(Heading) `
  font-size: 1.0625rem !important;
`;
const StyledHRElement = styled('hr') `
  margin: 0.75rem 0 0;
`;
const UserProfileInformation = () => {
    const { logout, user, authHeader } = useAuthentication();
    const { language } = useExperience();
    const { trackEvent } = useTealium();
    const { addMessage } = usePortalMessenger();
    const isSsoUser = user.ssoUser;
    let emailAddressChanged = false;
    let email = user.emailAddress;
    const [newEmailAddress, setNewEmailAddress] = useState(email);
    const [hasChanged, setHasChanged] = useState(false);
    const checkForFormChange = (values) => {
        if (user) {
            const { firstName: userFirstName = '', lastName: userLastName = '', phoneNumber: userPhoneNumber = '', emailAddress: userEmailAddress = '', } = user;
            const { firstName: inputFirstName = '', lastName: inputLastName = '', phoneNumber: inputPhoneNumber = '', emailAddress: inputEmailAddress = '', } = values;
            const firstNameChanged = inputFirstName !== userFirstName && inputFirstName !== '';
            const lastNameChanged = inputLastName !== userLastName && inputLastName !== '';
            const phoneNumberChanged = userPhoneNumber
                ? inputPhoneNumber.replace(/-/g, '') !== userPhoneNumber.replace(/-/g, '') && inputPhoneNumber !== ''
                : false;
            emailAddressChanged = inputEmailAddress !== userEmailAddress && inputEmailAddress !== '';
            if (emailAddressChanged) {
                email = inputEmailAddress;
            }
            setHasChanged(firstNameChanged || lastNameChanged || phoneNumberChanged || emailAddressChanged);
        }
    };
    const displayText = (key) => getDisplayText.apply({}, [key, LanguageEn, LanguageEs, language]);
    const hasMoreThanOneCustomer = (user) => {
        return user.accessAllCustomers || (user.customers && user.customers.length > 1);
    };
    const getUserName = (user) => {
        const notASuperAdmin = !hasMoreThanOneCustomer(user);
        const displayName = notASuperAdmin ? user.customerDisplayName || user.customerInternalName : '';
        return [displayName, !!displayName];
    };
    const [displayName, showDisplayName] = getUserName(user);
    const userValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        emailAddress: user.emailAddress,
        confirmEmail: user.confirmEmail,
    };
    const [userChanged, setUserChanged] = useState(userValues);
    useEffect(() => {
        user.firstName = userChanged.firstName;
        user.lastName = userChanged.lastName;
        user.phoneNumber = userChanged.phoneNumber;
        user.emailAddress = userChanged.emailAddress;
        user.confirmEmail = userChanged.confirmEmail;
    }, [userChanged]);
    const handleSubmit = async (values) => {
        const userId = user.userName;
        try {
            const response = await updateUserProfile(authHeader(), userId, values);
            if (response.status === 200) {
                addMessage({
                    type: 'success',
                    message: [LanguageEn.formSubmitSuccess, LanguageEs.formSubmitSuccess],
                    closeable: true,
                });
                setNewEmailAddress(email);
                setUserChanged(values);
                if (emailAddressChanged) {
                    logout();
                }
            }
        }
        catch (error) {
            let emailInUseFound = false;
            if (error.response.status === 409) {
                error.response.data.forEach((errors) => {
                    if (errors.message === 'A conflict occurred. Email address already in use.') {
                        addMessage({
                            type: 'error',
                            message: [LanguageEn.emailInUseError, LanguageEs.emailInUseError],
                            closeable: true,
                        });
                        emailInUseFound = true;
                    }
                });
            }
            if (!emailInUseFound) {
                addMessage({
                    type: 'error',
                    message: [LanguageEn.formSubmitError, LanguageEs.formSubmitError],
                    closeable: true,
                });
            }
        }
        setHasChanged(false);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DOCUMENT_UPLOAD_MODAL,
            event_name: 'Save profile',
        });
    };
    const emailConfirmation = (confirmEmail, values) => {
        const emailAddress = values ? values.emailAddress : '';
        return compareEmails(emailAddress, confirmEmail);
    };
    const showPhoneNumberField = () => {
        if (!isSsoUser) {
            return true;
        }
        else {
            return phoneNumberValidator(globalPhoneNumberFormat(user.phoneNumber)) === undefined;
        }
    };
    return (<>
      {isSsoUser && <Alert type={'info'}>{displayText('ssoUserAlert')}</Alert>}
      <Heading data-public={true} elemType={'h2'} color={'primary'} id='profileHeading'>
        {isSsoUser ? displayText('profileHeadingSso') : displayText('profileHeading')}
      </Heading>
      <StyledHRElement data-public={true}/>
      {!user?.isIdentityVerified && user?.auth0Roles?.includes('MLPEmployee') ?
            <Row id="custCodeMessage" className={'mt-3'} data-testid={'custCodeMessage'}>
          <Col>
            {displayText('mlpEmpCustCode')}
          </Col>
        </Row>
            :
                <>{showDisplayName && (<>
            <Row className={'mt-3'}>
              <Col>
                <StyledSubHeading data-public={true} elemType={'h2'}>
                  {displayText('employerNameLabel')}
                </StyledSubHeading>
              </Col>
            </Row>
            <StyledRowEmployerName>
              <Col>
                <StyledEmployerNameLabel data-public={true} id='employerName'>
                  {displayName}
                </StyledEmployerNameLabel>
              </Col>
            </StyledRowEmployerName>
          </>)}
        <Form onSubmit={handleSubmit} onValueChange={checkForFormChange} data-public={true}>
          <Row>
            <Col className={'mt-3 col-sm-12 col-md-6'}>
              <TextInput data-private={true} id={'firstName'} field={'firstName'} initialValue={user.firstName} label={displayText('firstNameLabel')} mask={formatName} maxLength={35} type={'text'} placeholder={displayText('firstNamePlaceholder')} readOnly={isSsoUser} validate={isNotEmpty} validateOnChange={!isSsoUser} validateOnBlur={!isSsoUser} validateOnMount={!isSsoUser} className={isSsoUser ? Styles.input : ''}/>
            </Col>
            <Col className={'mt-3 col-sm-12 col-md-6'}>
              <TextInput data-private={true} id={'lastName'} field={'lastName'} initialValue={user.lastName} label={displayText('lastNameLabel')} mask={formatName} maxLength={35} type={'text'} placeholder={displayText('lastNamePlaceholder')} readOnly={isSsoUser} validate={isNotEmpty} validateOnChange={!isSsoUser} validateOnBlur={!isSsoUser} validateOnMount={!isSsoUser} className={isSsoUser ? Styles.input : ''}/>
            </Col>
          </Row>
          <Row className={'mt-0'}>
            {showPhoneNumberField() && (<Col className={'mt-3 col-sm-12 col-md-6'}>
                <TextInput id={'phoneNumber'} field={'phoneNumber'} initialValue={globalPhoneNumberFormat(user.phoneNumber)} label={displayText('preferredPhoneLabel')} mask={globalPhoneNumberFormat} placeholder={'999-999-9999'} type={'text'} readOnly={isSsoUser} validate={phoneNumberValidator} validateOnChange={!isSsoUser} validateOnBlur={!isSsoUser} validateOnMount={!isSsoUser} helperText={isSsoUser ? '' : displayText('phoneHelperText')} className={isSsoUser ? Styles.input : ''}/>
              </Col>)}
            {!isSsoUser && (<>
                <Col className={'mt-3 col-sm-12 col-md-6'}>
                  <TextInput id={'emailAddress'} field={'emailAddress'} initialValue={user.emailAddress} label={displayText('preferredEmailLabel')} placeholder={displayText('preferredEmailPlaceholder')} type={'text'} readOnly={isSsoUser} validate={validateEmail} validateOnChange={!isSsoUser} validateOnBlur={!isSsoUser} validateOnMount={!isSsoUser} helperText={isSsoUser ? '' : displayText('emailHelperText')} className={isSsoUser ? Styles.input : ''}/>
                </Col>
                <Col />
                <Col className={'mt-3 col-sm-12 col-md-6'}>
                  <TextInput id={'confirmEmail'} field={'confirmEmail'} initialValue={user.emailAddress} label={displayText('reEnterPreferredEmailLabel')} placeholder={displayText('reEnterPreferredEmailLabel')} type={'text'} readOnly={isSsoUser} validate={emailConfirmation} validateOnBlur={!isSsoUser} validateOnChange={!isSsoUser} className={isSsoUser ? Styles.input : ''}/>
                </Col>
              </>)}
          </Row>
          {!isSsoUser && (<Row className={'mt-3'}>
              <Col>
                <FormButton idName='SaveChanges' displayText={displayText('saveChangesButton')} hasChanged={hasChanged}/>
              </Col>
            </Row>)}
        </Form></>}
    </>);
};
export default UserProfileInformation;
