import { Col } from '@digitools/honest-react';
import useStepperContext from './useStepperContext';
import useTranslate from 'src/hooks/useTranslate';
import useTealium from 'src/hooks/useTealium';
import { StyledBox, BackButton, RowCenterOnDesktop, StyledActionCardRow, StyledHeading } from './StyledComponents';
import { ActionCardContainer, ActionCardFooter, ActionCardHeader, CardIcon } from './ActionCardBox';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { VanityPageText } from '../constants/VanityPageText';
import { Language } from 'src/types/Language';
import useExperience from 'src/hooks/useExperience';
import { Link } from 'react-router-dom';
const EmployeeLoginChoice = () => {
    const { next, back } = useStepperContext();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const { language } = useExperience();
    console.log('emp login choice render');
    const handleOnClickLogIn = () => {
        console.log('selecting log in to move');
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: t(VanityPageText.logInToMove),
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMPLOYEE,
        });
    };
    const handleOnClickRegisterLFG = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: t(VanityPageText.registerLFG),
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMPLOYEE,
        });
    };
    const handleOnClickNotSure = () => {
        //Send on to guided experience
        next();
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: t(VanityPageText.notSure),
            event_version: TEALIUM_EVENT.EVENT_VERSION.EMPLOYEE,
        });
    };
    return (<div>
      <StyledBox>
        <RowCenterOnDesktop>
          <Col>
            <StyledHeading elemType={`h1`} elemStyle='h2' color='primary'>
              {t(VanityPageText.employeeSignInMoving)}
            </StyledHeading>
          </Col>
        </RowCenterOnDesktop>
      <hr />
      <StyledActionCardRow>
      <ActionCardContainer as={Link} 
    // @ts-ignore
    to={{
            pathname: '/public/login',
            search: `?employee=true${language === Language.SPANISH ? '&lang=es' : ''}`,
        }} onClick={() => handleOnClickLogIn()}>
          <ActionCardHeader>
            <CardIcon color='primary' name='lock-open'/>
          </ActionCardHeader>
          <ActionCardFooter>
            {t(VanityPageText.logInToMove)}
          </ActionCardFooter>
      </ActionCardContainer>
      <ActionCardContainer as={`a`} 
    // @ts-ignore
    href='https://www.lincolnfinancial.com' onClick={handleOnClickRegisterLFG}>
        <ActionCardHeader>
          <CardIcon color='primary' name='key' style={{ fontSize: '4rem', marginTop: '-.5rem' }}/>
        </ActionCardHeader>
        <ActionCardFooter>
          {t(VanityPageText.registerLFG)} 
        </ActionCardFooter>
      </ActionCardContainer>
      <ActionCardContainer onClick={handleOnClickNotSure}>
        <ActionCardHeader>
          <CardIcon color='primaryDark' name='strategy'/>
        </ActionCardHeader>
        <ActionCardFooter>
          {t(VanityPageText.notSure)}
        </ActionCardFooter>
      </ActionCardContainer>
      </StyledActionCardRow>
    </StyledBox>
    <BackButton onClick={() => back()}/>
  </div>);
};
export default EmployeeLoginChoice;
