import React from 'react';
import { useAuth0 } from 'status/hooks';
import { Navigate } from 'react-router-dom';
import { encode } from 'status/utilities/hash';
import contains from 'status/utilities/contains';
import { getEnvVariable } from 'src/utils/envUtils';
const EmployeeRedirect = () => {
    // const navigate = useNavigate();
    const { userMeta } = useAuth0();
    if (getEnvVariable('dnPaidLeaveSubmission') && userMeta.empNumGPFed && userMeta.smallMid) {
        let formatted = userMeta.empNumGPFed.replace(/-/g, '');
        const id = encode(formatted);
        return <Navigate replace to={`/status/employee/${id}/coveragedetail`}/>;
    }
    // User has an emp number so simply redirect to the landing page
    if (userMeta.empNumS1) {
        const id = encode(userMeta.empNumS1);
        // navigate(`/status/employee/${id}/coveragedetail`);
        return <Navigate replace to={`/status/employee/${id}/coveragedetail`}/>;
        // return;
    }
    // User is sso user so set the emp number to "id-empId" and encode it
    if (userMeta.ssoUser && userMeta.ssoCargo.employeeId) {
        const id = encode(userMeta.ssoCargo.employeeId, true);
        return <Navigate replace to={`/status/employee/${id}/coveragedetail`}/>;
    }
    // Special case for users that
    // 1. Have statusEE.eoiStatus enabled
    // 2. Dont have statusEE.claimLeaveStatus
    // This if check may change if more permissions are added
    // Basically the if statment can be read as follows:
    // If the user is only configured to view EOI and nothing else
    // Then send them to the EOI page
    const applicationPermissions = userMeta.applicationPermissions;
    if (!contains(applicationPermissions, 'statusEE.claimLeaveStatus') &&
        contains(applicationPermissions, 'statusEE.eoiStatus')) {
        return <Navigate replace to={'/status/employee/eoi'}/>;
    }
    if (userMeta.empNumGPFed && userMeta.smallMid) {
        let formatted = userMeta.empNumGPFed.replace(/-/g, '');
        const id = encode(formatted);
        return <Navigate replace to={`/status/employee/${id}/coveragedetail`}/>;
    }
    // If we get here we dont have enough info to give the user data
    // so we send them off to find themselves :)
    return <Navigate replace to={'/status/employee/findmyinfo'}/>;
};
export default EmployeeRedirect;
