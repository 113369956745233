import { themes } from '@digitools/honest-react';
import React from 'react';
import useTranslate from '../../../../hooks/useTranslate';
import { CliConfirmationText } from '../../i18n/CliConfirmationText';
const Step = ({ color, index, heading, text, communicationPref, submissionType, link }) => {
    const { t } = useTranslate();
    return (<div data-testid={'step'} style={{ display: 'flex', borderLeft: `20px solid ${color}`, padding: '1rem', marginBottom: '1rem' }}>
      <div style={{ marginTop: 'auto', marginBottom: 'auto', fontSize: '2rem', fontWeight: 300 }}>{index}</div>
      <div style={(submissionType === 'leave' && index === '3') ? { color: themes.lincoln.palette.black, paddingLeft: '1rem', display: 'flex', alignItems: 'center' } : { color: themes.lincoln.palette.black, paddingLeft: '1rem' }}>
        <strong style={{
            fontSize: '1.125rem',
            color: themes.lincoln.palette.grayDarkest,
            display: 'block',
            marginBottom: '.25rem',
        }}>
          {heading}
        </strong>
        <div>{text}</div>
        {communicationPref === true && (<span data-testid='automated-message'>{t(CliConfirmationText[submissionType].automatedMessageText)}</span>)}
        {link && <p style={{ marginTop: '.5rem' }}>{link}</p>}
      </div>
    </div>);
};
export default Step;
