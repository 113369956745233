import Icon from "@digitools/honest-react/dist/lib/components/Icon";
import React from "react";
export const CliConfirmationTextClaim = {
    pageHeading: {
        english: <><strong>Thank you!</strong> You have successfully submitted a new claim.</>,
        spanish: <><strong>Gracias!</strong> Ha enviado con éxito un nuevo.</>, // <- for claim only, 'una nueva ausencia' - this should conjugate different because of masculine/feminine nouns
    },
    subHeading: {
        english: 'Please keep your claim number handy for future reference.',
        spanish: 'Mantenga su número de reclamo a mano para futuras referencias.'
    },
    videoMessage: {
        english: <>For more information on next steps,&nbsp;
        <strong>
          <a id='video-message-link' href='#' onClick={() => window.open("https://players.brightcove.net/1134849171001/default_default/index.html?videoId=5811829006001", "_blank", "height=400px, width=600px")}>
            watch our step by step video of the claim process.
            </a>
          </strong></>,
        spanish: <>Para obtener más información sobre los próximos pasos,&nbsp;
        <strong>
          <a id='video-message-link' href='#' onClick={() => window.open("https://players.brightcove.net/1134849171001/default_default/index.html?videoId=5811829006001", "_blank", "height=400px, width=600px")}>
            vea nuestro video paso a paso del proceso de reclamación.
            </a>
        </strong></>
    },
    eventNumber: {
        english: 'CLAIM NUMBER: ',
        spanish: 'NUMERO DE RECLAMO: '
    },
    ourNextStepsHeading: {
        english: 'Our Next Steps',
        spanish: 'Nuestros Próximos Pasos'
    },
    ourNextStepsBody: {
        english: 'Your claims team will contact you within 1-3 business days to gather additional details and answer any questions you may have regarding your claim. If your requested claim is for a future absence, you will be contacted by a member of the claims team closer to your absence start date.',
        spanish: 'Su equipo de reclamos se comunicará con usted dentro de 1 a 3 días hábiles para recopilar detalles adicionales y responder cualquier pregunta que pueda tener con respecto a su reclamo. Si su reclamo solicitado es para una ausencia futura, un miembro del equipo de reclamos se comunicará con usted cuando se acerque la fecha de inicio de su ausencia.'
    },
    nextStepsHeading: {
        english: 'Your Next Steps',
        spanish: 'Tus Siguientes Pasos',
    },
    nextSteps1Heading: {
        english: 'Contact your manager',
        spanish: 'Comuníquese con su gerente',
    },
    nextSteps1Body: {
        english: 'If you haven’t already done so, please contact your manager to inform them you’ll be out of work.',
        spanish: 'Si aún no lo ha hecho, comuníquese con su gerente para informarle que no tendrá trabajo.'
    },
    nextSteps2Heading: {
        english: 'Send the Attending Physician Statement to your provider',
        spanish: 'Envíe la Declaración del médico tratante a su proveedor'
    },
    nextSteps2Body: {
        english: (<>
        Download the{' '}
        <a data-testid='phys-auth-link' onClick={() => window.open('/customer/employee-forms', '_blank')}>
          <strong>Attending Physician Statement</strong>
        </a>
      </>),
        spanish: (<>
        Descargue la{' '}
        <a data-testid='phys-auth-link' onClick={() => window.open('/customer/employee-forms', '_blank')}>
          <strong>Declaración del médico tratante</strong>
        </a>{' '}
        a su proveedor
      </>)
    },
    nextSteps3Heading: {
        english: 'Sign the Medical Authorization Form',
        spanish: 'Firme el Formulario de Autorización Médica'
    },
    nextSteps3Body: {
        english: (<>
        Download the{' '}
        <a data-testid='med-auth-link' onClick={() => window.open('/customer/employee-forms', '_blank')}>
          <strong>Medical Authorization </strong>
        </a>
        form, sign it, and send the signed form to your claims team.
      </>),
        spanish: (<>
        Descargue el formulario de{' '}
        <a data-testid='med-auth-link' onClick={() => window.open('/customer/employee-forms', '_blank')}>
          <strong>Autorización Médica</strong>
        </a>
        , fírmelo y envíelo firmado a su equipo de reclamos.
      </>)
    },
    nextSteps4Heading: {
        english: 'Upload the signed authorization, and any additional info requested, on our secure portal',
        spanish: 'Cargue la autorización firmada y cualquier información adicional solicitada en nuestro portal seguro'
    },
    nextSteps4Body: {
        english: (<>
        You can upload documents at any time in the future on our secure portal. These documents will be viewable by
        your Claim Specialist and may expedite the processing of your claim. If you prefer, you can also email documents
        and medical information to{' '}
        <a href='mailto: disabilitydocuments@lfg.com' data-testid='disability-documents-email-link'>
          <strong>disabilitydocuments@lfg.com</strong>
        </a>
      </>),
        spanish: (<>
        Puede cargar documentos en cualquier momento en el futuro en nuestro portal seguro. Estos documentos serán
        visibles para su especialista en reclamos y pueden acelerar el procesamiento de su reclamo. Si lo prefiere,
        también puede enviar documentos e información médica por correo electrónico a{' '}
        <a href='mailto: disabilitydocuments@lfg.com' data-testid='disability-documents-email-link'>
          <strong>disabilitydocuments@lfg.com</strong>
        </a>
      </>)
    },
    nextSteps5Heading: {
        english: 'Stay updated about your claim',
        spanish: 'Manténgase actualizado sobre su reclamo',
    },
    nextSteps5BodyEE: {
        english: (<>
        You'll be able to view your claim online within 48 hours. Simply select the{' '}
        <a data-testid='view-status-link-5' href='/customer/status/employee' target='_blank'>
          <strong>View Status</strong>
        </a>{' '}
        button from the home page. Here you’ll also find your Case Manager's contact information, should you have any
        questions. <br />
        You can access these forms, check your claim status, manage your claim, and upload completed forms on our secure
        portal.
      </>),
        spanish: (<>
        Podrá ver su reclamo en línea dentro de las 48 horas. Simplemente seleccione el botón{' '}
        <a data-testid='view-status-link-5' href='/customer/status/employee' target='_blank'>
          <strong>Ver Estado</strong>
        </a>{' '}
        en la página de inicio. Aquí también encontrará la información de contacto de su administrador de casos, en caso
        de que tenga alguna pregunta.<br />
        Puede acceder a estos formularios, verificar el estado de su reclamo, administrar su reclamo y cargar
        formularios completos en nuestro portal seguro.
      </>)
    },
    nextSteps5BodyER: {
        english: (<>
        You'll be able to view your claim online within 48 hours. Simply select the{' '}
        <a data-testid='view-status-link-5' href='/customer/status' target='_blank'>
          <strong>View Status</strong>
        </a>{' '}
        button from the home page. Here you’ll also find your Case Manager's contact information, should you have any
        questions.
      </>),
        spanish: (<>
        Podrá ver su reclamo en línea dentro de las 48 horas. Simplemente seleccione el botón{' '}
        <a data-testid='view-status-link-5' href='/customer/status' target='_blank'>
          <strong>Ver Estado</strong>
        </a>{' '}
        en la página de inicio. Aquí también encontrará la información de contacto de su administrador de casos, en caso
        de que tenga alguna pregunta.
      </>),
    },
    nextSteps5Link: {
        english: (<a href='/customer/profile/alertsNotifications' data-testid='customer-preferences-link' target='_blank'>
        <Icon name='mobile-alt'/>
        <strong> Sign up for text notifications</strong>
      </a>),
        spanish: (<a href='/customer/profile/alertsNotifications' data-testid='customer-preferences-link' target='_blank'>
        <Icon name='mobile-alt'/>
        <strong> Regístrese para recibir notificaciones de texto</strong>
      </a>),
    },
    automatedMessageText: {
        english: (<>
        <br />
        <strong>Interested in getting automated claim status updates by text?</strong>
        <br />
        Click the link below to update your communicated preferences in your portal profile. All communication will be
        sent in English.
      </>),
        spanish: (<>
        <br />
        <strong>
          ¿Está interesado en recibir actualizaciones automáticas del estado de las reclamaciones por mensaje de texto?
        </strong>
        <br />
        Haga clic en el enlace a continuación para actualizar sus preferencias comunicadas en su perfil de portal. Toda
        la comunicación se enviará en inglés.
      </>),
    },
    questions: {
        english: 'My Lincoln Portal® is your one-stop shop for everything you need to manage your claim. You can access the portal 24 hours a day, 7 days a week, from your desktop, tablet, or mobile device.',
        spanish: 'My Lincoln Portal® es su ventanilla única para todo lo que necesita para administrar su reclamo. Puede acceder al portal las 24 horas del día, los 7 días de la semana, desde su computadora de escritorio, tableta o dispositivo móvil.',
    },
    videoUrl: 'https://players.brightcove.net/1134849171001/default_default/index.html?videoId=5811829006001',
    videoId: '5811829006001',
    videoHeading: {
        english: 'Have questions about your next steps in the claim process?',
        spanish: '¿Tiene preguntas sobre los pasos de su nido en el proceso de reclamo?',
    },
    videoSubheading: {
        english: 'Watch this video',
        spanish: 'Mira este video',
    },
};
