import { Container, Icon, Logo, themes } from '@digitools/honest-react';
import { format, parseJSON } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TEALIUM_EVENT } from '../../constants/tealiumConstants';
import useAuthentication from '../../hooks/useAuthentication';
import useCustomerConfig from '../../hooks/useCustomerConfig';
import useExperience from '../../hooks/useExperience';
import useTealium from '../../hooks/useTealium';
import useTranslate from '../../hooks/useTranslate';
import { Experience } from '../../types/Experience';
import { Language } from '../../types/Language';
import { getHomeUrl } from '../../utils/NavigationUtils';
import MobileNav from '../NavMenuBar/Mobile/MobileNav';
import { HeaderText } from './HeaderText';
import { resize } from './HeaderUtils';
import SlideoutMenu from './SlideoutMenu';
//@ts-ignore
import styles from './HeaderStyles.scss';
import MobileExperienceMenu from './MobileExperienceMenu';
import { getEnvVariable } from '../../utils/envUtils';
import ProveText from '../prove/ProveText';
const CloseSpan = styled('span') `
  display: block;
  margin-top: 0.25rem;
  margin-left: -8px;
  font-weight: 600;
`;
const MenuContainer = styled('div') `
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transition: height 0.25s ease-in-out;
  width: 100%;
  z-index: 2001;
  background-color: ${({ theme }) => theme.palette.grayLightest};
`;
export const MenuButton = styled('button') `
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grayLight};
  text-align: left;
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.grayDarker};
  background-color: ${({ theme }) => theme.palette.grayLightest};
  transition: margin-left 0.25s ease-in-out;
  cursor: pointer;
`;
export const menuLinkStyle = css `
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grayLight};
  text-align: left;
  font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto';
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.grayDarker};
  background-color: ${({ theme }) => theme.palette.grayLightest};
  transition: margin-left 0.25s ease-in-out;
`;
export const MenuLink = styled(Link) `
  ${menuLinkStyle}
`;
export const MenuExternalLink = styled.a `
  ${menuLinkStyle}
`;
const LastLoginSpan = styled('span') `
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  /* font-weight: 400; */
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
`;
// TODO: break out inline styles into styled components for readability
// TODO: identify what can be pushed to honest (ticket)
const MobileHeader = () => {
    const { t } = useTranslate();
    /* Header State */
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { isCommPrefEnabled } = useCustomerConfig();
    const { user, isAuthenticated, logout } = useAuthentication();
    const { experience, changeLanguage, toggleLanguage, setExperience, language } = useExperience();
    const { trackEvent } = useTealium();
    /* Hamburger Menu Button Ref */
    const menuButtonRef = useRef(null);
    const location = useLocation();
    const isMigrateScreen = location.pathname.endsWith('migrate');
    useEffect(() => {
        if (isMenuOpen) {
            const element = document.getElementById('menu');
            element.style.height =
                Array.prototype.reduce.call(element.childNodes, function (p, c) {
                    return p + (c.offsetHeight || 0);
                }, 0) + 'px';
        }
    });
    // TODO: currently doesn't reset state of opened sub-menus, results in wonky size on re-opening
    const openCloseMenu = () => {
        //TODO: may be able to get rid of menuOpen state - can just look at height
        if (isMenuOpen) {
            document.getElementById('menu').style.height = '0px';
            //delay hidden for animation
            setTimeout(() => {
                try {
                    document.getElementById('menu').style.visibility = 'hidden';
                }
                catch { } // This only breaks in tests where the dom is gone by the time this timeout triggers
            }, 250);
        }
        else {
            // Animations won't work on height:auto - so we have to calculate all the children's height and set it exact
            const element = document.getElementById('menu');
            element.style.visibility = 'visible';
            element.style.height =
                Array.prototype.reduce.call(element.childNodes, function (p, c) {
                    return p + (c.offsetHeight || 0);
                }, 0) + 'px';
            // more verbose but easier to read below --
            // let newHeight = 0;
            // for (const child of element.childNodes) {
            //     newHeight += child.offsetHeight || 0;
            // }
            // element.style.height = `${newHeight}px`;
        }
        setIsMenuOpen(!isMenuOpen);
    };
    const trackPageEvent = (eventName, version) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: eventName,
            event_type: TEALIUM_EVENT.EVENT_TYPE.HEADER,
            event_version: version,
        });
    };
    return (<div data-testid={'mlp-mobile-header'} id={'mlp-mobile-header'} data-public={true}>
      <Container theme={themes.lincoln}>
        <div style={{ display: 'flex', height: '4.25rem', width: '100%' }}>
          <Link data-testid='lfg-logo' data-public={true} to={getHomeUrl(experience, user?.roles)}>
            <img alt={`Lincoln Financial Group`} src={Logo} style={{ width: '8.5rem', paddingRight: '1rem', paddingTop: '1rem' }}/>
          </Link>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '-1rem' }}>
            {isAuthenticated && experience === Experience.EMPLOYEE && (<Link aria-label={'Contact support page'} id='contactSupportTop' data-testid='contactSupportTop' to='/home/contacts' onClick={() => trackPageEvent('Contact and support', 'Logged in')} style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                padding: '1rem',
                backgroundColor: 'white',
                border: `1px solid ${themes.lincoln.palette.grayLight}`,
                borderRight: 'none',
                borderTop: 'none',
            }} className={styles.noUnderline}>
                <Icon classNames={styles.flipH + ' ' + styles.noUnderline} size='medium' name='phone' color={'grayDark'}/>
              </Link>)}
            {!isAuthenticated && (<Link aria-label={'Contact support page'} id='contactSupportPublic' data-testid='contactSupportTopPublic' to='/public/contacts' onClick={() => {
                openCloseMenu();
                trackPageEvent('Contact and support', 'Public');
            }} style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                padding: '1rem',
                backgroundColor: 'white',
                borderLeft: `1px solid ${themes.lincoln.palette.grayLight}`,
                borderRight: 'none',
                borderTop: 'none',
                borderBottom: 'none',
            }} className={styles.noUnderline}>
                <Icon classNames={styles.flipH + ' ' + styles.noUnderline} size='medium' name='phone' color={'grayDark'}/>
              </Link>)}
            {/* //TODO: need to fix up focused state - harden corners, use the standard blue glow */}
            <button data-testid={'menu-button'} aria-controls={'menu'} aria-label={`${isMenuOpen ? 'Close menu' : 'Open menu'}`} onClick={() => openCloseMenu()} style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '56px',
            color: 'white',
            height: '100%',
            padding: '1rem',
            backgroundColor: isMenuOpen ? themes.lincoln.palette.grayDark : 'white',
            border: 'none',
            borderLeft: `1px solid ${isMenuOpen ? themes.lincoln.palette.grayDark : themes.lincoln.palette.grayLight}`,
            borderRight: `1px solid ${isMenuOpen ? themes.lincoln.palette.grayDark : themes.lincoln.palette.grayLight}`,
            cursor: 'pointer',
        }}>
              <Icon size={isMenuOpen ? 'small' : 'medium'} name={isMenuOpen ? 'close' : 'bars-solid'} color={isMenuOpen ? 'white' : 'grayDark'}/>
              {isMenuOpen && <span style={{ fontWeight: 600 }}>CLOSE</span>}
            </button>
          </div>
        </div>
      </Container>
      {/* This bar is not visible in some LFG sites, but we don't have any banner image flush with the header - need to leave it or shit looks bad */}
      <div style={{ color: 'white', height: '10px', width: '100%', backgroundColor: themes.lincoln.palette.grayDark }}/>
      <MenuContainer id='menu' theme={themes.lincon} style={{ zIndex: 2000 }}>
        {isAuthenticated && user && !isMigrateScreen && (<>
            <MobileNav isMobileMenuOpen={isMenuOpen} openCloseMenu={openCloseMenu}/>
            <SlideoutMenu isMobileMenuOpen={isMenuOpen} aria-label={'Profile menu'} id='profile-menu' data-testid={'profile-menu'} iconName='profile' label={<>
                  <strong data-private={true}>
                    {t(HeaderText.welcome)}
                    {user.firstName && `, ${user.firstName}`}
                  </strong>
                  <br />
                  <LastLoginSpan>
                    {user && user.lastLogin
                    ? `${t(HeaderText.lastLogin)} ${format(parseJSON(user?.lastLogin), 'MM/dd/yy')}`
                    : ''}
                  </LastLoginSpan>
                </>} parentResize={resize}>
              {user?.isLfgUser && !user?.isIdentityVerified && (<MenuLink id='accountAccess' aria-label={'Alerts & notifications page'} data-testid={'accountAccess'} to='/profile/accountAccess' onClick={() => {
                    openCloseMenu();
                    trackPageEvent(ProveText.verifyHeadingAlt.english);
                }}>
                  {t(ProveText.verifyHeadingAlt)}
                </MenuLink>)}
              {(user.isLfgUser && (user.auth0Roles?.includes("PA") || (user.auth0Roles?.includes("MLPEmployee") && user?.isIdentityVerified))) ? (<>
                  <MenuExternalLink aria-label={'Profile page'} data-testid={'profileLink'} target='_blank' href={getEnvVariable('lfgHomeUrl') + '/secure/consumer/profileinformation#/consumer'} onClick={() => {
                    openCloseMenu();
                    trackPageEvent('Profile information');
                }}>
                    {t(HeaderText.profile)}
                  </MenuExternalLink>
                  <MenuExternalLink aria-label={'Settings page'} data-testid={'settingsLink'} target='_blank' href={getEnvVariable('lfgHomeUrl') + '/secure/consumer/securitysettings#/consumer'} onClick={() => {
                    openCloseMenu();
                    trackPageEvent('Security settings');
                }}>
                    {t(HeaderText.settings)}
                  </MenuExternalLink>
                </>) : (<>
                  <MenuLink id='profileLink' aria-label={'Profile page'} data-testid={'profileLink'} to='/profile/userInfo' onClick={() => {
                    openCloseMenu();
                    trackPageEvent('Profile information');
                }}>
                    {t(HeaderText.profile)}
                  </MenuLink>
                  <MenuLink aria-label={'Settings page'} data-testid={'settingsLink'} to='/profile/password' onClick={() => {
                    openCloseMenu();
                    trackPageEvent('Security settings');
                }}>
                    {t(HeaderText.settings)}
                  </MenuLink>
                </>)}
              {isCommPrefEnabled && (<MenuLink id='commPrefLink' aria-label={'Alerts & notifications page'} data-testid={'commPrefLink'} to='/profile/alertsNotifications' onClick={() => {
                    openCloseMenu();
                    trackPageEvent('Alerts & notifications');
                }}>
                  {t(HeaderText.commPrefs)}
                </MenuLink>)}
              {user.roles && (user.roles.includes('employer') || user.roles.indexOf('internal') > -1) && (<MenuLink id='emailNotificationsLink' aria-label={'Email Notifications'} data-testid={'emailNotificationsLink'} to='/admin/emailNotification' onClick={() => trackPageEvent('Email Notifications')}>
                  {t(HeaderText.emailNotifications)}
                </MenuLink>)}
              {user.roles &&
                (user.roles.includes('employeradmin') || user.roles.indexOf('internal') > -1) &&
                user.customerInContext && (<MenuLink id='userAccessLink' aria-label={'User Access'} data-testid={'userAccessLink'} to='/admin/permissions' onClick={() => trackPageEvent('User Access')}>
                    {t(HeaderText.userAccess)}
                  </MenuLink>)}
            </SlideoutMenu>
          </>)}
        {isAuthenticated && user && !isMigrateScreen && (<MobileExperienceMenu user={user} trackPageEvent={trackPageEvent} openCloseMenu={openCloseMenu} isMenuOpen={isMenuOpen}/>)}

        {experience !== Experience.EMPLOYER && (<MenuButton aria-label={'Change language'} id='language' data-testid={'changeLanguage'} onClick={() => {
                toggleLanguage();
                trackPageEvent('Language', language === 'en' ? 'English' : 'Spanish');
            }}>
            <span style={{
                textDecoration: language === Language.ENGLISH ? 'underline' : 'none',
                fontWeight: language === Language.ENGLISH ? 'bold' : 'normal',
            }}>
              {HeaderText.language.english}
            </span>
            <pre> | </pre>
            <span style={{
                textDecoration: language === Language.SPANISH ? 'underline' : 'none',
                fontWeight: language === Language.SPANISH ? 'bold' : 'normal',
            }}>
              {HeaderText.language.spanish}
            </span>
          </MenuButton>)}
        {isAuthenticated && !isMigrateScreen && (<MenuLink aria-label={'Contact support page'} id='contactSupport' data-testid='contactSupport' to='/home/contacts' onClick={() => {
                openCloseMenu();
                trackPageEvent('Contact and support', 'Logged in');
            }}>
            <Icon theme={themes.lincoln} className={styles.flipH} color={'grayDark'} name={'phone'} style={{ marginRight: '.5rem', alignSelf: 'center' }} size='xs'/>
            {t(HeaderText.contactSupport)}
          </MenuLink>)}
        {!isAuthenticated && !isMigrateScreen && (<MenuLink aria-label={'Contact support page'} id='contactSupportPublic' data-testid='contactSupportPublic' to='/public/contacts' onClick={() => {
                openCloseMenu();
                trackPageEvent('Contact and support', 'Public');
            }}>
            <Icon theme={themes.lincoln} className={styles.flipH} color={'grayDark'} name={'phone'} style={{ marginRight: '.5rem', alignSelf: 'center' }} size='xs'/>
            {t(HeaderText.contactSupport)}
          </MenuLink>)}
        {isAuthenticated && !isMigrateScreen && (<MenuButton aria-label={'Logout'} data-testid='logout' id='logout' onClick={() => {
                logout();
                openCloseMenu();
                trackPageEvent('Log Out');
            }}>
            {t(HeaderText.logout)}
          </MenuButton>)}
      </MenuContainer>
    </div>);
};
export default MobileHeader;
