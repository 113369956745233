import { NewModal as Modal } from '@digitools/honest-react';
import React from 'react';
import useExperience from 'src/hooks/useExperience';
import { Language } from 'src/types/Language';
const NavigationModal = (props) => {
    const id = props.id || 'NavigationModal';
    const { language } = useExperience();
    const headerText = language === Language.ENGLISH ? 'Are you sure?' : '¿Está Seguro?';
    const bodyText = language === Language.ENGLISH
        ? 'If you navigate away you will lose your changes.'
        : 'Si usted navega fuera de esta página, perderá sus cambios.';
    const cancelButtonText = language === Language.ENGLISH ? 'Cancel' : 'Cancelar';
    const okButtonText = language === Language.ENGLISH ? 'Ok' : 'Ok';
    return (<Modal data-testid={id} title={headerText} centered={true} opened={props.opened} onClose={() => {
            props.close();
        }} cancelButtonText={cancelButtonText} cancelButtonProps={{
            'data-testid': 'NavigationModal-cancel',
            'aria-label': 'Cancel navigate away'
        }} onSubmit={() => {
            props.navigationCallback();
            props.close();
        }} submitButtonText={okButtonText} submitButtonProps={{
            'data-testid': 'NavigationModal-ok',
            'aria-label': 'Ok navigate away'
        }}>
      <p>{bodyText}</p>
    </Modal>);
};
export default NavigationModal;
