import { Box, Icon } from '@digitools/honest-react';
import styled from 'styled-components';
export const ActionCardContainer = styled.button `
  background: none;
  max-width: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.palette.primary};
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 6px ${({ theme }) => theme.palette.primary};
    cursor: pointer;
    text-decoration: none;
  }
  &:focus {
    box-shadow: 0 0 6px ${({ theme }) => theme.palette.primary};
  }
`;
export const ActionCardHeader = styled(Box) `
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.palette.grayLightest}
  border: none;
  min-height: 88px;
`;
export const ActionCardFooter = styled(Box) `
  border: none
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 0.8125rem;
  text-align: center;
  margin-top: 0;
`;
export const CardIcon = styled(Icon) `
  font-size: 3rem;
`;
