import React, { useState } from "react";
import { Box, Col, DatePickerV2, Icon, Radio, RadioGroup, SelectInput, TextInput, themes } from '@digitools/honest-react';
import { AddRequestText } from '../../constants/AddAccommodationRequestText';
import useTranslate from 'src/hooks/useTranslate';
import useExperience from 'src/hooks/useExperience';
import styled from 'styled-components';
import useAsContext from '../../hooks/useAsConfigContext';
import { Scope, useFormState } from 'informed';
import { getSelectInputDropDown, getColorCode, getDropDownList } from "../../utils/AsDataUtils";
import { DatePickerBlock, DivBlock, LabelText, RadioContainer, SectionHeading, SelectBlock, StyledDiv, StyledRow, TextBlock } from "../../components/LayoutComponents";
import { validateAccommodationDetail, fieldRequired, validateCost, handleDateChange, normalizeNewLines, validateEndDate, OneSelectedRequired } from 'src/packages/as/utils/validationUtils';
import { AS_YES_NO_OPTIONS } from "../../constants/Constants";
import useAsDataContext from "../../hooks/useAsDataContext";
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from "src/types/Experience";
import { format } from "date-fns";
const StyledSelectInput = styled(SelectInput) `{
  height: 8rem;
  min-height: 8rem;
  width: 100%;
}`;
const AccomodationRequest = ({ count }) => {
    const { t } = useTranslate();
    const formState = useFormState();
    const { language, experience } = useExperience();
    const { asConfig } = useAsContext();
    const [type, setType] = useState();
    const [isOptional, setIsOptional] = useState(true);
    const [showAnticipateddate, setShowAnticipateddate] = useState(false);
    const [startDate, setStartDate] = useState();
    const [minDate, setMinDate] = useState();
    let tempStartDate = new Date();
    let tempActualStartDate = new Date();
    let borderColor = getColorCode(count);
    const [actualStartDate, setActualStartDate] = useState();
    const [accomodationStatus, setAccomodationStatus] = useState("IP");
    const [periodType, setPeriodType] = useState("");
    const [restrictionsOther, setRestrictionsOther] = useState(false);
    const [providedType, setProvidedType] = useState();
    const [isProvidedTypeOptional, setIsProvidedTypeOptional] = useState(true);
    const { accommodationRequestDataArray, receivedDate, receivedRequestDate, setReceivedRequestDate } = useAsDataContext();
    const [anticipatedEndDate, setAnticipatedEndDate] = useState();
    const [statusReason, setStatusReason] = useState("");
    const [periodTypeDeselect, setPeriodTypeDeselect] = useState(false);
    const [existingAccommodationDeselect, setExistingAccommodationDeselect] = useState(false);
    const [workStatusDeselect, setworkStatusDeselect] = useState(false);
    const isEmployer = experience === Experience.EMPLOYER;
    const accommodationRequestData = accommodationRequestDataArray && accommodationRequestDataArray[count - 1];
    const borderBlock = {
        paddingLeft: '0.8125rem',
        borderLeft: `20px solid ${borderColor}`
    };
    const StyledIcon = styled(Icon) `	
  font-size: 2.5rem; 	
  margin-left: 0.5rem;	
  margin-right: 0.5rem;	
  margin-top: 0.25rem;	
  margin-botton: -0.5rem;	
  @media only screen and (max-width: 500px) {	
    margin-top: -1rem;	
}	
`;
    const labelStyle = {
        color: '#333333',
    };
    const paddingRight30 = {
        paddingRight: '30px',
        lineHeight: '1',
    };
    return (<StyledDiv data-public={true} data-testid='accommodation-request'>
      <Box className={'mt-4'}>
        <Col md={12} className={'p-0'}>
          <StyledRow>
            <DivBlock className={'d-flex align-items-center'}>
              <StyledIcon name={'accommodation-request'} size='large' color={'primary'}/>
              <SectionHeading elemType={'h3'} color={'black'} theme={themes.lincoln} data-testid={'heading'}>
                {t(AddRequestText.sectionTitleEE)} {count}
              </SectionHeading>
            </DivBlock>
          </StyledRow>
          {/* @ts-ignore */}
          <Scope scope={'accommodationRequestValues'}>
            <div style={borderBlock}>
              {isEmployer && asConfig?.config?.asRep &&
            <><Col sm={12}>
                  <LabelText data-testid='requestReceivedDateLabel'>{CustomEmployeeText(t(AddRequestText.requestReceivedDate))}</LabelText>
                  <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                    <DatePickerV2 data-testid='requestReceivedDate' theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} field={`requestReceivedDate${count}`} placeholderText={t(AddRequestText.dateFormatEE)} hideLabel={true} dateFormat={'MM/dd/yyyy'} minDate={receivedDate} 
            // @ts-ignore
            validate={() => handleDateChange(formState?.values?.conditionData?.receivedDate, formState?.values?.accommodationRequestValues[`receivedRequestDate${count}`], 'eventReceivedDate')} validateOnChange={true} onChange={(date) => setReceivedRequestDate(date)} initialValue={accommodationRequestData?.requestReceivedDate}/>
                  </DatePickerBlock>
                </Col>

                  <Col sm={10} md={12}>
                    <LabelText data-testid='statusLabel'>{t(AddRequestText.status)}</LabelText>
                  </Col><SelectBlock sm={10} lg={5}>
                    <SelectInput data-testid='selectRequestStatusDropDown' field={`status${count}`} validateOnBlur={true} options={getSelectInputDropDown(asConfig?.config?.requestStatusDropDown, t)} validate={fieldRequired} theme={themes.secureSite} style={paddingRight30} className={'lh-1'} initialValue={'IP'} onChange={(e) => {
                    setAccomodationStatus(e.target.value);
                }}/>
                  </SelectBlock>
                  {(accomodationStatus === 'CL') &&
                    <><Col sm={12}>
                      <LabelText data-testid='closedDateLabel'>{t(AddRequestText.closedDate)}</LabelText>
                      <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                        <DatePickerV2 data-testid='closedDate' theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} field={`closedDate${count}`} placeholderText={t(AddRequestText.dateFormatEE)} hideLabel={true} dateFormat={'MM/dd/yyyy'} validate={fieldRequired} initialValue={accommodationRequestData?.closedDate}/>
                      </DatePickerBlock>
                    </Col>

                      <Col sm={10} md={12}>
                        <LabelText data-testid='statusReasonCodeLabel'>{t(AddRequestText.statusReasonCode)}</LabelText>
                      </Col>
                      <SelectBlock sm={10} lg={5}>
                        <SelectInput data-testid='selectStatusReasonDropDown' field={`statusReasonCode${count}`} validateOnBlur={true} options={getSelectInputDropDown(asConfig?.config?.statusReasonDropDown, t)} success={true} validate={fieldRequired} theme={themes.secureSite} style={paddingRight30} className={'lh-1'} onChange={(e) => {
                            setStatusReason(e.target.value);
                        }} initialValue={accommodationRequestData?.statusReasonCode}/>
                      </SelectBlock></>}
                  <Col sm={10} md={12}>
                    <LabelText optional={true} language={language} data-testid='assistRequiredLabel'>{t(AddRequestText.assistRequired)}</LabelText>
                  </Col>
                  <SelectBlock sm={10} lg={5}>
                    <SelectInput data-testid='selectAssistanceDropDown' field={`assistanceRequired${count}`} validateOnBlur={true} options={getSelectInputDropDown(asConfig?.config?.assistanceDropDown, t)} success={true} theme={themes.secureSite} style={paddingRight30} className={'lh-1'} initialValue={accommodationRequestData?.assistanceRequired}/>
                  </SelectBlock>
                </>}
              <div>
                <Col sm={10} md={12}>
                  <LabelText data-testid={'accommodationTypeLabel'}>{t(AddRequestText.accommodationTypeEE)}</LabelText>
                </Col>
                <SelectBlock sm={10} lg={5}>
                  <SelectInput data-testid='accommodation-type' field={`accomodationRequestType${count}`} validateOnBlur={true} options={getSelectInputDropDown(asConfig?.config?.requestedAccommodationTypeDropDown, t)} success={true} validate={fieldRequired} theme={themes.secureSite} placeholderText={t(AddRequestText.selectAccommodation)} optional={true} style={paddingRight30} className={'lh-1'} onChange={(e) => { setType(e.target.value); e.target.value === "13" ? setIsOptional(false) : setIsOptional(true); }} initialValue={accommodationRequestData?.requestedAccommodationType}/>
                </SelectBlock>
              </div>
              <TextBlock sm={10} md={10}>
                <LabelText optional={isOptional} language={language} data-testid={'accommodationDetailLabel'}>{t(AddRequestText.timeAwayRequestEE)}</LabelText>
                <TextInput data-testid='period-type' field={`isTimeawayfromWork${count}`} className={'d-block'} validateOnBlur={true} validateOnChange={true} placeholder={''} label={`isTimeawayfromWork${count}`} type={'text'} textarea={true} mask={normalizeNewLines} hideLabel={true} ariaLabelText={t(AddRequestText.timeAwayRequestEE)} validate={(props) => validateAccommodationDetail(props, type)} initialValue={accommodationRequestData?.requestedAccommodationDetail}/>
              </TextBlock>
            </div>
            
            {isEmployer && asConfig?.config?.asRep && (accomodationStatus === 'AP' || accomodationStatus === 'CL') &&
            <>
                <Col sm={10} md={12}>
                  <LabelText optional={!(accomodationStatus === 'AP' || (accomodationStatus === 'CL' && (statusReason === "NL" || statusReason === "XD" || statusReason === "AC")))} data-testid='providedAccomodationTypeCodeLabel'>{t(AddRequestText.providedAccomodationTypeCode)}</LabelText>
                </Col>
                <SelectBlock sm={10} lg={5}>
                  <SelectInput data-testid='selectProvidedAccommodationTypeDropDown' field={`providedAccomodationTypeCode${count}`} validateOnBlur={true} options={getSelectInputDropDown(asConfig?.config?.providedAccommodationTypeDropDown, t)} success={true} validate={(accomodationStatus === 'AP' || (accomodationStatus === 'CL' && (statusReason === "NL" || statusReason === "XD" || statusReason === "AC"))) ? fieldRequired : undefined} theme={themes.secureSite} style={paddingRight30} className={'lh-1'} onChange={(e) => {
                    setProvidedType(e.target.value);
                    e.target.value === "13" ? setIsProvidedTypeOptional(false) : setIsProvidedTypeOptional(true);
                }} initialValue={accommodationRequestData?.providedAccomodationTypeCode}/>
                </SelectBlock>
                <TextBlock sm={10} md={10}>
                  <LabelText optional={isProvidedTypeOptional} language={language} data-testid='providedDetailsTextLabel'>{t(AddRequestText.providedDetailsText)}</LabelText>
                  <TextInput label={''} //FIXME: not sure why this was required?
             data-testid='providedDetailsText' field={`providedDetailsText${count}`} className={'d-block'} validateOnBlur={true} placeholder={''} type={'text'} textarea={true} mask={normalizeNewLines} hideLabel={true} ariaLabelText={t(AddRequestText.providedDetailsText)} validate={(props) => validateAccommodationDetail(props, providedType)} initialValue={accommodationRequestData?.providedDetailsText}/>
                </TextBlock>
              </>}
            {/* Radio group not taking styles. Need margin after error message. */}
            <div style={{ marginBottom: '40px' }}>
              <RadioGroup label="" field={`temporaryorPermanentRequest${count}`} hideLabel={true} initialValue={accommodationRequestData?.requestedPeriodType} validate={accomodationStatus === 'AP' ? fieldRequired : undefined} validateOnChange={true}>
                <LabelText optional={accomodationStatus !== 'AP'} language={language} data-testid={'periodTypeLabel'}>{(accomodationStatus === 'AP' || accomodationStatus === 'CL') ? t(AddRequestText.actualPeriodType) : t(AddRequestText.isPermanentRequestEE)}</LabelText>
                <RadioContainer style={{ flexWrap: 'wrap', marginBottom: '0px' }}>
                  <Radio name={`temporaryorPermanentRequest${count}`} id={t(AddRequestText.temporaryTextEE)} label={t(AddRequestText.temporaryTextEE)} value={'T'} labelStyle={labelStyle} data-testid={'tempRequest'} onClick={() => { setPeriodType("T"); setShowAnticipateddate(true); setPeriodTypeDeselect(true); }}/>
                  <Radio name={`temporaryorPermanentRequest${count}`} id={t(AddRequestText.permanentTextEE)} label={t(AddRequestText.permanentTextEE)} value={'P'} labelStyle={labelStyle} data-testid={'permanentRequest'} onClick={() => { setPeriodType("P"); setShowAnticipateddate(false); setPeriodTypeDeselect(true); }}/>
                  {periodTypeDeselect &&
            <Radio name={`temporaryorPermanentRequest${count}`} label={'Deselect'} value={''} labelStyle={labelStyle} onClick={() => { setPeriodType(""); setShowAnticipateddate(false); setPeriodTypeDeselect(false); }}/>}
                </RadioContainer>
              </RadioGroup>
            </div>
            {(asConfig?.config?.asRep || (!asConfig?.config?.asRep)) && accomodationStatus != 'CL' &&
            <Col sm={12} className={'pl-0'}>
                <LabelText optional={true} language={language} data-testid={'startDateLabel'}>{t(AddRequestText.startDateEE)}</LabelText>
                <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                  <DatePickerV2 theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} field={`desiredStartDate${count}`} placeholderText={t(AddRequestText.dateFormatEE)} hideLabel={true} dateFormat={'MM/dd/yyyy'} data-testid={'startDate'} onChange={(date) => { tempStartDate.setDate(date !== null ? date.getDate() + 1 : new Date()); setStartDate(date); setMinDate(tempStartDate); }} validate={() => handleDateChange(startDate, anticipatedEndDate, 'startDate')} validateOnChange={false} initialValue={accommodationRequestData?.requestedStartDate}/>
                </DatePickerBlock>
              </Col>}
            {isEmployer && asConfig?.config?.asRep && (accomodationStatus === 'AP' || accomodationStatus === 'CL') &&
            <Col sm={12} className={'pl-0'}>
                <LabelText data-testid='actualBeginDateLabel'>{t(AddRequestText.actualBeginDate)}</LabelText>
                <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                  <DatePickerV2 data-testid='actualBeginDate' theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} field={`actualBeginDate${count}`} placeholderText={t(AddRequestText.dateFormatEE)} hideLabel={true} dateFormat={'MM/dd/yyyy'} validate={fieldRequired} initialValue={accommodationRequestData?.actualBeginDate} onChange={(date) => { tempActualStartDate.setDate(date !== null ? date.getDate() + 1 : new Date()); setActualStartDate(date); setMinDate(tempActualStartDate); }}/>
                </DatePickerBlock>
              </Col>}
            {showAnticipateddate && accomodationStatus != 'CL' &&
            <Col sm={12} className={'pl-0'}>
                <LabelText optional={true} language={language} data-testid='anticipatedEndDateLabel'>{t(AddRequestText?.anticipatedEndDate)}</LabelText>
                <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                  <DatePickerV2 data-testid='anticipatedEndDate' theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} id={'anticipatedEndDate'} field={`anticipatedEndDate${count}`} placeholderText={t(AddRequestText.dateFormatEE)} hideLabel={true} dateFormat={'MM/dd/yyyy'} minDate={minDate} onChange={(date) => setAnticipatedEndDate(date)} validateOnBlur={true} validate={(date) => validateEndDate(actualStartDate != null ? format(new Date(actualStartDate), 'P') : format(new Date("01/01/1990"), 'P'), date)} initialValue={accommodationRequestData?.anticipatedEndDate}/>
                </DatePickerBlock>
              </Col>}

            {isEmployer && asConfig?.config?.asRep && showAnticipateddate && (accomodationStatus === 'AP' || accomodationStatus === 'CL') &&
            <Col sm={12} className={'pl-0'}>
                <LabelText optional={accomodationStatus !== 'AP' && periodType !== "T"} language={language} data-testid='actualEndDateLabel'>{t(AddRequestText?.actualEndDate)}</LabelText>
                <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                  <DatePickerV2 data-testid='actualEndDate' theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} id={'actualEndDate'} field={`actualEndDate${count}`} placeholderText={t(AddRequestText.dateFormatEE)} hideLabel={true} dateFormat={'MM/dd/yyyy'} minDate={minDate} initialValue={accommodationRequestData?.actualEndDate} validate={(date) => (accomodationStatus === 'AP' && periodType === "T") ? validateEndDate(actualStartDate != null ? format(new Date(actualStartDate), 'P') : format(new Date("01/01/1990"), 'P'), date, true) : undefined}/>
                </DatePickerBlock>
              </Col>}
            {isEmployer && asConfig?.config?.asRep &&
            <>
                <Col md={12} xs={12} className={'pl-0'}>
                  <LabelText optional={accomodationStatus !== 'AP'} language={language} data-testid='restrictonOrLimitationLabel'>{t(AddRequestText.restrictonOrLimitation)} </LabelText>
                </Col>
                <Col md={6} xs={12} className={'pl-0'} style={{ marginBottom: '1.5rem' }}>
                  <StyledSelectInput data-testid='selectRlListDropDown' field={`restrictionsAndLimits${count}`} validateOnBlur={true} options={getSelectInputDropDown(asConfig?.config?.rlListDropDown, t)} multiple={true} onChange={(e) => {
                    if (e.target.value === '15') {
                        setRestrictionsOther(true);
                    }
                    else {
                        setRestrictionsOther(false);
                    }
                }} initialValue={accommodationRequestData?.restrictionsAndLimits} validate={accomodationStatus === 'AP' ? OneSelectedRequired : undefined}/>
                </Col>
                {restrictionsOther &&
                    <TextBlock sm={10} md={10} className={'pl-0'}>
                    <LabelText data-testid='suppRestrictionsAndLimitationsLabel'>{t(AddRequestText.suppRestrictionsAndLimitations)}</LabelText>
                    <TextInput 
                    //@ts-ignore //FIXME: typing error
                    validate={fieldRequired} label='' data-testid='suppRestrictionsAndLimitations' field={`suppRestrictionsAndLimitations${count}`} className={'d-block'} validateOnBlur={true} placeholder={''} type={'text'} textarea={true} mask={normalizeNewLines} hideLabel={true} ariaLabelText={t(AddRequestText.suppRestrictionsAndLimitations)} initialValue={accommodationRequestData?.suppRestrictionsAndLimitations}/>
                  </TextBlock>}

                <Col sm={12} className={'pl-0'}>
                  <LabelText optional={true} language={language} data-testid='interactiveBeginDateLabel'>{CustomEmployeeText(t(AddRequestText.interactiveBeginDate))}</LabelText>
                  <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                    <DatePickerV2 data-testid='interactiveBeginDate' theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} field={`interactiveBeginDate${count}`} placeholderText={t(AddRequestText.dateFormatEE)} hideLabel={true} dateFormat={'MM/dd/yyyy'} initialValue={accommodationRequestData?.interactiveBeginDate}/>
                  </DatePickerBlock>
                </Col>

                <Col sm={12} className={'pl-0'}>
                  <LabelText optional={true} language={language} data-testid='initialMeetingDateLabel'>{CustomEmployeeText(t(AddRequestText.initialMeetingDate))}</LabelText>
                  <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                    <DatePickerV2 data-testid='initialMeetingDate' theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} field={`initialMeetingDate${count}`} placeholderText={t(AddRequestText.dateFormatEE)} hideLabel={true} dateFormat={'MM/dd/yyyy'} initialValue={accommodationRequestData?.initialMeetingDate}/>
                  </DatePickerBlock>
                </Col>

                {(accomodationStatus === 'AP' || accomodationStatus === 'CL') &&
                    <><Col sm={12} className={'pl-0'}>
                    <LabelText optional={true} language={language} data-testid='determinationInfoReceivedDateLabel'>{CustomEmployeeText(t(AddRequestText.determinationInfoReceivedDate))}</LabelText>
                    <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                      <DatePickerV2 data-testid='determinationInfoReceivedDate' theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} field={`determinationInfoReceivedDate${count}`} placeholderText={t(AddRequestText.dateFormatEE)} hideLabel={true} dateFormat={'MM/dd/yyyy'} initialValue={accommodationRequestData?.determinationInfoReceivedDate}/>
                    </DatePickerBlock>
                  </Col><Col sm={12} className={'pl-0'}>
                      <LabelText optional={true} language={language} data-testid='determinationDateLabel'>{CustomEmployeeText(t(AddRequestText.determinationDate))}</LabelText>
                      <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                        <DatePickerV2 data-testid='determinationDate' theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} field={`determinationDate${count}`} placeholderText={t(AddRequestText.dateFormatEE)} hideLabel={true} dateFormat={'MM/dd/yyyy'} initialValue={accommodationRequestData?.determinationDate}/>
                      </DatePickerBlock>
                    </Col></>}
              </>}
            <RadioGroup label="" field={`isAlreadyReported${count}`} hideLabel={true} initialValue={accommodationRequestData?.existingAccommodation}>
              <LabelText optional={true} language={language} data-testid={'alreadyreportedLabel'}>{t(AddRequestText.alreadyReportedEE)}</LabelText>
              <RadioContainer>
                <Radio name={`isAlreadyReported${count}`} id={t(AddRequestText.yesTextEE)} label={t(AddRequestText.yesTextEE)} value={'Y'} labelStyle={labelStyle} data-testid={'alreadyReportedYes'} onClick={() => setExistingAccommodationDeselect(true)}/>
                <Radio name={`isAlreadyReported${count}`} id={t(AddRequestText.noTextEE)} label={t(AddRequestText.noTextEE)} value={'N'} labelStyle={labelStyle} data-testid={'alreadyReportedNo'} onClick={() => setExistingAccommodationDeselect(true)}/>
                {existingAccommodationDeselect &&
            <Radio name={`isAlreadyReported${count}`} label={'Deselect'} value={''} labelStyle={labelStyle} onClick={() => setExistingAccommodationDeselect(false)}/>}
              </RadioContainer>
            </RadioGroup>
            {isEmployer && asConfig?.config?.asRep &&
            <><Col sm={10} md={12} className={'pl-0'}>
                <LabelText optional={true} language={language} data-testid='priorCarrierLabel'>{t(AddRequestText.priorCarrier)}</LabelText>
              </Col><SelectBlock md={6} lg={4} className={'pl-0'}>
                  <SelectInput data-testid='priorCarrier' field={`priorCarrier${count}`} validateOnBlur={true} options={getSelectInputDropDown(AS_YES_NO_OPTIONS, t)} success={true} theme={themes.secureSite} style={paddingRight30} className={'lh-1'} initialValue={accommodationRequestData?.priorCarrier}/>
                </SelectBlock>
                {(accomodationStatus === 'IP' || accomodationStatus === 'AP') &&
                    <>
                    <Col md={12} xs={12} className={'pl-0'}>
                      <LabelText optional={true} language={language} data-testid='requestedAccommCostLabel'>{t(AddRequestText.requestedAccommCost)}</LabelText>
                    </Col>
                    <Col md={6} lg={4} className={'pl-0'}>
                      <TextInput data-testid='requestedAccommCost' label={t(AddRequestText.requestedAccommCost)} ariaLabelText={t(AddRequestText.requestedAccommCost)} field={`requestedAccommodationCost${count}`} hideLabel={true} validate={validateCost} validateOnChange={true} initialValue={accommodationRequestData?.requestedAccommodationCost}/>
                    </Col>
                  </>}
                {(accomodationStatus === 'CL' || accomodationStatus === 'AP') &&
                    <>
                    <Col md={12} xs={12} className={'pl-0'}>
                      <LabelText optional={true} language={language} data-testid='providedCostAmountLabel'>{t(AddRequestText.providedCostAmount)}</LabelText>
                    </Col>
                    <Col md={6} lg={4} className={'pl-0'}>
                      <TextInput data-testid='providedCostAmount' label={t(AddRequestText.providedCostAmount)} ariaLabelText={t(AddRequestText.providedCostAmount)} field={`providedCostAmount${count}`} hideLabel={true} validate={validateCost} validateOnChange={true} initialValue={accommodationRequestData?.providedCostAmount}/>
                    </Col>
                  </>}

              </>}
            <div style={{ marginBottom: '40px' }}>
            <RadioGroup label="" field={`workStatus${count}`} hideLabel={true} initialValue={accommodationRequestData?.accommodationWorkOrHome} validate={accomodationStatus === 'AP' ? fieldRequired : undefined} validateOnChange={true}>
              <LabelText optional={accomodationStatus !== 'AP'} language={language} data-testid={'workStatusLabel'}>{t(AddRequestText.backToWorkEE)}</LabelText>
              <RadioContainer style={{ flexWrap: 'wrap', marginBottom: '0px' }}>
                <Radio name={`workStatus${count}`} id={t(AddRequestText.backToWorkTextEE)} label={asConfig?.config?.rtwSawDropDown && getDropDownList(asConfig?.config?.rtwSawDropDown, t)[0].label} value={asConfig?.config?.rtwSawDropDown && getDropDownList(asConfig?.config?.rtwSawDropDown, t)[0].value} labelStyle={labelStyle} data-testid={'backToWork'} onClick={() => setworkStatusDeselect(true)}/>
                <Radio name={`workStatus${count}`} id={t(AddRequestText.stayingAtWorkTextEE)} label={asConfig?.config?.rtwSawDropDown && getDropDownList(asConfig?.config?.rtwSawDropDown, t)[1].label} value={asConfig?.config?.rtwSawDropDown && getDropDownList(asConfig?.config?.rtwSawDropDown, t)[1].value} labelStyle={labelStyle} data-testid={'stayingAtWork'} onClick={() => setworkStatusDeselect(true)}/>
                {workStatusDeselect &&
            <Radio name={`workStatus${count}`} label={'Deselect'} value={''} labelStyle={labelStyle} onClick={() => setworkStatusDeselect(false)}/>}
              </RadioContainer>
            </RadioGroup>
            </div>
            {isEmployer && asConfig?.config?.asRep && showAnticipateddate && accomodationStatus === 'CL' &&
            <Col sm={12} className={'pl-0'}>
                <LabelText optional={true} language={language} className={'d-block mt-4'} data-testid='rtwWithoutAccomodationDateLabel'>{t(AddRequestText?.rtwWithoutAccomodationDate)}</LabelText>
                <DatePickerBlock md={6} lg={4} className={'pl-0'}>
                  <DatePickerV2 data-testid='rtwWithoutAccomodationDate' theme={themes.lincoln} label={t(AddRequestText.datePickerLabelEE)} id={'rtwWithoutAccomodationDate'} field={`rtwWithoutAccomodationDate${count}`} placeholderText={t(AddRequestText.dateFormatEE)} hideLabel={true} dateFormat={'MM/dd/yyyy'} initialValue={accommodationRequestData?.rtwWithoutAccomodationDate}/>
                </DatePickerBlock>
              </Col>}
          </Scope>
        </Col>
      </Box>
    </StyledDiv>);
};
export default React.memo(AccomodationRequest);
