import React from 'react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import styled from 'styled-components';
import { Col, Heading, Icon, Row } from '@digitools/honest-react';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
const DentalButton = ({ image, imageName, component, event, isInlineImageAndTitle, isInline, title, text, hasOutArrow, action, isLeftCentered, href, eventAction, }) => {
    const { setActiveTab, tealiumFunction } = useDentalDataContext();
    const OuterDiv = styled.button `
    background: white;
    box-shadow: 4px 4px 12px rgba(171.57, 171.57, 171.57, 0.15);
    border-radius: 4px;
    border: ${({ theme }) => `.5px solid ${theme.palette.grayLighter}`};
    cursor: pointer;
  `;
    let outerDivClass = 'w-100 h-100';
    if (isInlineImageAndTitle && text) {
        outerDivClass += ' p-3';
    }
    if (!isInlineImageAndTitle && !text) {
        outerDivClass += ' align-items-center pl-0 pr-0 pt-2 pb-2';
    }
    if (!isInlineImageAndTitle && text) {
        outerDivClass += ' d-flex flex-column p-3 m-0 justify-content-between';
    }
    const DentalButtonContent = () => (<OuterDiv className={outerDivClass} id={`benefitButton-${title.replaceAll(' ', '')}`} onClick={() => {
            if (component) {
                setActiveTab(component);
            }
            tealiumFunction({ eventName: event, eventAction: eventAction || TEALIUM_EVENT.EVENT_ACTION.BUTTON });
            if (action) {
                action();
            }
        }}>
      {isInlineImageAndTitle && (<>
          <div className={'d-flex w-100 justify-content-between align-content-center pl-0'}>
            <div className={'d-flex text-left '} style={{ wordBreak: 'break-word' }}>
              <div className={'pr-2 align-content-center m-0'}>
                {imageName && (<Icon name={imageName} id={`${imageName}Icon`} size={isInlineImageAndTitle ? 'medium' : 'large'} color={'secondaryLight'}/>)}
                {image && image}
              </div>
              <Heading className={'text-secondary m-0'} elemStyle={'h4'}>
                {title}
              </Heading>
            </div>
            {hasOutArrow && (<Icon name={'out-arrow'} id={`${imageName}OutArrow`} size={'medium'} color={'secondaryLight'}/>)}
          </div>
          {text && <p className={'text-left mb-0 mt-0'}>{text}</p>}
        </>)}
      {!isInlineImageAndTitle && (<>
          <div className={isLeftCentered ? 'd-flex justify-content-between w-100' : ''} style={{ wordBreak: 'break-word' }}>
            <div className={isLeftCentered ? 'mt-0 text-left p-0' : 'w-100 h-100'}>
              <div className={'p-0 m-0'}>
                {imageName && (<Icon name={imageName} id={`${imageName}Icon`} size={isInlineImageAndTitle ? 'medium' : 'large'} color={'secondaryLight'}/>)}
              </div>
              <Heading className={'text-secondary p-0 mb-0 mt-2'} elemStyle={'h4'}>
                {title}
              </Heading>
            </div>
            {hasOutArrow && (<div className={'p-0 m-0'}>
                <Icon name={'out-arrow'} id={`${imageName}OutArrow`} size={'medium'} color={'secondaryLight'}/>
              </div>)}
          </div>
          {text && <p className={'text-left mb-0 p-0'}>{text}</p>}
        </>)}
    </OuterDiv>);
    const LinkButton = () => (<a style={{ textDecoration: 'none' }} href={href} target='_blank' rel='noreferrer'>
      <DentalButtonContent />
    </a>);
    return isInline ? (<Col>{href ? <LinkButton /> : <DentalButtonContent />}</Col>) : (<Row>
      <Col>{href ? <LinkButton /> : <DentalButtonContent />}</Col>
    </Row>);
};
export default DentalButton;
