import React, { useContext } from 'react';
import { Icon, NewModal as Modal } from '@digitools/honest-react';
import styled from 'styled-components';
import { BdmAddBeneficiaryModalDisplayTextEn as LanguageEn } from '../language/BdmAddBeneficiaryModalDisplayTextEn';
import { BdmAddBeneficiaryModalDisplayTextEs as LanguageEs } from '../language/BdmAddBeneficiaryModalDisplayTextEs';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { Language } from '../../../types/Language';
import { useDisclosure } from '@mantine/hooks';
const StyledIcon = styled(Icon) `
  color: #23669A;
  margin: .5rem .625rem;
  font-size: 1.2rem;
`;
const StyledDiv = styled('div') `
  color: #23669A;
  cursor: pointer;
`;
const StyledModal = styled(Modal) `
  .mantine-Modal-content {
    padding: 1rem 2.1875rem 0 !important;
  }
  .mantine-Modal-body {
    padding-top: 1rem !important;
  }
  .ggdDYg {
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-left: -1rem;
  }
`;
const BdmModalDeleteBeneficiary = ({ beneficiaryCategory, beneficiaryIndex, displayEditDeleteIconsFlag, handleDeleteBeneficiary }) => {
    const experienceContext = useContext(ExperienceContext);
    const displayText = experienceContext.language === Language.SPANISH ? LanguageEs : LanguageEn;
    const displayIconsValue = displayEditDeleteIconsFlag ? 'inline-block' : 'none';
    const RenderOpenModalButton = ({ showModal }) => {
        const handleClick = () => {
            showModal();
        };
        return (<StyledDiv id={`div-open-delete-modal-${beneficiaryCategory}${beneficiaryIndex}`} style={{ display: displayIconsValue }} onClick={handleClick}>
        <StyledIcon id={`btn-open-delete-modal-${beneficiaryCategory}${beneficiaryIndex}`} name={'trash'} size={'medium'} onClick={handleClick} style={{ display: displayIconsValue }}/>
        <span>{displayText.delete}</span>
      </StyledDiv>);
    };
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <StyledModal withCloseButton={true} title={displayText.delete} centered={true} opened={isOpen} onClose={() => {
            close();
        }} cancelButtonText={displayText.cancelButton} submitButtonText={displayText.delete} onSubmit={() => {
            handleDeleteBeneficiary(beneficiaryIndex);
            close();
        }} cancelButtonProps={{
            'data-testid': `btn-hide-delete-modal-${beneficiaryCategory}${beneficiaryIndex}`,
            buttonType: 'text',
            'aria-label': 'cancel delete beneficiary'
        }} submitButtonProps={{
            'data-testid': `btn-confirm-delete-${beneficiaryCategory}${beneficiaryIndex}`,
            buttonType: 'primary',
            'aria-label': 'confirm delete beneficiary'
        }} size={'368px'} closeOnClickOutside={true}>
        <p>
          {displayText.deleteMessage}
        </p>
      </StyledModal>
      <RenderOpenModalButton showModal={open}/>
    </>);
};
export default BdmModalDeleteBeneficiary;
