import React from 'react';
import { Button, NewModal as Modal } from '@digitools/honest-react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
const RenderOpenModalButton = ({ showModal, getLocalDisplayText }) => {
    const handleOnClick = () => {
        showModal();
    };
    return (<Button buttonType='secondary' id='Cancel' type='button' style={{ marginRight: '.5rem' }} onClick={handleOnClick}>
        {getLocalDisplayText('cancelButtonLabel')}
      </Button>);
};
export const CancelModal = ({ ...props }) => {
    const { getLocalDisplayText, history } = props;
    const navigate = useNavigate();
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <Modal withCloseButton={true} title={getLocalDisplayText('cancelModalHeading')} opened={isOpen} onClose={() => {
            close();
        }} cancelButtonText={getLocalDisplayText('cancelButtonLabel')} submitButtonText={getLocalDisplayText('okButton')} onSubmit={() => {
            navigate('/public/login');
            close();
        }} cancelButtonProps={{
            'data-testid': 'cancelModal-cancel',
            buttonType: 'secondary',
            'aria-label': 'return to registration form'
        }} submitButtonProps={{
            'data-testid': 'cancelModal-ok',
            buttonType: 'primary',
            'aria-label': 'confirm cancel'
        }}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <p tabIndex={0}>{getLocalDisplayText('cancelModalBodyText')}</p>
      </Modal>
      <RenderOpenModalButton getLocalDisplayText={getLocalDisplayText} showModal={open}/>
    </>);
};
export default CancelModal;
