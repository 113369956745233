import { Alert, Col, Row, TabContainer, TabSection } from '@digitools/honest-react';
import DeductibleAndCoinsurance, { DeductibleAndCoinsuranceInfo, } from 'src/packages/dental/pages/DeductibleAndCoinsurance';
import CoveredServices, { CoveredServicesInfo } from 'src/packages/dental/pages/CoveredServices';
import Claims, { ClaimsInfo } from 'src/packages/dental/pages/Claims';
import useMediaQuery from 'src/hooks/useMediaQuery';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import React from 'react';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import { CLAIMS, COVERED_SERVICES, DEDUCTIBLES_AND_COINSURANCE, tealiumFormat, } from 'src/packages/dental/providers/DentalDataProvider';
import DentalButton from 'src/packages/dental/components/DentalButton';
const TabContent = () => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const { activeTab, setActiveTab, currentMember, tealiumFunction } = useDentalDataContext();
    const { t } = useTranslate();
    return (<>
      {isMobile ? (<>
          {!activeTab && (<>
              <Row className={'mt-3'}>
                <Col>
                  <p>{t(DentalText.disclaimerText)}</p>
                </Col>
              </Row>
              <Row>
                <DentalButton isInline={true} event={tealiumFormat(COVERED_SERVICES)} component={COVERED_SERVICES} 
            // TODO: change honest version to 4.4.9
            title={t(DentalText.coveredServicesText)} imageName='covered-services'/>
                <DentalButton isInline={true} event={tealiumFormat(DEDUCTIBLES_AND_COINSURANCE)} component={DEDUCTIBLES_AND_COINSURANCE} title={t(DentalText.deductibleText)} imageName='deductibles-and-coinsurance'/>
                <DentalButton isInline={true} event={tealiumFormat(CLAIMS)} component={CLAIMS} title={t(DentalText.claimsText)} imageName='claims'/>
              </Row>
            </>)}
          {activeTab === COVERED_SERVICES && <CoveredServices />}
          {activeTab === DEDUCTIBLES_AND_COINSURANCE && <DeductibleAndCoinsurance />}
          {activeTab === CLAIMS && <Claims />}
        </>) : (<div style={{
                paddingTop: '27px',
                textAlign: 'left',
            }} className='mb-4'>
          <p>{t(DentalText.disclaimerText)}</p>
          <TabContainer initialActiveTab={(activeTab || COVERED_SERVICES) + '_tab'}>
            <TabSection label={t(DentalText.coveredServicesText)} name={`${COVERED_SERVICES}_tab`} onClick={() => {
                setActiveTab(COVERED_SERVICES);
                tealiumFunction({ buttonName: tealiumFormat(COVERED_SERVICES) });
            }}>
              {currentMember?.planOverview?.inNetworkOnlyClause && (<Alert type={'info'}>{currentMember.planOverview.inNetworkOnlyClause}</Alert>)}
              <CoveredServicesInfo />
            </TabSection>
            <TabSection label={t(DentalText.deductibleText)} name={`${DEDUCTIBLES_AND_COINSURANCE}_tab`} aria-selected={true} onClick={() => {
                setActiveTab(DEDUCTIBLES_AND_COINSURANCE);
                tealiumFunction({ buttonName: tealiumFormat(DEDUCTIBLES_AND_COINSURANCE) });
            }}>
              {currentMember?.planOverview?.inNetworkOnlyClause && (<Alert type={'info'}>{currentMember.planOverview.inNetworkOnlyClause}</Alert>)}
              <DeductibleAndCoinsuranceInfo />
            </TabSection>
            <TabSection label={t(DentalText.claimsText)} name={`${CLAIMS}_tab`} onClick={() => {
                setActiveTab(CLAIMS);
                tealiumFunction({ buttonName: tealiumFormat(CLAIMS) });
            }}>
              {currentMember?.planOverview?.inNetworkOnlyClause && (<Alert type={'info'}>{currentMember.planOverview.inNetworkOnlyClause}</Alert>)}
              <ClaimsInfo />
            </TabSection>
          </TabContainer>
        </div>)}
    </>);
};
export default TabContent;
