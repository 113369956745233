import React from 'react';
import { Icon, NewModal as Modal } from '@digitools/honest-react';
import styled from 'styled-components';
import useExperience from '../../../hooks/useExperience';
import { Language } from '../../../types/Language';
import { BdmDisplayTextEn as LanguageEn } from '../language/BdmDisplayTextEn';
import { BdmDisplayTextEs as LanguageEs } from '../language/BdmDisplayTextEs';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import { useDisclosure } from '@mantine/hooks';
const StyledIcon = styled(Icon) `
  color: #23669a;
  margin: 0.5rem 0.625rem;
  font-size: 1.2rem;
`;
const StyledDiv = styled('div') `
  color: #23669a;
  cursor: pointer;
`;
const BdmModalGetHelp = ({ hasIcon, openModalText }) => {
    const displayText = useExperience().language === Language.SPANISH ? LanguageEs : LanguageEn;
    const { trackEvent } = useTealium();
    const RenderOpenModalButton = ({ showModal }) => {
        const handleClick = () => {
            showModal();
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.GET_HELP,
                event_name: 'Get Help',
            });
        };
        return (<StyledDiv id={'div-open-get-help-modal'} onClick={handleClick}>
        {hasIcon && <StyledIcon id={'btn-open-get-help-modal'} name={'question'} size={'medium'}/>}
        <span>{openModalText}</span>
      </StyledDiv>);
    };
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <Modal withCloseButton={true} title={displayText.getHelp} centered={true} opened={isOpen} onClose={() => {
            close();
        }} cancelButtonText={displayText.closeBtnText} submitButton={false} cancelButtonProps={{
            'data-testid': 'btn-get-help-okay',
            buttonType: 'primary',
            'aria-label': 'close get help'
        }} closeOnClickOutside={true}>
        <p>{displayText.getHelpModalText}</p>
      </Modal>
      <RenderOpenModalButton showModal={open}/>
    </>);
};
export default BdmModalGetHelp;
