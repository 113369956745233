import { themes } from "@digitools/honest-react";
import Icon from "@digitools/honest-react/dist/lib/components/Icon";
import React from "react";
export const CliConfirmationTextClaimDocs = {
    pageHeading: {
        english: <><strong>Thank you!</strong> You have successfully submitted a new claim.</>,
        spanish: <><strong>Gracias!</strong> Ha enviado con éxito un nuevo.</>, // <- for claim only, 'una nueva ausencia' - this should conjugate different because of masculine/feminine nouns
    },
    subHeading: {
        english: 'Please keep your claim number handy for future reference.',
        spanish: 'Mantenga su número de reclamo a mano para futuras referencias.'
    },
    videoMessage: {
        english: <>For more information on next steps,&nbsp;
        <strong>
          <a id='video-message-link' href='#' onClick={() => window.open("https://players.brightcove.net/1134849171001/KW4KiINzkI_default/index.html?videoId=6306802973112", "_blank", "height=400px, width=600px")}>
            watch our step by step video of the claim process.
            </a>
          </strong></>,
        spanish: <>Para obtener más información sobre los próximos pasos,&nbsp;
        <strong>
          <a id='video-message-link' href='#' onClick={() => window.open("https://players.brightcove.net/1134849171001/KW4KiINzkI_default/index.html?videoId=6306802973112", "_blank", "height=400px, width=600px")}>
            vea nuestro video paso a paso del proceso de reclamación.
            </a>
        </strong></>
    },
    claimNumber: {
        english: 'Pending',
        spanish: 'Pendiente'
    },
    claimNumberMessageTitle: {
        english: 'Important message about your claim number',
        spanish: 'Mensaje importante sobre su número de reclamo'
    },
    claimNumberMessageBody: {
        english: 'You will receive a confirmation email within the next 24 hours with an assigned claim number to your case. Your claim number can then be used to track the status of your request.',
        spanish: 'Recibirá un correo electrónico de confirmación dentro de las próximas 24 horas con un número de reclamo asignado a su caso. Su número de reclamo se puede usar para rastrear el estado de su solicitud.'
    },
    eventNumber: {
        english: 'CLAIM NUMBER: ',
        spanish: 'NUMERO DE RECLAMO: ' //TODO: get translation
    },
    ourNextStepsHeading: {
        english: 'Our Next Steps',
        spanish: 'Nuestros Próximos Pasos',
    },
    ourNextStepsBody: {
        english: 'Your claim specialist will review your request and send you related correspondence within 5 business days via your preferred contact method, which you indicated during intake.',
        spanish: 'Su especialista en reclamos revisará su solicitud y le enviará la correspondencia relacionada dentro de los 5 días hábiles a través de su método de contacto preferido, que indicó durante la admisión.',
    },
    nextStepsHeading: {
        english: 'Your Next Steps',
        spanish: 'Tus Siguientes Pasos',
    },
    nextSteps1Heading: {
        english: 'Contact your manager',
        spanish: 'Comuníquese con su gerente',
    },
    nextSteps1Body: {
        english: 'If you haven’t already done so, please contact your manager to inform them you’ll be out of work.',
        spanish: 'Si aún no lo ha hecho, comuníquese con su gerente para informarle que no tendrá trabajo.'
    },
    nextSteps2Heading: {
        english: 'Send the Attending Physician Statement to your Provider',
        spanish: 'Envíe la Declaración del médico tratante a su proveedor',
    },
    nextSteps2Body: {
        english: 'Download the Attending Physician Statement and send to your provider to complete. This will confirm your medical condition, which is an important step in the claims process.',
        spanish: 'Descargue la Declaración del médico tratante y envíela a su proveedor para que la complete. Esto confirmará su condición médica, que es un paso importante en el proceso de reclamos.'
    },
    nextSteps2Link: {
        english: <a data-testid='phys-auth-link' onClick={() => window.open('/customer/employee-forms', '_blank')}>
          <Icon name='download' style={{ marginRight: '.5rem', color: themes.lincoln.palette.secondaryDark }}/><strong>Download the Attending Physician Statement </strong>
          </a>,
        spanish: <a data-testid='phys-auth-link' onClick={() => window.open('/customer/employee-forms', '_blank')}>
      <Icon name='download' style={{ marginRight: '.5rem', color: themes.lincoln.palette.secondaryDark }}/><strong>Descargue la Declaración del médico tratante</strong>
      </a>
    },
    nextSteps3Heading: {
        english: 'Sign the medical authorization form and send the signed form to your Claims Team',
        spanish: 'Firme el formulario de autorización médica y envíe el formulario firmado a su Equipo de Reclamos',
    },
    nextSteps3Body: {
        english: 'Download the medical authorization form, sign it, and send it back to your claims team.The signed medical authorization form will be reviewed by your claims specialist and will expedite the processing of your claim. If you prefer, you may also email the document to:',
        spanish: 'Descargue el formulario de autorización médica, fírmelo y envíelo de regreso a su equipo de reclamos. El formulario de autorización médica firmado será revisado por su especialista en reclamos y acelerará el procesamiento de su reclamo. Si lo prefiere, también puede enviar el documento por correo electrónico a:'
    },
    nextSteps3Link: {
        english: <><a data-testid='med-auth-link' onClick={() => window.open('/customer/employee-forms', '_blank')}>
      <Icon name='download' style={{ marginRight: '.5rem', color: themes.lincoln.palette.secondaryDark }}/><strong>Download medical authorization form </strong>
      </a> <a href='mailto: disabilityclaims@lfg.com' data-testid='disability-documents-email-link' style={{ marginLeft: '1rem' }}><Icon name='envelope'/><strong>  disabilityclaims@lfg.com</strong></a></>,
        spanish: <><a data-testid='med-auth-link' onClick={() => window.open('/customer/employee-forms', '_blank')}>
      <Icon name='download' style={{ marginRight: '.5rem', color: themes.lincoln.palette.secondaryDark }}/><strong>Descargar formulario de autorización médica </strong>
      </a> <a href='mailto: disabilityclaims@lfg.com' data-testid='disability-documents-email-link' style={{ marginLeft: '1rem' }}><Icon name='envelope'/><strong>  disabilityclaims@lfg.com</strong></a></>
    },
    nextSteps4Heading: {
        english: 'Upload your documents',
        spanish: 'Sube tus documentos'
    },
    nextSteps4Body: {
        english: <>Within 24 hours, you can upload any relevant documents by visiting <strong>My claims {">"} Manage my claim {">"} Upload a document</strong>'</>,
        spanish: <>Dentro de las 24 horas, puede cargar cualquier documento relevante visitando <strong>Mis reclamos {">"} Administrar mi reclamo {">"} Cargar un documento</strong></>
    },
    nextSteps5Heading: {
        english: 'Stay updated about your claim',
        spanish: 'Manténgase actualizado sobre su reclamo'
    },
    nextSteps5Body: {
        english: 'Within 24 hours, you can also view your claims status online, report your return-to-work date, upload documents and more.',
        spanish: 'Dentro de las 24 horas, también puede ver el estado de sus reclamos en línea, informar su fecha de regreso al trabajo, cargar documentos y más.'
    },
    automatedMessageText: {
        english: <>
      <br /><strong>Interested in getting automated claim status updates by text?</strong><br />
        Click the link below to update your communicated preferences in your portal profile. All communication will be sent in English.
      </>,
        spanish: <>
      <br /><strong>¿Está interesado en recibir actualizaciones automáticas del estado de las reclamaciones por mensaje de texto?</strong><br />
        Haga clic en el enlace a continuación para actualizar sus preferencias comunicadas en su perfil de portal. Toda la comunicación se enviará en inglés.
      </>
    },
    questions: {
        english: 'My Lincoln Portal® is your one-stop shop for everything you need to manage your claim. You can access the portal 24 hours a day, 7 days a week, from your desktop, tablet, or mobile device.',
        spanish: 'My Lincoln Portal® es su ventanilla única para todo lo que necesita para administrar su reclamo. Puede acceder al portal las 24 horas del día, los 7 días de la semana, desde su computadora de escritorio, tableta o dispositivo móvil.'
    },
    videoUrl: 'https://players.brightcove.net/1134849171001/KW4KiINzkI_default/index.html?videoId=6306802973112',
    videoId: '6306802973112',
    videoHeading: {
        english: 'Have questions about your next steps in the claim process?',
        spanish: '¿Tiene preguntas sobre los pasos de su nido en el proceso de reclamo?'
    },
    videoSubheading: {
        english: 'Watch this video',
        spanish: 'Mira este video'
    }
};
