import { Button, Icon, NewModal as Modal, Spinner, StepperContext, themes } from '@digitools/honest-react';
import { useFormState } from 'informed';
import React, { useContext, useEffect, useRef, useState } from 'react';
import useTranslate from '../../../hooks/useTranslate';
import styled from 'styled-components';
import { SaveForLaterText } from '../../eoi/i18n/SaveForLaterText';
import useCliDataContext from '../hooks/useCliDataContext';
import { DeleteInterviewData, SaveInterviewData, UpdateInterviewData } from '../api/CliApiCalls';
import useExperience from '../../../hooks/useExperience';
import useAuthentication from 'src/hooks/useAuthentication';
import { addDays, format, startOfToday } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import useCliCustomerConfigContext from '../hooks/useCliContext';
import useCliContext from '../hooks/useCliContext';
import { deriveInterviewAnswers } from '../utils/apiUtils';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { getEventStatusByCurrentStep } from '../utils/eventStatusStepUtil';
import { useDisclosure } from '@mantine/hooks';
const StyledLaterButton = styled(Button) `
  @media (max-width: 1200px) {
    padding: 0 1rem;
  }
`;
const StyledDeleteButton = styled(Button) `
  @media (max-width: 1200px) {
    padding: 0 1rem;
  }
`;
const StyledModal = styled(Modal) `
  .mantine-Modal-content{
    padding :1rem 2.8125rem 1rem !important;
  }
  .ggdDYg {
    flex-direction: row-reverse;
    margin-left: -1rem;
  }
  p {
    margin: 0.875rem 0px 1.875rem 0px;
  }
`;
const SaveForLaterButton = ({ analyticsPageName }) => {
    const { setClaimantData, setAbsenceData, setContactData, claimantData, absenceData, contactData, isDirectDepositSuccess, paymentMethodData, setIsSflModalOpen, setPaymentMethodData, setIsDirectDepositSuccess, } = useCliDataContext();
    const { saveForLaterEntries, setSaveForLaterEntries } = useCliContext();
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    const formState = useFormState();
    const { authHeader, user } = useAuthentication();
    const { language, experienceCode } = useExperience();
    let navigate = useNavigate();
    const stepper = useContext(StepperContext);
    const cliConfigContext = useCliCustomerConfigContext();
    const sflDays = cliConfigContext.saveForLaterDays ? cliConfigContext.saveForLaterDays : 0;
    const sflApplicationExpirationDate = cliConfigContext && format(addDays(startOfToday(), sflDays), 'P');
    const [displaySaveForLaterModal, setDisplaySaveForLaterModal] = useState(false);
    const [success, setSuccess] = useState(true);
    const [loading, setLoading] = useState(false);
    const setIsOpenRef = useRef(() => { });
    useEffect(() => {
        if (displaySaveForLaterModal) {
            setIsOpenRef.current(true);
            setDisplaySaveForLaterModal(true);
        }
        else if (!displaySaveForLaterModal) {
            setIsOpenRef.current(false);
            setDisplaySaveForLaterModal(false);
        }
    }, [displaySaveForLaterModal]);
    const SaveButton = ({ showModal }) => {
        const interviewAnswers = deriveInterviewAnswers({
            claimantData,
            absenceData,
            contactData,
            formState,
        });
        const handleOnClick = async () => {
            setIsSflModalOpen(true);
            showModal();
            setLoading(true);
            if (!formState.invalid) {
                try {
                    setDisplaySaveForLaterModal(true);
                    if (claimantData) {
                        // @ts-ignore
                        setClaimantData({ ...claimantData, ...formState?.values?.claimantData });
                        // @ts-ignore
                        setAbsenceData({ ...absenceData, ...formState?.values?.absenceData });
                        // @ts-ignore
                        setContactData({ ...contactData, ...formState?.values?.contactData });
                        if (!saveForLaterEntries?.rowID) {
                            SaveInterviewData({
                                savedData: interviewAnswers,
                                language: language,
                                title: experienceCode,
                                //@ts-ignore
                                preferredEmailAddress: user?.emailAddress,
                            }, authHeader()).then((response) => {
                                setSaveForLaterEntries({
                                    rowID: response?.data?.rowID,
                                    updatedDateAndTime: response?.data?.lastUpdatedDateTime,
                                });
                            });
                        }
                        else if (saveForLaterEntries?.rowID) {
                            UpdateInterviewData({
                                savedData: interviewAnswers,
                                language: language,
                                title: experienceCode,
                                rowID: saveForLaterEntries?.rowID,
                                //@ts-ignore
                                preferredEmailAddress: user?.emailAddress,
                            }, authHeader()).then((response) => {
                                setSaveForLaterEntries((prevState) => {
                                    return {
                                        ...prevState,
                                        updatedDateAndTime: response?.data?.lastUpdatedDateTime,
                                    };
                                });
                            });
                        }
                    }
                }
                catch (error) {
                    setSuccess(false);
                    console.error('Error sending data to endpoint');
                }
            }
            setLoading(false);
        };
        return (<StyledLaterButton type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-save-button'}>
        <Icon aria-hidden={true} name={'save'} color={'secondary'} size={'small'} style={{ marginRight: '0.5rem' }}/>
        {t(SaveForLaterText.saveForLater)}
      </StyledLaterButton>);
    };
    // Delete Modal wraps this button
    const DeleteButton = ({ showModal }) => {
        const handleOnClick = () => {
            setIsSflModalOpen(true);
            showModal();
        };
        return (<StyledDeleteButton type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-delete-button'} style={{ marginLeft: '0rem' }}>
        <Icon aria-hidden={true} name={'trash'} color={'primaryDark'} size={'small'} style={{ marginRight: '0.5rem' }}/>
        <span style={{ color: themes.lincoln.palette.primaryDark }}>{t(SaveForLaterText.deleteApplication)}</span>
      </StyledDeleteButton>);
    };
    const isDDSelected = () => (analyticsPageName === 'payment method' || analyticsPageName === 'review') &&
        paymentMethodData?.preferredpaymentmethod === 'directDeposit';
    const [isDeleteOpen, deleteModal] = useDisclosure(false);
    const [isSaveOpen, saveModal] = useDisclosure(false);
    return (<>
      {saveForLaterEntries?.rowID && (<>
          <StyledModal title={t(SaveForLaterText.deleteApplication)} centered={true} opened={isDeleteOpen} onClose={() => {
                setIsSflModalOpen(false);
                setDisplaySaveForLaterModal(false);
                deleteModal.close();
            }} cancelButtonText={t(SaveForLaterText.returnToApplication)} submitButtonText={t(SaveForLaterText.deleteApplicationModal)} onSubmit={async () => {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                    event_name: 'delete application',
                    event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                    event_version: analyticsPageName,
                    event_status: getEventStatusByCurrentStep(stepper, t),
                });
                try {
                    await DeleteInterviewData(saveForLaterEntries?.rowID, authHeader());
                    setSaveForLaterEntries(null);
                    setClaimantData({});
                    setAbsenceData({});
                    setContactData({});
                    setPaymentMethodData({});
                    navigate(`/employee/home`);
                    setIsSflModalOpen(false);
                }
                catch (error) {
                    console.log('Error deleting application', error);
                    t(SaveForLaterText.saveForLaterDeleteErrorMessage);
                    deleteModal.close();
                }
            }} cancelButtonProps={{
                'data-testid': 'sfl-return-button',
                buttonType: 'text',
                'aria-label': 'return to application'
            }} submitButtonProps={{
                'data-testid': 'sfl-delete-application',
                buttonType: 'primary',
                'aria-label': 'delete application'
            }} 
        // footerContentPosition={'flex-start'}
        theme={themes.lincoln} data-testid={'sfl-delete-modal'}>
            <>
              <p>
                <strong>{t(SaveForLaterText.deleteApplicationHeaderSuccess)}</strong>
              </p>
              <p className={`mt-1`}>{t(SaveForLaterText.deleteApplicationTextSuccess)}</p>
            </>
          </StyledModal>
          <DeleteButton showModal={deleteModal.open}/>
        </>)}

      <StyledModal title={t(SaveForLaterText.saveForLater)} centered={true} opened={isSaveOpen} onClose={() => {
            setIsSflModalOpen(false);
            setDisplaySaveForLaterModal(false);
            saveModal.close();
        }} cancelButtonText={t(SaveForLaterText.returnToApplication)} submitButtonText={t(SaveForLaterText.exitApplication)} onSubmit={() => {
            navigate(`/employee/home`);
            setIsSflModalOpen(false);
            setPaymentMethodData({});
            if (isDirectDepositSuccess) {
                setIsDirectDepositSuccess(false);
            }
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_name: 'save for later',
                event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                event_version: analyticsPageName,
                event_status: getEventStatusByCurrentStep(stepper, t),
            });
            saveModal.close();
        }} cancelButtonProps={{
            'data-testid': 'sfl-return-button',
            buttonType: 'text',
            'aria-label': 'return to application',
        }} submitButtonProps={{
            'data-testid': 'sfl-exit-application',
            buttonType: 'primary',
            'aria-label': 'exit application',
        }} 
    // footerContentPosition={'flex-start'}
    theme={themes.lincoln} data-testid={'sfl-save-modal'}>
        {loading ? (<Spinner id='sfl-buttons-spinner'/>) : success ? (<>
            <p>
              <strong>
                {isDDSelected()
                ? t(SaveForLaterText.paymentSavedApplicationHeaderSuccess)
                : t(SaveForLaterText.savedApplicationHeaderSuccess)}
              </strong>
            </p>
            <p className={`mt-1`}>
              {isDDSelected()
                ? t(SaveForLaterText.paymentSavedApplicationTextSuccess)
                : t(SaveForLaterText.savedApplicationTextSuccess)}
              <strong>{sflApplicationExpirationDate}</strong>
              {'.'}
            </p>
          </>) : (<>
            <p>
              <strong>{t(SaveForLaterText.savedApplicationHeaderError)}</strong>
            </p>
            <p className={`mt-1`}>{t(SaveForLaterText.savedApplicationTextError)}</p>
          </>)}
      </StyledModal>
      <SaveButton showModal={saveModal.open}/>
    </>);
};
export default SaveForLaterButton;
