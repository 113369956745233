import { Col, Heading, Row } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import useStepperContext from './useStepperContext';
import { StyledBox, RowCenterOnDesktop } from './StyledComponents';
import { ActionCardContainer, ActionCardFooter, ActionCardHeader, CardIcon } from './ActionCardBox';
import { VanityPageText } from '../constants/VanityPageText';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { Language } from 'src/types/Language';
import useExperience from 'src/hooks/useExperience';
import { Link } from 'react-router-dom';
const RoleSelect = () => {
    const { t } = useTranslate();
    const { next } = useStepperContext();
    const { trackEvent } = useTealium();
    const { language } = useExperience();
    const handleOnClickEmployee = () => {
        // console.log('this is an employee sending to guided exp');
        next();
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: t(VanityPageText.imEmployee),
        });
    };
    const handleOnClickEmployer = () => {
        // console.log('this is an employee sending to guided exp');
        next();
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.MIGRATION_HELP,
            event_name: t(VanityPageText.imEmployerOrBroker),
        });
    };
    return (<div>
      <StyledBox>
        <RowCenterOnDesktop>
          <Col>
            <Heading elemType='h2' color='primary'>
              {t(VanityPageText.welcome)}
            </Heading>
          </Col>
        </RowCenterOnDesktop>
        <RowCenterOnDesktop>
          <Col>
            <Heading elemType='h3' style={{ fontSize: '1.25rem' }}>
              {t(VanityPageText.letsHelp)}
            </Heading>
          </Col>
        </RowCenterOnDesktop>
        <hr />
        <Row style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
          <ActionCardContainer onClick={() => handleOnClickEmployee()}>
            <ActionCardHeader>
              <CardIcon color='primaryDark' name='badge'/>
            </ActionCardHeader>
            <ActionCardFooter>
              {t(VanityPageText.imEmployee)}
            </ActionCardFooter>
          </ActionCardContainer>

          <ActionCardContainer as={Link} 
    // @ts-ignore
    to={{
            pathname: '/public/login',
            search: `?employer=true${language === Language.SPANISH ? '&lang=es' : ''}`,
        }} onClick={() => handleOnClickEmployer()}>
            <ActionCardHeader>
              <CardIcon color='primary' name='person-with-tie'/>
            </ActionCardHeader>
            <ActionCardFooter>
              {t(VanityPageText.imEmployerOrBroker)}
            </ActionCardFooter>
          </ActionCardContainer>
        </Row>
      </StyledBox>
    </div>);
};
export default RoleSelect;
