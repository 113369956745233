import React, { useContext, useRef, useState } from 'react';
import { Alert, Col, Form, Heading, Row, SelectInput, StepperContext } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import { AboutYouText } from './../../constants/AboutYouText';
import useExperience from '../../../../hooks/useExperience';
import { CLI_COUNTRIES, CLI_PROVINCES, CLI_PROVINCES_VALUES, CLI_STATES } from '../../constants/CliConstants';
import { isNotEmpty } from '../../../../utils/validationUtils';
import useCliDataContext from '../../hooks/useCliDataContext';
import { Scope } from 'informed';
import StepperButtons from '../../components/StepperButtons';
import PersonalInformation from '../../components/PersonalInformation';
import ResidentialInformation from '../../components/ResidentialInformation';
import PreferredContactInformation from '../../components/PreferredContactInformation';
import { selectInputErrorMessage } from '../../../eoi/utils/FormFieldValidations';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
import { Experience } from 'src/types/Experience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import RouterPrompt from '../../components/RouterPrompt';
import GettingStartedText from '../start/GettingStartedText';
import useOnScreen from 'src/hooks/visibilityChecker';
import { getUserContactMethod } from '../../utils/cliUtils';
import { getEnvVariable } from 'src/utils/envUtils';
import useAuthentication from 'src/hooks/useAuthentication';
import styled from 'styled-components';
const StyledAlert = styled(Alert) `
  border : 0px ;
`;
const AboutYou = () => {
    const stepper = useContext(StepperContext);
    const { t } = useTranslate();
    const { experience } = useExperience();
    const { claimantData, setClaimantData, saveAndUpdateInterview, hasEligibilityEmpState, } = useCliDataContext();
    const { primaryWorkLocation, employmentCountry, employmentProvince, employmentState } = useCliCustomerConfigContext();
    const { trackEvent } = useTealium();
    const cliConfigContext = useCliCustomerConfigContext();
    const pageTitleLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(AboutYouText.pageTitleEE) : t(AboutYouText.pageTitleER));
    const [paidLeaveBlock, setPaidLeaveBlock] = useState(false);
    const { user } = useAuthentication();
    const submit = (values) => {
        // @ts-ignore
        const mergedClaimantData = { ...claimantData, ...values.claimantData };
        mergedClaimantData.phone = values?.claimantData?.phone ? values?.claimantData?.phone?.replace(/[^0-9.]/g, '') : claimantData?.phone?.replace(/[^0-9.]/g, '');
        mergedClaimantData.claimantpreferredfax = values?.claimantData?.claimantpreferredfax ? values?.claimantData?.claimantpreferredfax?.replace(/[^0-9.]/g, '') : claimantData?.claimantpreferredfax?.replace(/[^0-9.]/g, '');
        mergedClaimantData.uniqueID = mergedClaimantData.ssn ? 'ssn' : 'empID';
        { /* Need to clear out emp state/province values if user selects another country but CAN or US */ }
        if (values.claimantData.selectedEmpCountryID !== 'CAN' && values.claimantData.selectedEmpCountryID !== 'USA') {
            mergedClaimantData.selectedEmpStateID = undefined;
        }
        { /* Need to clear out state/province values if user selects another country but CAN or US */ }
        if (values.claimantData.selectedCountryID !== 'CAN' && values.claimantData.selectedCountryID !== 'USA') {
            mergedClaimantData.selectedStateID = undefined;
        }
        /* persist data from form to CliDataContext @ts-ignore*/
        // @ts-ignore
        setClaimantData(mergedClaimantData);
        if (getEnvVariable('dnPaidLeaveSubmission') && user?.customerInContext?.smallMid && cliConfigContext?.paidLeaveStates.includes(mergedClaimantData?.selectedEmpStateID || 'X')) {
            setPaidLeaveBlock(true);
        }
        else {
            setPaidLeaveBlock(false);
            saveAndUpdateInterview(values);
            trackEvent({
                event_action: 'selfservice',
                event_name: experience === Experience.EMPLOYEE && cliConfigContext && cliConfigContext?.hasSaveForLater ? 'save and continue' : 'continue',
                event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                event_version: pageTitleLabel, //TODO - inform swathi the 'about your associate' is a misnamed title == she was looking at a customized employee title
                user_contact_method: getUserContactMethod(mergedClaimantData),
                event_status: 'step 2',
            });
            /* Send to the next step */
            stepper.next();
            window.scrollTo(0, 0);
        }
    };
    const ref = useRef();
    const isVisible = useOnScreen(ref);
    let initialEmpCountryValue = 'USA';
    // Eligibility coming back with province ID but not country, this sets default country accordingly
    // @ts-ignore
    if ((!claimantData?.selectedEmpCountryID) && CLI_PROVINCES_VALUES.includes(claimantData?.selectedEmpStateID)) {
        initialEmpCountryValue = 'CAN';
    }
    return (<div ref={ref}>
      <Heading elemType='h2' color='primary'>{pageTitleLabel}</Heading>
      {/* Used to protect multiple prompts, needs to be fixed at the stepper level (we need to use one prompt at the intake level) */}
      {stepper.currentStep !== t(GettingStartedText.pageTitle) && isVisible && <RouterPrompt />}
      <Form onSubmit={submit}>
        {/* Destructure Form State from wrapping <Form> to access form's values (this has to happen as direct child of <Form>)*/}
        {({ formState }) => (<>
            <PersonalInformation />
            <PreferredContactInformation />
            <ResidentialInformation />
            {/* @ts-ignore */}
            <Scope scope='claimantData'>
              <BorderLessGrayBox style={{ marginTop: '1rem' }}>
                <Heading elemType='h3' elemStyle='h4'>{primaryWorkLocation && t(primaryWorkLocation)}</Heading>
              </BorderLessGrayBox>
              <Row>
                <Col md={6} xs={12}>
                  <SelectInput data-testid='selectedEmpCountryID' ariaLabelText={employmentCountry && t(employmentCountry)} field={'selectedEmpCountryID'} label={employmentCountry && t(employmentCountry)} validateOnBlur={true} validate={isNotEmpty} placeholderText={t(AboutYouText.countrySelect)} options={CLI_COUNTRIES} initialValue={initialEmpCountryValue}/>
                </Col>
                <Col md={6} xs={12}>
                  {/* @ts-ignore */}
                  {formState.values['claimantData'] &&
                <>
                      {formState.values['claimantData']['selectedEmpCountryID'] === 'USA' && (<SelectInput disabled={(claimantData?.selectedEmpStateID && hasEligibilityEmpState) === true} data-testid='usaStateEmpId' ariaLabelText={employmentState && t(employmentState)} field={'selectedEmpStateID'} label={employmentState && t(employmentState)} placeholderText={employmentState && t(employmentState)} validateOnBlur={true} validate={isNotEmpty} options={CLI_STATES} errorMessage={selectInputErrorMessage} initialValue={claimantData && claimantData.selectedEmpStateID?.trim()}/>)}
                      {formState.values['claimantData']['selectedEmpCountryID'] === 'CAN' && (<SelectInput disabled={(claimantData?.selectedEmpStateID && hasEligibilityEmpState) === true} data-testid='canadaStateEmpId' ariaLabelText={employmentProvince && t(employmentProvince)} field={'selectedEmpStateID'} label={employmentProvince && t(employmentProvince)} placeholderText={employmentProvince && t(employmentProvince)} validateOnBlur={true} validate={isNotEmpty} errorMessage={selectInputErrorMessage} options={CLI_PROVINCES} initialValue={claimantData && claimantData.selectedEmpStateID?.trim()}/>)}
                    </>}
                </Col>
                <div style={{ margin: '.5rem', marginTop: '-.5rem', }}>
                  <em>
                    <span data-testid={`about-my-absence-disclaimer`}>{t(AboutYouText.disclaimer)}</span>
                  </em>
                </div>
              </Row>
            </Scope>
            {(paidLeaveBlock) &&
                <StyledAlert type={'warning'} data-testid='paidLeaveAlert'>
                <strong>{t(AboutYouText.paidLeaveBlockText)(claimantData?.selectedEmpStateID)}</strong>
              </StyledAlert>}
            <StepperButtons suppressNext analyticsPageName={pageTitleLabel}/>
          </>)}
      </Form>
    </div>);
};
export default AboutYou;
