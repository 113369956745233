import React, { useRef, useState } from 'react';
import ExitModal from 'src/components/exitModal/ExitModal';
import NavigationModal from 'src/utils/NavigationModal';
import { useDisclosure } from '@mantine/hooks';
const ROUTE_CHECK_DEFAULT = {
    setLciStarted: (started) => null,
    setLciCompleted: (completed) => null,
    setBdmStarted: (started) => null,
    setBdmCompleted: (completed) => null,
    navigationCheck: () => () => null,
};
export const RouteCheckContext = React.createContext(ROUTE_CHECK_DEFAULT);
/**
 * Provider to intercept certain routes and display modals to the user giving
 * them an option before they continue on to the final destination
 */
const RouteCheckProvider = ({ children }) => {
    const [lciStarted, setLciStarted] = useState(false);
    const [lciCompleted, setLciCompleted] = useState(false);
    const [bdmStarted, setBdmStarted] = useState(false);
    const [bdmCompleted, setBdmCompleted] = useState(false);
    const [isOpen, { open, close }] = useDisclosure(false);
    /*** NAVIGATION MODAL - FOR lci and bdm to prevent lost form data  */
    const setIsNavigationModalOpenRef = useRef(() => { });
    const setNavigationModalControl = (setIsOpen) => {
        setIsNavigationModalOpenRef.current = setIsOpen;
    };
    const [navigationCallback, setNavigationCallback] = useState(() => { });
    /**
     * Given a callback function that dictates what this link wants to do
     * Will check for any reason to block and show a modal
     * Sets appropriate callback so the modals will route to the desired destination
     */
    const navigationCheck = (callback, id) => () => {
        const path = window.location.pathname;
        let shouldBlockNav = false;
        let showExitModal = false;
        let showNavigationModal = false;
        // console.log('IN ROUTE CHECK');
        /* Check path or id to determine if modals need to stop navigation */
        if (path.includes('lci')) {
            if (lciStarted && !lciCompleted) {
                shouldBlockNav = true;
                showNavigationModal = true;
            }
        }
        else if (path.includes('bdm')) {
            if (bdmStarted && !bdmCompleted) {
                shouldBlockNav = true;
                showNavigationModal = true;
            }
        }
        else if (id && (id === 'VIS' || id === 'DHMO')) {
            shouldBlockNav = true;
            showExitModal = true;
        }
        /* render the modal and set appropriate callback */
        if (shouldBlockNav) {
            setNavigationCallback(() => callback);
            if (showNavigationModal) {
                setIsNavigationModalOpenRef.current(true);
            }
            else if (showExitModal) {
                open();
            }
        }
        else {
            callback();
        }
    };
    const context = {
        setLciStarted,
        setLciCompleted,
        setBdmStarted,
        setBdmCompleted,
        navigationCheck,
    };
    return (<RouteCheckContext.Provider value={context}>
      {children}
      <NavigationModal setControl={setNavigationModalControl} navigationCallback={navigationCallback} close={close} opened={isOpen}/>
      <ExitModal close={close} opened={isOpen} navigationCallback={navigationCallback}/>
    </RouteCheckContext.Provider>);
};
export default RouteCheckProvider;
