import React, { memo, useContext, useEffect } from 'react';
import { NewModal as Modal } from '@digitools/honest-react';
import useTimeout from '../../hooks/useTimeout';
import { AuthenticationContext } from '../../providers/AuthenticationProvider';
import useAnyAction from '../../hooks/useAnyAction';
import useExperience from '../../hooks/useExperience';
import TimeoutModalDisplayTextEn from './i18n/TimeoutModalDisplayTextEn';
import TimeoutModalDisplayTextEs from './i18n/TimeoutModalDisplayTextEs';
import getDisplayText from '../../utils/languageDisplay';
import { useDisclosure } from '@mantine/hooks';
const TimeoutModal = ({ ms = 1200000, sRemainingForModal = 300 }) => {
    const { isAuthenticated, logout } = useContext(AuthenticationContext);
    const [ready, sRemaining, reset] = useTimeout(ms, isAuthenticated);
    const { language } = useExperience();
    const [isOpen, { open, close }] = useDisclosure(false);
    const showingModal = sRemaining <= sRemainingForModal;
    const getLocalDisplayText = (key) => getDisplayText.apply({}, [key, TimeoutModalDisplayTextEn, TimeoutModalDisplayTextEs, language]);
    // Don't reset timer if modal is already showing
    useAnyAction(!showingModal && isAuthenticated ? reset : undefined);
    useEffect(() => {
        if (showingModal) {
            open();
        }
    }, [showingModal]);
    if (ready && sRemaining < 1) {
        close();
        logout();
    }
    const closeModal = () => {
        reset();
        close();
    };
    return (<>
      {showingModal && (<Modal withCloseButton={false} cancelButton={false} centered={true} size={'500px'} title={getLocalDisplayText('title')} onClose={() => {
                closeModal();
            }} onSubmit={() => {
                closeModal();
            }} opened={isOpen} submitButtonText={`${getLocalDisplayText('continue')}`} submitButtonProps={{
                'data-testid': 'timeout-continue-button',
                'aria-label': 'timeout modal continue'
            }}>
          <div data-testid={'timeout-modal-content'} className={'mb-4'}>
            <p>{getLocalDisplayText('timeout')(sRemaining)}</p>
            <p>{getLocalDisplayText('clickHere')}</p>
          </div>
        </Modal>)}
    </>);
};
export default memo(TimeoutModal);
