import React, { useState } from 'react';
import { Alert, Button, Col, Link, Heading, Row } from '@digitools/honest-react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { encode } from 'status/utilities/hash';
import useDentalDataContext from './hooks/useDentalContext';
import MemberInformationBox from 'src/packages/dental/components/MemberInformationBox';
import { Spinner } from '@digitools/honest-react';
import TabContent from './components/TabContent';
import useMediaQuery from 'src/hooks/useMediaQuery';
import MemberDropdown from 'src/packages/dental/components/MemberDropdown';
import useSetCurrentMember from 'src/packages/dental/hooks/useSetCurrentMember';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import { CLAIMS, COVERED_SERVICES, DEDUCTIBLES_AND_COINSURANCE, tealiumFormat, } from 'src/packages/dental/providers/DentalDataProvider';
import { GetPdf } from 'src/packages/dental/api/DentalApiCalls';
import useAuthentication from 'src/hooks/useAuthentication';
import DentalButton from 'src/packages/dental/components/DentalButton';
import useExperience from 'src/hooks/useExperience';
import dentalText from 'src/packages/dental/constants/DentalText';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import AdditionalResources from './components/AdditionalResources';
const PathNavLink = (props) => {
    return <NavLink {...props}/>;
};
export const StyledNavLink = styled(PathNavLink) `
  color: ${({ theme }) => `${theme.palette.black}`};
  font-weight: 400;
  border-top: ${({ theme }) => `1px solid ${theme.palette.grayLighter}`};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding: 1rem 0 1rem 1rem;

  &:last-child {
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.grayLighter}`};
  }

  &:before {
    content: '';
    height: 50%;
    width: 3px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:focus {
    color: ${({ theme }) => `${theme.palette.primary}`};
    font-weight: 500;

    &:before {
      background: ${({ theme }) => `${theme.palette.primary}`};
    }
  }
`;
export const ErrorHtml = ({ errorAttribute, generalErrorMessage, notFoundErrorMessage }) => {
    const { error } = useDentalDataContext();
    const { t } = useTranslate();
    return error.subscriber === 'NOT_FOUND' || error[errorAttribute || 'subscriber'] === 'NOT_FOUND' ? (<Alert type={'warning'}>{notFoundErrorMessage || t(DentalText.deductibleAndCoinsuranceNotFoundWarning)}</Alert>) : (<Alert type={'error'}>{generalErrorMessage || t(DentalText.generalError)}</Alert>);
};
export const DisclaimerText = () => {
    const { subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    return (<div data-testid={'disclaimer'} style={{ color: 'gray', fontSize: '.5rem' }}>
      <p className={'small'}>
        <p>
          {subscriber?.planOverview?.planFundingClause}
          {t(dentalText.disclaimerTextPlanFundingAddition)}
        </p>
        <p>{t(DentalText.hipaaDisclaimer)}</p>
        <p>{t(DentalText.obtainDisclaimerText)}</p>
        <ul style={{ marginTop: 0 }}>
          <li style={{ listStylePosition: 'outside', marginLeft: '2rem' }}>
            <span>{t(DentalText.authorizationFormDisclaimer)}</span>
          </li>
          <li style={{
            listStylePosition: 'outside',
            marginLeft: '2rem',
            marginTop: 0,
        }}>
            {t(DentalText.dentalInfoReleaseDisclaimerA)}
            <Link style={{ fontSize: '.8rem' }} target={'_blank'} href={'https://www.lincoln4benefits.com/adminWebContent/Production/Ardis_Forms/grup/BJ-03566.pdf'}>
              {t(DentalText.dentalInfoReleaseFormLinkText)}
            </Link>
            {t(DentalText.dentalInfoReleaseDisclaimerB)}
          </li>
        </ul>
        <p>
          {t(DentalText.continuedParticipationDisclaimerA)}
          <Link style={{ fontSize: '.8rem' }}>{t(DentalText.dentalIdCardLink)}</Link>
          {t(DentalText.continuedParticipationDisclaimerB)}
        </p>
      </p>
    </div>);
};
const Dental = () => {
    const { subscriber, loading, activeTab, tealiumFunction, setActiveTab, currentMember, setInfoLoading, error } = useDentalDataContext();
    const { t } = useTranslate();
    const { language } = useExperience();
    const [pdfError, setPdfError] = useState('');
    const isMobile = useMediaQuery('(max-width: 1439px)');
    const isTitleView = useMediaQuery('(max-width: 738px)');
    useSetCurrentMember();
    const { authHeader } = useAuthentication();
    if (loading) {
        return <Spinner data-testid='dental-spinner'/>;
    }
    const processPdf = () => {
        setInfoLoading(true);
        GetPdf(language, authHeader(), currentMember?.memberType, currentMember?.id)
            .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            let url;
            url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Member_Dental_Information.pdf`);
            document.body.appendChild(link);
            link.click();
            // @ts-ignore
            link.parentNode.removeChild(link);
        })
            .catch(error => {
            setPdfError(t(DentalText.pdfError));
        })
            .finally(() => {
            setInfoLoading(false);
        });
    };
    const idCardTitle = isTitleView ? t(DentalText.IdCardTitleSmallest) : t(DentalText.IdCardTitle);
    const findADentistTitle = t(DentalText.findADentistTitle);
    const downloadBenefitsTitle = isTitleView
        ? t(DentalText.downloadBenefitsTitleSmallest)
        : t(DentalText.downloadBenefitsTitle);
    const MemberInformationButtons = ({ isInlineImageAndTitle, isTitleView, isMobileView, isLeftCentered, }) => {
        return (<>
        <DentalButton isInlineImageAndTitle={isInlineImageAndTitle} isInline={!isMobileView} event={tealiumFormat(idCardTitle)} eventAction={TEALIUM_EVENT.EVENT_ACTION.LINK} text={isTitleView ? undefined : t(DentalText.IdCardText)} hasOutArrow={!isTitleView} title={idCardTitle} imageName='id-card' isLeftCentered={isLeftCentered}/>
        <DentalButton isInlineImageAndTitle={isInlineImageAndTitle} isInline={!isMobileView} eventAction={TEALIUM_EVENT.EVENT_ACTION.LINK} href={'https://lfg.go2dental.com/member/dental_search/searchprov.cgi?P=LFGDentalConnect'} event={tealiumFormat(findADentistTitle)} text={isTitleView ? undefined : t(DentalText.findADentistText)} hasOutArrow={!isTitleView} title={findADentistTitle} imageName='find-a-dentist' isLeftCentered={isLeftCentered}/>
        <DentalButton isInlineImageAndTitle={isInlineImageAndTitle} isInline={!isMobileView} event={tealiumFormat(downloadBenefitsTitle)} eventAction={TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD} action={() => {
                setPdfError('');
                processPdf();
            }} text={isTitleView ? undefined : t(DentalText.downloadBenefitsText)} title={downloadBenefitsTitle} imageName='download' isLeftCentered={isLeftCentered}/>
      </>);
    };
    return (<>
      {(!isTitleView || !activeTab) && subscriber && (<>
          <Row>
            <Col>
              <Heading elemStyle={'h1'}>{t(DentalText.dentalTitle)}</Heading>
            </Col>
          </Row>
          {isTitleView && (<Row>
              <MemberInformationButtons isTitleView={true}/>
            </Row>)}
          {isTitleView && pdfError && (<>
              <Row>
                <Alert type={'error'}>{t(DentalText.pdfError)}</Alert>
              </Row>
            </>)}
        </>)}
      {subscriber ? (<>
          {isMobile ? (<>
              {isTitleView && activeTab && activeTab === COVERED_SERVICES && (<Row>
                  <Col>
                    <Heading elemStyle={'h1'}>{t(DentalText.coveredServicesTitle)}</Heading>
                  </Col>
                </Row>)}
              {isTitleView && activeTab && activeTab === DEDUCTIBLES_AND_COINSURANCE && (<Row>
                  <Col>
                    <Heading elemStyle={'h1'}>{t(DentalText.deductiblesTitle)}</Heading>
                  </Col>
                </Row>)}
              {isTitleView && activeTab && activeTab === CLAIMS && (<Row>
                  <Col>
                    <Heading elemStyle={'h1'}>{t(DentalText.claimsTitle)}</Heading>
                  </Col>
                </Row>)}
              {isTitleView && activeTab && (<Button style={{ padding: '0' }} data-testid={'backButton'} onClick={() => {
                        setActiveTab('');
                    }}>
                  &lt; {t(DentalText.backText)}
                </Button>)}
              {(activeTab === '' || !isTitleView) && (<Row>
                  <Col>
                    <p>{subscriber.planOverview?.planFundingClause}</p>
                  </Col>
                </Row>)}
              <Row>
                <Col className={'d-flex flex-column'} md={isTitleView ? 12 : 6}>
                  <MemberDropdown />
                  <MemberInformationBox />
                </Col>
                {!isTitleView && (<Col md={6}>
                    <MemberInformationButtons isInlineImageAndTitle={true} isMobileView={true}/>
                  </Col>)}
              </Row>
              {!isTitleView && pdfError && (<>
                  <Row>
                    <Col>
                      <Alert type={'error'}>{t(DentalText.pdfError)}</Alert>
                    </Col>
                  </Row>
                </>)}
              <TabContent />
              {(activeTab === '' || (activeTab === COVERED_SERVICES && !isTitleView)) && (<>
                  <AdditionalResources />
                  <Row>
                    <Col style={{ color: 'gray', fontSize: '.5rem' }}>
                      <DisclaimerText />
                    </Col>
                  </Row>
                </>)}
            </>) : (<Col>
              <Row style={{ padding: '.3rem .3rem .3rem .5rem' }}>{subscriber.planOverview?.planFundingClause}</Row>
              <Row>
                <Col xl={2} lg={3}>
                  <nav style={{ display: 'flex', flexDirection: 'column' }}>
                    {[...[subscriber], ...(subscriber.dependents || [])].map((member, index) => (<StyledNavLink data-testid={`navlink-${member.id}`} key={encode(member?.id)} data-public={true} to={{
                        pathname: `/employee/dental/members/${encode(member?.id)}`,
                    }} onClick={() => {
                        tealiumFunction({
                            eventAction: TEALIUM_EVENT.EVENT_ACTION.TAB,
                            memberEvent: index === 0 ? 'subscriber' : 'member' + (index - 1),
                        });
                    }}>
                        {`${member?.firstName} ${member?.lastName}`}
                      </StyledNavLink>))}
                  </nav>
                </Col>
                <Col xl={10} lg={9}>
                  <Row className={'d-flex'}>
                    <Col md={6} className={'d-flex flex-column'}>
                      <MemberInformationBox />
                    </Col>
                    <Col md={6} className={'d-flex'}>
                      <Row>{!isTitleView && <MemberInformationButtons isLeftCentered={true}/>}</Row>
                    </Col>
                  </Row>
                  {pdfError && (<>
                      <Row>
                        <Col>
                          <Alert type={'error'}>{t(DentalText.pdfError)}</Alert>
                        </Col>
                      </Row>
                    </>)}
                  <TabContent />
                  {(activeTab === COVERED_SERVICES || activeTab === '') && (<>
                      <AdditionalResources />
                      <Row>
                        <Col style={{ color: 'gray' }}>
                          <DisclaimerText />
                        </Col>
                      </Row>
                    </>)}
                </Col>
              </Row>
            </Col>)}
        </>) : (<>{(!subscriber || error?.subscriber) && !loading && <ErrorHtml />}</>)}
    </>);
};
export default Dental;
