import React from 'react';
import { NewModal as Modal, CompositeTable as Table, Button, themes } from '@digitools/honest-react';
import SubsLocsColumnModal from '../../../../Constants/SubsLocsColumns';
import { useDisclosure } from '@mantine/hooks';
const SubsAndLocsViewModal = ({ subsAndLocs }) => {
    const RenderOpenButton = ({ showModal }) => {
        const handleOnClick = () => {
            showModal();
        };
        return (<Button buttonType={'text'} type={'button'} aria-label='View subsidiaries/locations modal' onClick={handleOnClick} data-testid={'openModal'}>
        View subsidiaries/locations
      </Button>);
    };
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <Modal style={{ margin: '2rem', overflowX: 'scroll' }} title={'View subsidiaries/locations'} centered={true} opened={isOpen} onClose={() => {
            close();
        }} cancelButtonText={'Close'} cancelButtonProps={{
            'data-testid': 'closeSubsAndLocs',
            buttonType: 'primary',
            'aria-label': 'close view subsidiaries/locations modal'
        }} submitButton={false} theme={themes.lincoln} closeOnClickOutside={true}>
        <Table data={subsAndLocs} defaultPageSize={10} columns={SubsLocsColumnModal} paginated={true} theme={themes.lincoln} filterable={true} isSelectable={false} rowIdentifier={'locationId'} data-testid={'subLocTable'}/>
      </Modal>
      <RenderOpenButton showModal={open}/> 
    </>);
};
export default SubsAndLocsViewModal;
