import { Alert, Icon, Link, NewModal as Modal, Spinner } from '@digitools/honest-react';
import React, { useState } from 'react';
import { postCopyPermisssions } from '../../../api/UiServiceApi';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useAuthentication from '../../../hooks/useAuthentication';
import useTealium from '../../../hooks/useTealium';
import UserSearch from './UserSearch';
import { useDisclosure } from '@mantine/hooks';
const CopyUserModal = ({ allUsers, selectedUser, showAllUsers, setCopyPermissionsState }) => {
    const { trackEvent } = useTealium();
    const [isOpen, { open, close }] = useDisclosure(false);
    const { authHeader } = useAuthentication();
    const [isLoading, setIsLoading] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const RenderOpenButton = ({ showModal }) => {
        const handleOnClick = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.MODAL,
                event_type: TEALIUM_EVENT.EVENT_TYPE.COPY_PERMISSIONS_MODAL,
                event_name: 'copy permissions',
                event_status: TEALIUM_EVENT.EVENT_STATUS.OPEN,
            });
            showModal();
        };
        return (<Link id={'copy-user-openmodal'} data-testid='openModal' aria-label='Copy Employer Permissions' onClick={handleOnClick}>
        <b>
          <Icon name={'clone'} color={'accent1Light'} id='icon-copy-user' size={'xs'}/> Copy permissions
        </b>
      </Link>);
    };
    const handleFormSubmit = async (values) => {
        if (values.userId) {
            const childUser = values.userId;
            setIsLoading(true);
            await postCopyPermisssions(selectedUser.userId, childUser.userId, authHeader()).then((res) => {
                setIsLoading(false);
                setDisplayError(false);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.COPY_PERMISSIONS_MODAL,
                    event_name: 'SAVE ',
                    event_status: TEALIUM_EVENT.EVENT_STATUS.CONFIRM,
                });
                setCopyPermissionsState(true, selectedUser.firstName, selectedUser.lastName, childUser);
            }).catch(() => {
                setIsLoading(false);
                setDisplayError(true);
            });
        }
    };
    return (<>
      <Modal isForm={true} title={'Copy user permissions'} submitButtonText={'SAVE'} size={'690px'} cancelButtonText={'CANCEL'} onClose={() => {
            close();
            setDisplayError(false);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.COPY_PERMISSIONS_MODAL,
                event_name: 'CANCEL',
                event_status: TEALIUM_EVENT.EVENT_STATUS.CANCEL,
            });
        }} opened={isOpen} submitButtonProps={{
            'data-testid': 'saveSubLocs',
            'aria-label': 'Ok copy user permissions'
        }} cancelButtonProps={{
            'id': 'copy-user-closemodal',
            'data-testid': 'hideModal',
            'aria-label': 'Cancel copy user permissions'
        }} onSubmit={handleFormSubmit}>
        {isLoading ?
            <Spinner /> : <>
            {selectedUser && <>
              <p data-testid={'modal-content'}>Both permissions and folder settings will be saved from <b>{selectedUser.firstName} {selectedUser.lastName} </b> to the selected user below.</p>
            </>}
            <hr />
            {displayError && <Alert type={'error'}>
              <strong>Error!</strong>&nbsp;Permissions and folders from <b>{selectedUser.firstName} {selectedUser.lastName} </b>could not be saved.
            </Alert>}
            <p style={{ marginBottom: '4px' }}><b>Apply to: </b></p>
            {"\n"}
            <UserSearch selectedUser={selectedUser} users={allUsers} showAllIndicator={true} showAllUsers={showAllUsers} showCopyPermissionsTable={true}/> </>}
      </Modal>
      <RenderOpenButton showModal={open}/>
    </>);
};
export default CopyUserModal;
