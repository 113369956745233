import { Button, Icon, NewModal as Modal } from '@digitools/honest-react';
import { format } from 'date-fns';
import moment from 'moment';
import React, { useState } from 'react';
import { SubmitUnplannedAbsence } from '../api/UnplannedAbsenceApi';
import { useDisclosure } from '@mantine/hooks';
const DeleteAbsenceButton = ({ absence, getLeaves, setSuccessMessage, setFailureMessage, }) => {
    const [disableButton, setDisableButton] = useState(false);
    const onSuccess = () => {
        setDisableButton(false);
        getLeaves();
        setSuccessMessage('You have successfully deleted an Unplanned Absence.');
    };
    const onFailure = () => {
        setDisableButton(false);
        getLeaves();
        setFailureMessage('There was a problem deleting an Unplanned Absence. Please try again later.');
    };
    const { post: postAbsence } = SubmitUnplannedAbsence(onSuccess, onFailure);
    const deleteAbsence = () => {
        setDisableButton(true);
        postAbsence({
            payload: {
                absenceDate: format(absence.uaStartDate, 'MM/dd/yyyy'),
                absReason: absence.absReason,
                absType: absence.absType,
                additionalDates: absence.additionalDates ? absence.additionalDates.map(date => '111/11/1') : [],
                associatedLeaveNumber: absence.associatedLeave?.trim(),
                associatedTransNum: absence.transNum,
                deathFollowsPolicy: absence.uaHasDeath,
                duration: absence.uaDuration,
                endTime: moment.utc(absence.uaEndTime).format('HH:mm'),
                hasAssociatedLeave: absence.uaHasLeave,
                spansMidnight: absence.uaSpansMidnight,
                startTime: moment.utc(absence.uaStartTime).format('HH:mm'),
                transType: 'D',
            },
        });
    };
    const TrashButton = ({ showModal }) => (<Button onClick={showModal} buttonType='text' className='p-2' disabled={disableButton} title='Delete Absence' data-testid='delete-btn'>
      <Icon name='trash' color={'secondary'}/>
    </Button>);
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <Modal closeOnClickOutside={true} centered={true} opened={isOpen} onClose={() => {
            close();
        }} cancelButtonText={'Cancel'} submitButtonText={'Delete Absence'} onSubmit={() => {
            deleteAbsence();
            close();
        }} cancelButtonProps={{
            'data-testid': 'cancel-delete-btn',
            buttonType: 'secondary',
            'aria-label': 'cancel delete absence'
        }} submitButtonProps={{
            'data-testid': 'confirm-delete-btn',
            buttonType: 'primary',
            'aria-label': 'confirm delete absence'
        }} withCloseButton={false} title='Please Confirm'>
        <p data-testid='delete-modal'>Are you sure that you want to delete this absence?</p>
      </Modal>
      <TrashButton showModal={open}/>
    </>);
};
export default DeleteAbsenceButton;
