import { Box, Col, Collapse, Container, Heading, Icon, Row, themes } from '@digitools/honest-react';
import prevServiceIcon from '../../../assets/toothpaste.svg';
import drillIcon from '../../../assets/drill.svg';
import instrumentsIcon from '../../../assets/instruments.svg';
import toothIcon from '../../../assets/tooth.svg';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import { tealiumFormat, } from 'src/packages/dental/providers/DentalDataProvider';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import DentalText from 'src/packages/dental/constants/DentalText';
import { ErrorHtml } from 'src/packages/dental/Dental';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
const BenefitFrequencyTables = () => {
    const CollapsedIcon = () => (<Icon name='add' size='xs' className='align-content-center p-3' color='primary'/>);
    const ExpandedIcon = () => (<Icon name='subtract' size='xs' className='align-content-center p-3' color='primary'/>);
    const BenefitFreqTable = ({ benefits, title, image, waitingPeriod, hasWaitingPeriodError, }) => {
        const { t } = useTranslate();
        // The waiting period table ordered (waitingPeriodOrder) only displays if "allMet" is null or undefined
        let waitingPeriodOrder = [];
        const addKeyPairWithLabels = keyPair => {
            if (keyPair && keyPair.label != null && keyPair.label !== '') {
                waitingPeriodOrder?.push(keyPair);
            }
        };
        if (waitingPeriod?.allMet == null) {
            [
                waitingPeriod?.waitingPeriod,
                waitingPeriod?.waitingPeriodMet,
                waitingPeriod?.entrantLimit,
                waitingPeriod?.entrantLimitMet,
            ].forEach(val => addKeyPairWithLabels(val));
        }
        const showWaitingPeriod = hasWaitingPeriodError || waitingPeriodOrder.length || waitingPeriod?.allMet;
        return (<Box>
        <Collapse data-testid={'outer-collapse-benefits' + title} titleComponent={() => (<div onClick={(event) => {
                    if (event.currentTarget.parentElement?.parentElement?.ariaExpanded == 'false') {
                        trackDropdownEvent(title || '');
                    }
                    event.preventDefault();
                }} className={'text-center align-content-center h-100'}>
              <Heading className={'p-0 m-0'} elemStyle={'h2'} color={'secondaryDark'}>
                {title}
              </Heading>
            </div>)} headerElemType={'h2'} isOpen={true} expandedIcon={ExpandedIcon} collapsedIcon={CollapsedIcon} theme={themes.lincoln} type='alternate' color={'white'} headerLeftDisplay={image}>
          {showWaitingPeriod && (<Container className={'pl-0 pr-0'}>
              <Row>
                {hasWaitingPeriodError && (<Col className={'mb-2'}>
                    <ErrorHtml errorAttribute={'waitingPeriods'} generalErrorMessage={`${t(DentalText.waitingPeriodGeneralError)}`} notFoundErrorMessage={t(DentalText.waitingPeriodNotFoundError)}/>
                  </Col>)}
                {waitingPeriod?.allMet && (<Col className={'mb-4 mt-2'}>
                    <Icon className={'ml-3 align-middle'} size='medium' name='info' color={'secondary'} title={'deductibles info'}/>
                    <div className={'d-inline ml-2'}>{waitingPeriod.allMet}</div>
                  </Col>)}
                {!hasWaitingPeriodError &&
                    !!waitingPeriodOrder.length &&
                    waitingPeriodOrder.map(benefit => (<>
                      {benefit && (<Col md={6} xs={12} className={''}>
                          <Row>
                            <Col className={'m-2 bg-light w-100 pt-2 pb-2'}>
                              <Heading elemStyle={'h4'}>{benefit?.label}</Heading>
                            </Col>
                          </Row>
                          <Row className={'mt-1 mb-1 ml-0 mr-0 pb-3'}>
                            <Col className={'w-100 border-danger'} style={{ border: 'solid', borderWidth: '0 0 2px 0' }}>
                              {benefit?.value}
                            </Col>
                          </Row>
                        </Col>)}
                    </>))}
              </Row>
            </Container>)}
          {benefits &&
                benefits.map((item) => (<Collapse title={item.serviceName} type={'alternate'} data-testid={'inner-collapse-benefits'} style={{ paddingBottom: '1rem' }}>
                {item.details && item.details.length !== 0 && (<Box style={{ border: 0, paddingTop: '1rem', marginBottom: '.5rem', marginTop: 0 }} color={'grayLightest'} data-testid={'details-box'}>
                    <Col>
                      <Row>
                        <Heading elemType={'h4'}>{t(DentalText.details)}</Heading>
                      </Row>
                      {item.details.map((detail) => (<Row className={'font-weight-light'}>{detail}</Row>))}
                    </Col>
                  </Box>)}
                <Box style={{ border: 0, paddingTop: 0 }}>
                  {item.frequency != null && item.frequency != '' && (<Row className={'border-bottom pb-2'}>
                      <Col>
                        <Heading elemType={'h4'}>{t(DentalText.frequency)}</Heading>
                      </Col>
                      <Col>
                        <p className={'text-right font-weight-light w-100'}>{item.frequency}</p>
                      </Col>
                    </Row>)}
                  {item.ageLimit != null && item.ageLimit != '' && (<Row className={'border-bottom pb-2'}>
                      <Col>
                        <Heading elemType={'h4'}>{t(DentalText.ageLimit)}</Heading>
                      </Col>
                      <Col>
                        <p className={'text-right font-weight-light w-100'}>{item.ageLimit}</p>
                      </Col>
                    </Row>)}
                  {item.additionalInfo && (<Row className={'border-bottom font-weight-bold pb-2'}>
                      <Col classname={'text-left'}>
                        <Heading elemType={'h4'}>{t(DentalText.additionalInfo)}</Heading>
                        <Row className={'font-weight-light'} style={{ paddingLeft: '.5rem' }}>
                          {item.additionalInfo}
                        </Row>
                      </Col>
                    </Row>)}
                </Box>
              </Collapse>))}
        </Collapse>
      </Box>);
    };
    const { currentMember, error, tealiumFunction } = useDentalDataContext();
    const { t } = useTranslate();
    const benefitsAndFrequencies = currentMember?.benefitsAndFrequencies;
    const preventiveServicesBenefits = benefitsAndFrequencies?.preventiveServices;
    const basicServicesBenefits = benefitsAndFrequencies?.basicServices;
    const majorServicesBenefits = benefitsAndFrequencies?.majorServices;
    const orthodontiaBenefits = benefitsAndFrequencies?.orthodontia;
    const waitingPeriods = currentMember?.waitingPeriods || [];
    const trackDropdownEvent = (eventName) => tealiumFunction({
        hasEventView: false,
        eventAction: TEALIUM_EVENT.EVENT_ACTION.EXPAND,
        eventName: tealiumFormat(`${eventName}`),
        eventType: 'covered services',
    });
    const getWaitingPeriod = (serviceCategory) => {
        return ((waitingPeriods.length &&
            waitingPeriods.find(waitingPeriod => waitingPeriod.serviceCategory?.valueOf() === serviceCategory)) ||
            undefined);
    };
    if (error.benefits) {
        return (<ErrorHtml errorAttribute={'benefits'} generalErrorMessage={t(DentalText.benefitsAndFrequencyGeneralError)} notFoundErrorMessage={t(DentalText.benefitsAndFrequencyNotFoundError)}/>);
    }
    return (<>
      {!!preventiveServicesBenefits && preventiveServicesBenefits.length !== 0 && (<BenefitFreqTable benefits={preventiveServicesBenefits} waitingPeriod={getWaitingPeriod('TYPE_1')} hasWaitingPeriodError={!waitingPeriods.length} title={t(DentalText.preventiveServices)} image={prevServiceIcon}></BenefitFreqTable>)}
      {!!basicServicesBenefits && basicServicesBenefits.length !== 0 && (<BenefitFreqTable benefits={basicServicesBenefits} waitingPeriod={getWaitingPeriod('TYPE_2')} hasWaitingPeriodError={!waitingPeriods.length} title={t(DentalText.basicServices)} image={instrumentsIcon}></BenefitFreqTable>)}
      {!!majorServicesBenefits && majorServicesBenefits.length !== 0 && (<BenefitFreqTable benefits={majorServicesBenefits} waitingPeriod={getWaitingPeriod('TYPE_3')} hasWaitingPeriodError={!waitingPeriods.length} title={t(DentalText.majorServices)} image={drillIcon}></BenefitFreqTable>)}
      {!!orthodontiaBenefits && orthodontiaBenefits.length !== 0 && (<BenefitFreqTable benefits={orthodontiaBenefits} waitingPeriod={getWaitingPeriod('TYPE_4')} hasWaitingPeriodError={!waitingPeriods.length} title={t(DentalText.orthodontia)} image={toothIcon}></BenefitFreqTable>)}
    </>);
};
export default BenefitFrequencyTables;
