import React from 'react';
import { NewModal as Modal } from '@digitools/honest-react';
import Link from '@digitools/honest-react/dist/lib/components/Link';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import getDisplayText from '../../../utils/languageDisplay';
import IMfaAuthyInstructionsDisplayTextEn from '../i18n/IMfaAuthyInstructionsDisplayTextEn';
import IMfaAuthyInstructionsDisplayTextEs from '../i18n/IMfaAuthyInstructionsDisplayTextEs';
// @ts-ignore
import authyStep1 from 'src/assets/Authy-01-App.png';
// @ts-ignore
import authyStep2 from 'src/assets/Authy-02-Phone-Number.png';
// @ts-ignore
import authyStep4 from 'src/assets/Authy-04-Select-MLP.png';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import styled from 'styled-components';
import { useDisclosure } from '@mantine/hooks';
const StyledLink = styled(Link) `
  text-decoration: underline;
`;
const MfaAuthyInstructionsModal = (props) => {
    const { trackEvent } = useTealium();
    const OpenMfaAuthyInstructionsModalButton = ({ showModal }) => {
        const handleOnClick = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.OPEN_AUTHY_MODAL,
                event_name: 'Authy Instructions'
            });
            showModal();
        };
        return (<Button id='#authyInstructionsModal' aria-label={getDisplayText('link', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language)} onClick={handleOnClick} buttonType={'text'} className={'p-0'} data-testid='open-btn'>
        {getDisplayText('link', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language)}
      </Button>);
    };
    const step1Title = getDisplayText('step1Title', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step1TextA = getDisplayText('step1TextA', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step1TextB = getDisplayText('step1TextB', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step2Title = getDisplayText('step2Title', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step2Text = getDisplayText('step2Text', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step3Title = getDisplayText('step3Title', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step3Text = getDisplayText('step3Text', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step3Italic = getDisplayText('step3Italic', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step4Title = getDisplayText('step4Title', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step4Text = getDisplayText('step4Text', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step4Italic = getDisplayText('step4Italic', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <Modal 
    // containerMaxHeight={'90%'}
    withCloseButton={true} title={getDisplayText('heading', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language)} opened={isOpen} centered={true} closeOnClickOutside={true} submitButton={false} onClose={() => {
            close();
        }} size={'680px'} cancelButtonText={'Okay'} cancelButtonProps={{
            'data-testid': 'mfaAuthyInstructions-ok',
            buttonType: 'primary',
            'aria-label': 'What is Authy - okay, close'
        }}>
        <p>
          {getDisplayText('body', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language)}
        </p>
        <ol>
          <li>
            <strong>{step1Title} </strong>
            {step1TextA}
            <StyledLink href={'https://www.authy.com/install'} target={'_blank'} data-testid='authy-link'>
              https://www.authy.com/install
            </StyledLink>
            {step1TextB}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={authyStep1} style={{ width: '200px', margin: '1rem' }} alt='Authy app store logo.'/>
            </div>
          </li>
          <li>
            <strong>{step2Title} </strong>
            {step2Text}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={authyStep2} style={{ width: '300px', margin: '1rem' }} alt='Authy setup page, asking for cell phone number.'/>
            </div>
          </li>
          <li>
            <strong>{step3Title}</strong>
            {step3Text} <i>{step3Italic}</i>
          </li>
          <li>
            <strong>{step4Title}</strong>
            {step4Text} <i>{step4Italic}</i>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={authyStep4} style={{ width: '250px', margin: '1rem' }} alt='Example of Authy app displaying portal token.'/>
            </div>
          </li>
        </ol>
      </Modal>
      <OpenMfaAuthyInstructionsModalButton showModal={open}/>
    </>);
};
export default MfaAuthyInstructionsModal;
