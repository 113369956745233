import React from 'react';
// @ts-ignore
import modalStyle from './workLocations.scss';
import { Checkbox, Row, Col, Button, Heading, } from '@digitools/honest-react';
import _ from 'lodash';
const WorkLocations = (props) => {
    const { onStateSelect, onProvinceSelect, workStates, workProvinces, onClearAllState, onClearAllProvince, disableForm } = props;
    const clearAllStates = () => onClearAllState;
    const clearAllProvices = () => onClearAllProvince;
    const selectState = (state) => () => onStateSelect(state);
    const selectLocation = (location) => () => onProvinceSelect(location);
    const statesArray = _.chunk(workStates, 13);
    const territoryAndProvinceArray = _.chunk(workProvinces, 7);
    const stateCheckboxRows = statesArray.map((state, indexRow) => {
        return (<div key={'state' + indexRow}>
        {state.map((ws, index) => <Checkbox key={ws.stateCode} field={ws.stateCode} label={ws.stateName} onChange={selectState(ws)} initialValue={ws.checked} disabled={disableForm}/>)}
      </div>);
    });
    const territoryAndProvince = territoryAndProvinceArray.map((subProducts, indexRow) => {
        return (<div key={'province' + indexRow}>
        {subProducts.map((wl, index) => <Checkbox key={wl.provinceCode} style={{ display: 'block', width: '100%' }} field={wl.provinceCode} label={wl.provinceName} onChange={selectLocation(wl)} initialValue={wl.checked} disabled={disableForm}/>)}
      </div>);
    });
    return (<div tabIndex={0} style={{ maxHeight: '725px', overflowY: 'scroll' }}>
      <div className={modalStyle['section-padding']}>
        <Row>
          <Col>
            <Heading elemType={'h2'}> US states </Heading>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button buttonType={'text'} type={'button'} style={{ padding: '0px' }} onClick={clearAllStates()} disabled={disableForm}>Clear all</Button>
          </Col>
        </Row>
        <Row className={modalStyle['custom-row']}>
          {stateCheckboxRows}
        </Row>
      </div>
      <div className={modalStyle['section-padding']}>
        <Row>
          <Col>
            <Heading elemType={'h2'}> Territories/provinces </Heading>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button buttonType={'text'} type={'button'} style={{ padding: '0px' }} onClick={clearAllProvices()} disabled={disableForm}>Clear all</Button>
          </Col>
        </Row>
        <Row className={modalStyle['custom-row']}>
          {territoryAndProvince}
        </Row>
      </div>
    </div>);
};
export default WorkLocations;
