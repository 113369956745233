import React from 'react';
import { Col, Collapse, Container, Heading, Icon, Row, themes } from '@digitools/honest-react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useMediaQuery from 'src/hooks/useMediaQuery';
export const PlanOverview = () => {
    const { subscriber } = useDentalDataContext();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const data = subscriber?.planOverview;
    let groupName = '';
    const blankKeyPair = { value: '', label: '' };
    let multiColumnData = [
        { col1: blankKeyPair, col2: blankKeyPair },
        { col1: blankKeyPair, col2: blankKeyPair },
        { col1: blankKeyPair, col2: blankKeyPair },
    ];
    let planOverviewEntriesMobile = [];
    let hasData = false;
    let lastRowIndex = 2;
    if (data) {
        groupName = `${data.groupName?.label} ${data.groupName?.value}` || '';
        let col1Index = 0;
        let col2Index = 0;
        ['groupPolicyNum', 'coverageType', 'benefitPeriod'].forEach(key => {
            if (data[key] && data[key].value != null && data[key].label) {
                planOverviewEntriesMobile.push(data[key]);
                multiColumnData[col1Index]['col1'] = data[key];
                col1Index += 1;
            }
        });
        ['terminationDate', 'dependentAgeLimit', 'studentAgeLimit'].forEach(key => {
            if (data[key] && data[key].value != null && data[key].label) {
                planOverviewEntriesMobile.push(data[key]);
                multiColumnData[col2Index]['col2'] = data[key];
                col2Index += 1;
            }
        });
        lastRowIndex = Math.max(col1Index, col2Index) - 1;
        hasData = !!col1Index || !!col2Index;
    }
    const CollapsedIcon = () => <Icon name='add' size='xs' className='p-2 mr-2' color='primary'/>;
    const ExpandedIcon = () => <Icon name='subtract' size='xs' className='p-2 mr-2' color='primary'/>;
    const PlanOverviewColumn = ({ isLastRow, entry, labelWidth }) => {
        let labelCss = isLastRow ? 'pb-1' : 'mb-4 pb-1';
        const valueCss = isLastRow ? 'pb-0 pl-0' : 'mb-4 pb-0 pl-0';
        let paragraphCss = 'pb-1 mr-3 h-100';
        const hasEntryData = entry.label;
        if (hasEntryData) {
            labelCss += ' border-bottom';
            paragraphCss += ' border-bottom';
        }
        return (<>
        <Col md={labelWidth} className={labelCss}>
          <Heading elemType={'h4'}>{entry.label}</Heading>
        </Col>
        <Col md={6 - labelWidth} className={valueCss}>
          <div className={paragraphCss}>
            <p className={'text-right font-weight-light pb-1'}>{entry.value}</p>
          </div>
        </Col>
      </>);
    };
    return (<>
      <Collapse data-testid={'planOverviewCollapse'} title={groupName} collapsedIcon={CollapsedIcon} expandedIcon={ExpandedIcon} headerElemType='h2' theme={themes.lincoln} type='box' isOpen={true} color={'white'}>
        {hasData && isMobile && (<Container data-testid={'mobileDataContainer'}>
            {planOverviewEntriesMobile.map((entry, index) => (<Row className={'border-bottom pb-2'}>
                <Col>
                  <Heading elemType={'h4'} id={'planOverviewDataLabel' + index}>
                    {entry.label}
                  </Heading>
                </Col>
                <Col>
                  <p className={'text-right font-weight-light w-100'} id={'planOverviewDataValue' + index} data-testid={'planOverviewDataValue' + index}>
                    {entry.value}
                  </p>
                </Col>
              </Row>))}
          </Container>)}
        {hasData && !isMobile && (<Container className={'pr-0'} data-testid={'desktopDataContainer'}>
            <Row className={'flex-row'}>
              {multiColumnData &&
                multiColumnData.map((entry, index) => (<>
                    <PlanOverviewColumn entry={entry.col1} isLastRow={index === lastRowIndex} labelWidth={2}/>
                    <PlanOverviewColumn entry={entry.col2} isLastRow={index === lastRowIndex} labelWidth={4}/>
                  </>))}
            </Row>
          </Container>)}
      </Collapse>
    </>);
};
export default PlanOverview;
