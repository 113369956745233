import React from "react";
import { CliConfirmationTextClaim } from "./CliConfirmationTextClaim";
export const CliConfirmationTextCoord = {
    pageHeading: {
        english: <><strong>Thank you!</strong> You have successfully submitted a new claim. A coordinated leave has also been created on your behalf.</>,
        spanish: <><strong>Gracias!</strong> Ha enviado correctamente un nuevo reclamo. También se ha creado una licencia coordinada en su nombre.</>, // <- for claim only, 'una nueva ausencia' - this should conjugate different because of masculine/feminine nouns
    },
    subHeading: {
        english: CliConfirmationTextClaim.subHeading.english,
        spanish: CliConfirmationTextClaim.subHeading.spanish,
    },
    videoMessage: {
        english: <>For more information on next steps,&nbsp;
        <strong>
                <a id='video-message-link' href='#' onClick={() => window.open("https://players.brightcove.net/1134849171001/default_default/index.html?videoId=5811829006001", "_blank", "height=400px, width=600px")}>
                    watch our step by step video of the claim process.
            </a>
            </strong></>,
        spanish: <>Para obtener más información sobre los próximos pasos,&nbsp;
        <strong>
                <a id='video-message-link' href='#' onClick={() => window.open("https://players.brightcove.net/1134849171001/default_default/index.html?videoId=5811829006001", "_blank", "height=400px, width=600px")}>
                    vea nuestro video paso a paso del proceso de reclamación.
            </a>
            </strong></>
    },
    eventNumber: {
        english: CliConfirmationTextClaim.eventNumber.english,
        spanish: CliConfirmationTextClaim.eventNumber.spanish
    },
    ourNextStepsHeading: {
        english: CliConfirmationTextClaim.ourNextStepsHeading.english,
        spanish: CliConfirmationTextClaim.ourNextStepsHeading.spanish,
    },
    ourNextStepsBody: {
        english: CliConfirmationTextClaim.ourNextStepsBody.english,
        spanish: CliConfirmationTextClaim.ourNextStepsBody.spanish,
    },
    nextStepsHeading: {
        english: CliConfirmationTextClaim.nextStepsHeading.english,
        spanish: CliConfirmationTextClaim.nextStepsHeading.spanish,
    },
    nextSteps1Heading: {
        english: CliConfirmationTextClaim.nextSteps1Heading.english,
        spanish: CliConfirmationTextClaim.nextSteps1Heading.spanish,
    },
    nextSteps1Body: {
        english: CliConfirmationTextClaim.nextSteps1Body.english,
        spanish: CliConfirmationTextClaim.nextSteps1Body.spanish
    },
    nextSteps2Heading: {
        english: CliConfirmationTextClaim.nextSteps2Heading.english,
        spanish: CliConfirmationTextClaim.nextSteps2Heading.spanish,
    },
    nextSteps2Body: {
        english: CliConfirmationTextClaim.nextSteps2Body.english,
        spanish: CliConfirmationTextClaim.nextSteps2Body.spanish
    },
    nextSteps3Heading: {
        english: CliConfirmationTextClaim.nextSteps3Heading.english,
        spanish: CliConfirmationTextClaim.nextSteps3Heading.spanish
    },
    nextSteps3Body: {
        english: CliConfirmationTextClaim.nextSteps3Body.english,
        spanish: CliConfirmationTextClaim.nextSteps3Body.spanish
    },
    nextSteps4Heading: {
        english: CliConfirmationTextClaim.nextSteps4Heading.english,
        spanish: CliConfirmationTextClaim.nextSteps4Heading.spanish
    },
    nextSteps4Body: {
        english: CliConfirmationTextClaim.nextSteps4Body.english,
        spanish: CliConfirmationTextClaim.nextSteps4Body.spanish
    },
    nextSteps5Heading: {
        english: 'Stay updated about your claim and leave',
        spanish: 'Manténgase actualizado sobre su reclamo y váyase'
    },
    nextSteps5BodyEE: {
        english: <>
            You'll be able to view your claim and leave online within 48 hours, simply select the <a data-testid='view-status-link-5' href='/customer/status/employee' target='_blank'><strong>View Status</strong></a> button from the home page. Here you’ll also find your Claim Specialist contact information should you have any questions.<br />
            You can access these forms, check your claim status, manage your claim, and upload completed forms on our secure
            portal.</>,
        spanish: <>
            Podrá ver su reclamo y salir en línea dentro de las 48 horas, simplemente seleccione el botón <a data-testid='view-status-link-5' href='/customer/status/employee' target='_blank'><strong>Ver el Estado de</strong></a> en la página de inicio. Aquí también encontrará la información de contacto de su especialista en reclamos si tiene alguna pregunta.<br />
            Puede acceder a estos formularios, verificar el estado de su reclamo, administrar su reclamo y cargar
            formularios completos en nuestro portal seguro.</>
    },
    nextSteps5BodyER: {
        english: <>You'll be able to view your claim and leave online within 48 hours, simply select the <a data-testid='view-status-link-5' href='/customer/status' target='_blank'><strong>View Status</strong></a> button from the home page. Here you’ll also find your Claim Specialist contact information should you have any questions.</>,
        spanish: <>Podrá ver su reclamo y salir en línea dentro de las 48 horas, simplemente seleccione el botón <a data-testid='view-status-link-5' href='/customer/status' target='_blank'><strong>Ver el Estado de</strong></a> en la página de inicio. Aquí también encontrará la información de contacto de su especialista en reclamos si tiene alguna pregunta.</>
    },
    nextSteps5Link: {
        english: CliConfirmationTextClaim.nextSteps5Link.english,
        spanish: CliConfirmationTextClaim.nextSteps5Link.spanish
    },
    automatedMessageText: {
        english: CliConfirmationTextClaim.automatedMessageText.english,
        spanish: CliConfirmationTextClaim.automatedMessageText.spanish
    },
    questions: {
        english: 'My Lincoln Portal® is your one-stop shop for everything you need to manage your claim and leave. You can access the portal 24 hours a day, 7 days a week, from your desktop, tablet, or mobile device.',
        spanish: 'My Lincoln Portal® es su ventanilla única para todo lo que necesita para administrar su reclamo y salir. Puede acceder al portal las 24 horas del día, los 7 días de la semana, desde su computadora de escritorio, tableta o dispositivo móvil.'
    },
    videoUrl: 'https://players.brightcove.net/1134849171001/default_default/index.html?videoId=5811829006001',
    videoId: '5811829006001',
    videoHeading: {
        english: 'Have questions about your next steps in the claim process?',
        spanish: '¿Tiene preguntas sobre sus próximos pasos en el proceso de reclamo y baja?'
    },
    videoSubheading: {
        english: CliConfirmationTextClaim.videoSubheading.english,
        spanish: CliConfirmationTextClaim.videoSubheading.spanish
    }
};
