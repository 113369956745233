import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Heading, Icon, Label, Link, Loader, NewModal as Modal, Row } from '@digitools/honest-react';
import { Language } from '../../../../../types/Language';
import { DocumentUploadDisplayTextEs } from '../../../../../components/documentUpload/language/DocumentUploadDisplayTextEs';
import { DocumentUploadDisplayTextEn } from '../../../../../components/documentUpload/language/DocumentUploadDisplayTextEn';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getTraceId } from '../../../../../utils/axiosUtils';
import useExperience from '../../../../../hooks/useExperience';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
import './RtwDetails.scss';
import useAuthentication from 'src/hooks/useAuthentication';
import styles from './RtwDetails.scss';
import { useDisclosure } from '@mantine/hooks';
const StyledCol = styled(Col) `
  border: 0.5px solid lightgrey;
  padding: 1.5%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
`;
const StyledLink = styled(Link) `
  font-weight: bold;
`;
const StyledFileInput = styled('input') `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
const supportedFiles = ['gif', 'jpg', 'pdf', 'pptx', 'tif', 'tiff', 'xls', 'xlsx', 'doc', 'docx'];
const RtwDocumentUpload = (props) => {
    const { language, experience } = useExperience();
    const { trackEvent } = useTealium();
    const { authHeader } = useAuthentication();
    const { t } = useTranslation();
    const languageFile = language === Language.SPANISH ? DocumentUploadDisplayTextEs : DocumentUploadDisplayTextEn;
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    useEffect(() => {
        if (uploadedFiles?.length > 0) {
            props.setUploaded(uploadedFiles);
            props.setUpdateDoc(false);
        }
    }, [uploadedFiles]);
    useEffect(() => {
        if (props.updateDoc) {
            setUploadedFiles([]);
        }
    }, [props.updateDoc]);
    const RenderOpenButton = ({ showModal }) => {
        const handleOnClick = () => {
            showModal();
        };
        if (props.isSuccess)
            return <></>;
        return (<Button buttonType={props.displayLabel ? 'secondary' : 'text'} size={'medium'} type={'button'} onClick={handleOnClick} data-testid={'openModal'} key={'openModal' + props.eventNum} aria-label={languageFile.uploadLabel} style={{ padding: '0.5rem 0.5rem ', marginBottom: '3%' }}>
        {t('uploadRtwLabel')}
        <Icon name='upload' color={'secondary'} style={{ paddingLeft: '.25rem' }}/>
      </Button>);
    };
    const onUpload = (event) => {
        if (event.target.files.length >= 1) {
            const selectedFile = event.target.files[0];
            const fileName = selectedFile.name;
            if (fileName.match(/^[a-zA-Z0-9_.\s-]*$/)) {
                const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
                if (supportedFiles.indexOf(fileType.toLocaleLowerCase()) >= 0) {
                    if (selectedFile.size <= 6100000 && selectedFile.size !== 0) {
                        uploadDocument(selectedFile);
                        event.target.value = null;
                    }
                    else if (selectedFile.size === 0) {
                        setErrorMessage(languageFile.uploadFileEmpty);
                    }
                    else {
                        setErrorMessage(languageFile.uploadSizeExceeded);
                    }
                }
                else {
                    setErrorMessage(languageFile.uploadFileTypeInvalid);
                }
            }
            else {
                setErrorMessage(languageFile.uploadFilenameInvalid);
            }
        }
    };
    const getProduct = () => {
        if (props.productName === 'LEAVE') {
            return languageFile.leave;
        }
        else {
            return languageFile.claim;
        }
    };
    const uploadDocument = async (file) => {
        try {
            setUploading(true);
            const response = await axios.post(`/mlp-ui-service/api/documents/upload`, getFormData(file, props.productName.toUpperCase()), {
                headers: {
                    Authorization: authHeader(),
                },
            });
            if (response.status === 200) {
                setUploadedFiles(prev => [...prev, { documentName: file.name, documentId: response.data }]);
                setErrorMessage('');
                setUploading(false);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                    event_name: 'open',
                    event_version: 'return to work - details',
                    event_results: '1', // Allowing only one file at a time
                });
            }
            else {
                setUploading(false);
                setErrorMessage(`${languageFile.failureMessage}`);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                    event_name: languageFile.failureMessage,
                    event_version: 'upload RTW document',
                });
            }
        }
        catch (error) {
            setUploading(false);
            setErrorMessage(`${languageFile.failureMessage}${getTraceId(error)}`);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                event_name: `${languageFile.failureMessage}${getTraceId(error)}`,
                event_version: 'upload RTW document',
            });
        }
    };
    const getFormData = (file, interviewType) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('interviewType', interviewType);
        formData.append('experience', experience);
        return formData;
    };
    const renderColumns = () => {
        return (<>
        <Col md={6}>
          {uploadedFiles?.map((documentDetail, index) => {
                if (index <= 4)
                    return getSelectedDocuments(documentDetail);
            })}
        </Col>

        <Col md={6}>
          {uploadedFiles?.map((documentDetail, index) => {
                if (index > 4)
                    return getSelectedDocuments(documentDetail);
            })}
        </Col>
      </>);
    };
    const getSelectedDocuments = (documentDetail, onPage) => {
        const handleDelete = () => {
            const newDocumentList = uploadedFiles.filter(file => file.documentId !== documentDetail.documentId);
            setUploadedFiles(newDocumentList);
            props.setUploaded(newDocumentList);
        };
        return (<Row key={documentDetail.documentId} styles={{ padding: '0 7px' }}>
        <StyledCol md={props.isSuccess ? 12 : 8}>
          <Row data-testid={'submitted-doc'} aria-labelledby={documentDetail.documentName}>
            <Icon styles={{ margin: '2px .5rem 0 1rem' }} name='documents'/> {documentDetail.documentName}
          </Row>
        </StyledCol>
        {!props.isSuccess && (<StyledCol md={onPage ? 4 : 3}>
            <StyledLink data-testid={'delete-doc'} aria-label={languageFile.delete} onClick={handleDelete}>
              <Icon color='secondary' className={styles.deleteIcon} name='trash'/> {languageFile.delete}
            </StyledLink>
          </StyledCol>)}
      </Row>);
    };
    const clearStates = () => {
        setUploading(false);
        setErrorMessage('');
    };
    const renderOnPageColumns = () => {
        return (<Col md={12}>
        {' '}
        {uploadedFiles.map((documentDetail, index) => {
                return getSelectedDocuments(documentDetail, 'onpage');
            })}{' '}
      </Col>);
    };
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <Modal data-testid={'uploadModal'} style={{ margin: '2rem', overflowX: 'scroll' }} centered={true} opened={isOpen} title={<Heading color={'primaryDark'} elemStyle={'h1'} elemType={'h1'} style={{ lineHeight: 'normal' }}>
            {`${languageFile.headerText} ${getProduct()} #${props.eventNum}`}
          </Heading>} cancelButtonProps={{
            'data-testid': 'cancel',
            buttonType: 'secondary',
            type: 'button',
            'aria-label': 'close document upload'
        }} cancelButtonText={languageFile.closeText} onClose={() => {
            clearStates();
            close();
        }} submitButton={false} size={'1100px'} closeOnClickOutside={true}>
        <Loader id='document-upload-spinner' isShown={uploading}>
          <Container>
            {errorMessage !== '' && (<Alert type={'error'} data-testid={'error-alert'}>
                <div>
                  {/* REVERTING CHANGE FROM OGS-3128 */}
                  {/* {t('claimErrorMessage')}
            <a href='/customer/home/contacts'>{t('contactUs')}</a> */}
                  {errorMessage}
                </div>
              </Alert>)}
            <Row data-testid={'informationText'}>
              {`${languageFile.informationalMessage1} ${languageFile.informationalMessagePart}`}
            </Row>
            <Row data-testid={'acceptableDocumentsText'}>
              {languageFile.informationalMessage2}
            </Row>
            {uploadedFiles?.length < 10 && (<Row>
                <Col md={4}>
                  <Label htmlFor={'file-upload' + props.eventNum}>
                    <Icon color={'secondary'} name={'upload'} size={'medium'}/>
                    <Link styles={{ marginLeft: '.5rem', fontSize: '16px', fontWeight: 'bold' }} data-testid={'uploadButton'}>
                      {languageFile.uploadButtonText}
                    </Link>
                  </Label>
                  <StyledFileInput data-testid={'fileUpload' + props.eventNum} type={'file'} id={'file-upload' + props.eventNum} onChange={onUpload} accept={'.gif, .jpg, .pdf, .pptx, .tif, .tiff, .xls, .xlsx, .doc, .docx'} aria-label='Upload Document'/>
                </Col>
              </Row>)}
            <Row data-testid={'modalColumns'}>{renderColumns()}</Row>
          </Container>
        </Loader>
      </Modal>
      <RenderOpenButton showModal={open}/>
      <Row data-testid={'OnPageColumns'}>{renderOnPageColumns()}</Row>
    </>);
};
export default RtwDocumentUpload;
