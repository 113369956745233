import { Box, Heading, TabContainer, Table, TableBody, TableData, TableRow, TabSection, themes, } from '@digitools/honest-react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import React from 'react';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { DEDUCTIBLES_AND_COINSURANCE, getTabs, tealiumFormat, } from 'src/packages/dental/providers/DentalDataProvider';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import { DeductiblesAndCoinsuranceHeader } from 'src/packages/dental/pages/DeductibleAndCoinsurance';
const CoinsuranceRows = ({ coinsurances, networkType, coinsuranceClause }) => {
    const { t } = useTranslate();
    return (<Table className={'pt-0 mt-0 mb-1'}>
      <TableBody>
        <TableRow className={'border-bottom'} isHoverable={false}>
          <TableData className={'border-0'}></TableData>
          <TableData className={'border-0'}>
            <Heading elemType={'h4'}>{t(DentalText.youPayText)}</Heading>
          </TableData>
          <TableData className={'border-0'}>
            <Heading elemType={'h4'}>{t(DentalText.deductibleAppliesText)}</Heading>
          </TableData>
        </TableRow>
        {coinsurances &&
            coinsurances.map(coinsurance => coinsurance[networkType] && (<TableRow key={coinsurance.serviceCategory} className={'border-bottom'} isHoverable={false}>
                  <TableData className={'border-0'}>
                    <Heading elemType={'h4'}>{coinsurance.serviceCategory}</Heading>
                  </TableData>
                  <TableData className={'border-0 font-weight-light'}>
                    {coinsurance[networkType].networkCoinsurance}
                  </TableData>
                  <TableData className={'border-0 font-weight-light'}>
                    {coinsurance[networkType].deductibleApplies}
                  </TableData>
                </TableRow>))}

        {networkType === 'inNetwork' ? (<TableRow key={coinsuranceClause?.label} className={'border-bottom'} isHoverable={false}>
            <TableData className={'border-0'}>
              <Heading elemType={'h4'}>{coinsuranceClause?.label}</Heading>
            </TableData>
            <TableData className={'border-0 font-weight-light'}>{coinsuranceClause?.inNetworkValue}</TableData>
            <TableData className={'border-0 font-weight-light'}>{coinsuranceClause?.inNetworkValue}</TableData>
          </TableRow>) : (<TableRow key={coinsuranceClause?.label} className={'border-bottom'} isHoverable={false}>
            <TableData className={'border-0'}>
              <Heading elemType={'h4'}>{coinsuranceClause?.label}</Heading>
            </TableData>
            <TableData className={'border-0 font-weight-light'}>{coinsuranceClause?.outNetworkValue}</TableData>
            <TableData className={'border-0 font-weight-light'}>{coinsuranceClause?.outNetworkValue}</TableData>
          </TableRow>)}
      </TableBody>
    </Table>);
};
const Coinsurances = () => {
    const { currentMember, tealiumFunction } = useDentalDataContext();
    const { t } = useTranslate();
    const [inNetworkLabel, outNetworkLabel, deductiblesAndCoinsurances, coinsurances] = getTabs('coinsurances', currentMember);
    const hasData = !!(coinsurances && coinsurances?.length);
    const hasMultiTabs = !!(inNetworkLabel.length && outNetworkLabel.length);
    const trackDropdownEvent = (eventName) => tealiumFunction({
        hasEventView: false,
        eventAction: TEALIUM_EVENT.EVENT_ACTION.TAB,
        eventName: tealiumFormat(`${eventName}-coinsurances`),
        eventType: tealiumFormat(DEDUCTIBLES_AND_COINSURANCE),
    });
    return (<Box theme={themes.lincoln} className={'pb-0'}>
      <DeductiblesAndCoinsuranceHeader title={t(DentalText.coinsuranceTitleText)} text={currentMember?.deductiblesAndCoinsurance?.coinsuranceHelpText}/>
      {hasData && hasMultiTabs ? (<TabContainer noVertical={true}>
          <TabSection onClick={() => {
                trackDropdownEvent(inNetworkLabel);
            }} label={inNetworkLabel} name={'coinsurancesInNetworkTab'}>
            <CoinsuranceRows coinsurances={coinsurances} networkType={'inNetwork'} coinsuranceClause={currentMember?.deductiblesAndCoinsurance?.coinsuranceClause}/>
          </TabSection>
          <TabSection onClick={() => {
                trackDropdownEvent(outNetworkLabel);
            }} label={outNetworkLabel} name={'coinsurancesOutNetworkTab'}>
            <CoinsuranceRows coinsurances={coinsurances} networkType={'outNetwork'} coinsuranceClause={currentMember?.deductiblesAndCoinsurance?.coinsuranceClause}/>
          </TabSection>
        </TabContainer>) : (hasData && (<CoinsuranceRows coinsurances={coinsurances} networkType={inNetworkLabel ? 'inNetwork' : 'outNetwork'} coinsuranceClause={currentMember?.deductiblesAndCoinsurance?.coinsuranceClause}/>))}
    </Box>);
};
export default Coinsurances;
