// process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0"
// @ts-ignore
import { AriaLiveProvider, Container, ThemeProvider, themes } from '@digitools/honest-react';
import React, { useEffect, useRef } from 'react';
import { withCookies } from 'react-cookie';
// @ts-ignore
import styles from './App.scss';
// @ts-ignore
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
// @ts-ignore
import { createLogger } from 'redux-logger';
import thunkMiddleWare from 'redux-thunk';
import EmployeeProfile from '../components/employeeProfile/EmployeeProfile';
import Header from '../components/header/Header';
import AdminRouter from '../packages/admin/AdminRouter';
import BdmEligibilityContainer from '../packages/bdm/components/BdmEligibilityContainer';
import CreateMyReportRouter from '../packages/createMyReport/components/CreateMyReportRouter';
import EmployeeFormsContainer from '../packages/employeeForms/EmployeeFormsContainer';
import EmployerResourceContainer from '../packages/employerResources/EmployerResourceContainer';
import EmployeeContacts from '../packages/landingPages/components/employeeContacts/EmployeeContacts';
import CustomerSearchLandingPage from '../packages/landingPages/components/multiCustomer/CustomerSearchLandingPage';
import NewHomePageContainer from '../packages/landingPages/containers/NewHomePageContainer';
import LCI from '../packages/lci/components/LCI';
import MfaContainer from '../packages/mfa/MfaContainer';
import Login from '../packages/public/login/components/Login';
import LearnMore from '../packages/public/learnMore/LearnMore';
import PublicRouter from '../packages/public/PublicRouter';
import StandardReportsContainer from '../packages/reports/StandardReportsContainer';
import Rsc from '../packages/rsc/Rsc';
import Status from '../packages/status';
import { NotFound } from '../packages/status/components/pages';
import TeamViewContainer from '../packages/teamview/container/TeamViewContainer';
import Auth0Provider from '../providers/Auth0Provider';
import CoreProvider from '../providers/CoreProvider';
import MessageProvider from '../providers/MessageProvider';
import MfaProvider from '../providers/MfaProvider';
import PortalStatusProvider from '../providers/PortalStatusProvider';
import UnexpectedErrorProvider, { defaultUnexpectedErrorApi, } from '../providers/UnexpectedErrorProvider';
import rootReducer from '../reducers';
import configureAxios from '../utils/configureAxios';
import { getEnvVariable, isProd } from '../utils/envUtils';
import Rab from '../packages/rab/index';
import LogRocket from 'logrocket';
import EoiRouter from '../packages/eoi/EoiRouter';
import setupLogRocketReact from 'logrocket-react';
import Footer from '../components/footer/Footer';
import TealiumProvider from '../providers/TealiumProvider';
import UnplannedAbsence from '../packages/unplannedAbsence/UnplannedAbsence';
import Glossary from '../components/LearningCenter/Glossary';
import useAuthentication from '../hooks/useAuthentication';
import CliContainer from '../packages/cli/CliContainer';
import RiaContainer from 'src/packages/ria/RiaContainer';
import SsoService from 'src/components/NavMenuBar/NavMenu/SsoService';
import Accessibility from 'src/packages/public/accessibility/Accessibility';
import CommHelpStaticPage from 'src/packages/employeeProfile/components/commPref/CommHelpStaticPage';
import ContactUs from 'src/packages/public/contacts/ContactUs';
import ForgotInfo from 'src/packages/public/forgotInfo/components/ForgotInfo';
import RegistrationContainer from 'src/packages/public/registration/containers/RegistrationContainer';
import SsoLogoutPage from 'src/components/SsoLogoutPage/SsoLogoutPage';
import EnrollmentSuccess from 'src/packages/public/verifyEmail/EnrollmentSuccess';
import UnauthorizedPage from 'src/packages/public/static/UnauthorizedPage';
import USAAMobileAppStepper from 'src/packages/public/usaa/USAAMobileAppStepper';
import VerifyEmail from 'src/packages/public/verifyEmail/VerifyEmail';
import Prove from 'src/components/prove/Prove';
import AsContainer from 'src/packages/as/AsContainer';
import TimeoutModal from 'src/components/TimeoutModal/TimeoutModal';
import Maintenance from 'src/packages/public/maintenance/Maintenance';
import UserMigration from 'src/packages/userMigration/UserMigration';
import VanityPageContainer from 'src/packages/public/vanityPage/VanityPage';
import DentalContainer from "src/packages/dental/DentalContainer";
import { createTheme, MantineProvider } from '@mantine/core';
const loggerMiddleware = createLogger();
const store = createStore(rootReducer, applyMiddleware(thunkMiddleWare, loggerMiddleware));
const authorizeUserErrorCallback = (e) => {
    console.error(e);
};
const enableEmployeeMigration = getEnvVariable('enableEmployeeMigration');
const enableEmployeeMigrationForSoftLaunch = getEnvVariable('enableEmployeeMigrationForSoftLaunch');
const SamlpRouter = () => {
    return (<Auth0Provider audience={getEnvVariable('auth0Audience')} clientID={getEnvVariable('auth0ClientId')} domain={getEnvVariable('auth0SSODomain')} logoutUrl={getEnvVariable('logoutUrl')} redirectUri={getEnvVariable('auth0RedirectUri')} userMetadataUrl={getEnvVariable('userMetadataUrl')} isPublic={true}>
      <Outlet />
    </Auth0Provider>);
};
const Auth0Router = () => {
    return (<Auth0Provider audience={getEnvVariable('auth0Audience')} clientID={getEnvVariable('auth0ClientId')} domain={getEnvVariable('auth0SSODomain')} logoutUrl={getEnvVariable('logoutUrl')} redirectUri={getEnvVariable('auth0RedirectUri')} userMetadataUrl={getEnvVariable('userMetadataUrl')} isPublic={false}>
      <TimeoutModal ms={1200000} sRemainingForModal={180}/>
      <Outlet />
    </Auth0Provider>);
};
const SamlpCustomDomainRouter = () => {
    return (<Auth0Provider audience={getEnvVariable('auth0Audience')} clientID={getEnvVariable('auth0ClientId')} domain={getEnvVariable('auth0Domain')} logoutUrl={getEnvVariable('logoutUrl')} redirectUri={getEnvVariable('auth0RedirectUri')} userMetadataUrl={getEnvVariable('userMetadataUrl')} isPublic={true}>
      <Outlet />
    </Auth0Provider>);
};
const MfaRouter = () => {
    return (
    // @ts-ignore
    <MfaProvider>
      <Outlet />
    </MfaProvider>);
};
const mantineTheme = createTheme({
/** Put your mantine theme override here */
});
const BackDoorRouter = () => {
    return (<Auth0Provider audience={getEnvVariable('auth0Audience')} clientID={getEnvVariable('auth0ClientId')} domain={getEnvVariable('auth0Domain')} logoutUrl={getEnvVariable('logoutUrl')} redirectUri={getEnvVariable('auth0RedirectUri')} userMetadataUrl={getEnvVariable('userMetadataUrl')} isPublic={true}>
      <Outlet />
    </Auth0Provider>);
};
const App = () => {
    const unexpectedErrorApi = useRef(defaultUnexpectedErrorApi);
    const authApi = useRef({
        logout: () => { },
    });
    const { user, isAuthenticated } = useAuthentication();
    const setUnexpectedErrorApi = (api) => {
        unexpectedErrorApi.current = api;
    };
    const setAuthApi = (api) => {
        authApi.current = api;
    };
    useEffect(() => {
        configureAxios(unexpectedErrorApi, authApi);
    }, []);
    useEffect(() => {
        // wrap in if statement for now so we dont get console errors
        // due to missing appId for prod because we are in the POC phase
        const logRocketAppId = getEnvVariable('LOGROCKET_APP_ID');
        if (logRocketAppId) {
            LogRocket.init(logRocketAppId, {
                shouldCaptureIP: false,
                mergeIframes: true,
                browser: {
                    urlSanitizer: url => {
                        let sanitizedUrl = url;
                        sanitizedUrl = sanitizedUrl.replace(/access_token=([^&]*)/, 'access_token=**redacted**');
                        return sanitizedUrl;
                    },
                },
                dom: {
                    // @ts-ignore
                    textSanitizer: true,
                    inputSanitizer: true,
                },
                network: {
                    requestSanitizer: request => {
                        request.body = undefined;
                        request.headers['Authorization'] = '*****';
                        return request;
                    },
                    responseSanitizer: response => {
                        response.body = undefined;
                        return response;
                    },
                },
            });
            setupLogRocketReact(LogRocket);
            LogRocket.getSessionURL(sessionURL => {
                // @ts-ignore
                newrelic.setCustomAttribute('logrocket_url', sessionURL);
                // @ts-ignore
                window.logRocket = {
                    sessionURL,
                };
            });
        }
    }, [user, isAuthenticated]);
    return (<Provider store={store}>
      <BrowserRouter basename='/customer'>
        <ThemeProvider theme={themes.lincoln}>
          <MantineProvider theme={mantineTheme}>
            <CoreProvider authorizeUserErrorCallback={authorizeUserErrorCallback} getAuthApi={setAuthApi}>
              <TealiumProvider>
                <div className={styles.body}>
                  <>
                    <Header />
                  </>
                  <main id='maincontent' className={styles.main} tabIndex={-1}>
                    <AriaLiveProvider>
                      <Container theme={themes.lincoln} classNames={`${styles.container} mb-4`}>
                        <MessageProvider>
                          <PortalStatusProvider>
                            <UnexpectedErrorProvider getApi={setUnexpectedErrorApi}>
                              <Routes>
                                <Route path={'public/*'} element={<PublicRouter />}>
                                  <Route path='accessibility' element={<Accessibility />}/>
                                  <Route path='comm/help' element={<CommHelpStaticPage />}/>
                                  <Route path='contacts' element={<ContactUs />}/>
                                  <Route path='forgot-info' element={<ForgotInfo />}/>
                                  <Route path='login' element={<Login />}/>
                                  {(enableEmployeeMigration || enableEmployeeMigrationForSoftLaunch) && <Route path='learn-more' element={<LearnMore />}/>}
                                  {enableEmployeeMigration && <Route path='migration-help' element={<VanityPageContainer />}/>}
                                  <Route path='registration' element={<RegistrationContainer />}/>
                                  <Route path='ssoLogout' element={<SsoLogoutPage />}/>
                                  <Route path='success' element={<EnrollmentSuccess />}/>
                                  <Route path='unauthorized' element={<UnauthorizedPage />}/>
                                  <Route path='usaa' element={<USAAMobileAppStepper />}/>
                                  <Route path='verify-email' element={<VerifyEmail />}/>
                                  <Route path='*' element={<NotFound />}/>
                                  <Route path={'maintenance'} element={<Maintenance />}/>
                                </Route>
                                <Route path={'mfa/*'} element={<MfaRouter />}>
                                  <Route path={`register`} element={<MfaContainer />}/>
                                  <Route path='*' element={<NotFound />}/>
                                </Route>
                                {(enableEmployeeMigration || enableEmployeeMigrationForSoftLaunch) && <Route path={'migrate/*'} element={<UserMigration />}/>}
                                {/*samlpcustomrouter*/}
                                <Route path={'samlpv2/*'} element={<SamlpCustomDomainRouter />}>
                                  <Route path={`:connectionName`} element={<></>}/>
                                  <Route path='*' element={<NotFound />}/>
                                </Route>
                                {/*samlprouter*/}
                                <Route path={'samlp/*'} element={<SamlpRouter />}>
                                  <Route path={`:connectionName`} element={<></>}/>
                                  <Route path='*' element={<NotFound />}/>
                                </Route>
                                <Route path={'backdoor/*'} element={<BackDoorRouter />}>
                                  <Route path={`login`} element={<Login />}/>
                                </Route>
                                {/*  Begin new routes */}
                                <Route path={'*'} element={<Auth0Router />}>
                                  {/*<LogRocketOptOutChecker />*/}
                                  <Route path={'prove'} element={<Prove />}/>
                                  <Route path={'cli/*'} element={<CliContainer />}/>
                                  <Route path={'as/*'} element={<AsContainer />}/>
                                  {!isProd() && <Route path={'employee/dental/*'} element={<DentalContainer />}/>}
                                  <Route path={'customer-search'} element={<CustomerSearchLandingPage />}/>
                                  <Route path={'employee/unplanned-absence/*'} element={<UnplannedAbsence />}/>
                                  <Route path={'employer/unplanned-absence/*'} element={<UnplannedAbsence />}/>
                                  <Route path={'eoi/*'} element={<EoiRouter />}/>
                                  <Route path={'ria/*'} element={<RiaContainer />}/>
                                  <Route path={'ssologin/:id'} element={<SsoService />}/>
                                  <Route path={'status/*'} element={<Status />}/>
                                  <Route path={`admin/*`} element={<AdminRouter />}/>
                                  <Route path={`bdm/*`} element={<BdmEligibilityContainer />}/>
                                  <Route path={`create-my-report/*`} element={<CreateMyReportRouter />}/>
                                  <Route path={`employee-forms/*`} element={<EmployeeFormsContainer />}/>
                                  <Route path={`employee/home`} element={<NewHomePageContainer />}/>
                                  <Route path={`employer/home`} element={<NewHomePageContainer />}/>
                                  <Route path={`employer/resource-center`} element={<EmployerResourceContainer />}/>
                                  <Route path={`home/contacts`} element={<EmployeeContacts />}/>
                                  <Route path={`lci`} element={<LCI />}/>
                                  <Route path={`learning/glossary/*`} element={<Glossary />}/>
                                  <Route path={`profile/*`} element={<EmployeeProfile />}/>
                                  <Route path={`rab/employee`} element={<Rab />}/>
                                  <Route path={`reports/*`} element={<StandardReportsContainer />}/>
                                  <Route path={`rsc`} element={<Rsc />}/>
                                  <Route path={`teamview/*`} element={<TeamViewContainer />}/>
                                  <Route path={`v2/callback/*`} element={<></>}/>
                                  <Route path={'*'} element={<NotFound />}/>
                                </Route>
                              </Routes>
                            </UnexpectedErrorProvider>
                          </PortalStatusProvider>
                        </MessageProvider>
                      </Container>
                    </AriaLiveProvider>
                  </main>
                  <Footer />
                </div>
              </TealiumProvider>
            </CoreProvider>
          </MantineProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>);
};
export default withCookies(App);
