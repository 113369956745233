import { Spinner, StepperContext, NewModal as Modal } from '@digitools/honest-react';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { useFormState } from 'informed';
import React, { useContext, useEffect, useRef } from 'react';
import useAuthentication from '../../../hooks/useAuthentication';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';
import useTranslate from '../../../hooks/useTranslate';
import { DeleteInterviewData } from '../api/EoiApiCalls';
import useEoiContext from '../hooks/useEoiContext';
import { SaveForLaterText } from '../i18n/SaveForLaterText';
import styled from 'styled-components';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { getEventStatusByCurrentStep } from '../utils/EventStatusStepUtil';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
const StyledLaterButton = styled(Button) `
    @media(max-width: 1200px) {
      padding: 0 1rem;
    }
`;
const StyledDeleteButton = styled(Button) `
    @media(max-width: 1200px) {
        padding: 0 1rem;
    }
`;
const StyledModal = styled(Modal) `
  .mantine-Modal-content{
    padding :1rem 2.8125rem 1rem !important;
  }
  .ggdDYg {
    flex-direction: row-reverse;
    margin-left: -1rem;
  }
  p {
    margin: 0.875rem 0px 1.875rem 0px;
  }
`;
const SaveForLaterButtons = (props) => {
    const { savedInterview, sflModalBodyText, sflModalHeaderText, setSaveForLaterError, saveForLaterError, displaySaveForLaterModal, setDisplaySaveForLaterModal, setDisplaySaveForLaterError, displaySaveForLaterError, initialInformation, } = useEoiContext();
    const { t } = useTranslate();
    const { authHeader } = useAuthentication();
    const formState = useFormState();
    const { trackClickWithPage } = useGoogleAnalytics();
    const { trackEvent } = useTealium();
    const stepper = useContext(StepperContext);
    let navigate = useNavigate();
    const setIsOpenRef = useRef(() => { });
    useEffect(() => {
        if (!saveForLaterError && displaySaveForLaterModal && displaySaveForLaterError) {
            setIsOpenRef.current(true);
            setDisplaySaveForLaterModal(true);
        }
        else if (saveForLaterError) {
            setIsOpenRef.current(false);
            setDisplaySaveForLaterModal(false);
        }
    }, [saveForLaterError, savedInterview, displaySaveForLaterModal, displaySaveForLaterError]);
    const saveInterview = async () => {
        setSaveForLaterError(undefined);
        if (props.saveToProvider) {
            props.saveToProvider(formState.values);
        }
    };
    // Save Modal wraps this button
    const SaveButton = ({ showModal }) => {
        const handleOnClick = async () => {
            showModal();
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.EOI,
                event_name: 'save for later',
                event_version: props.analyticsPageName,
                event_status: getEventStatusByCurrentStep(stepper, t, initialInformation, props.analyticsPageName)
            });
            if (!formState.invalid) {
                setDisplaySaveForLaterModal(true);
                setDisplaySaveForLaterError(true);
                await saveInterview();
                setDisplaySaveForLaterModal(false);
            }
        };
        return (<StyledLaterButton type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-save-button'}>
        <Icon name={'save'} color={'secondary'} size={'small'} style={{ marginRight: '0.5rem' }}/>
        {t(SaveForLaterText.saveForLater)}
      </StyledLaterButton>);
    };
    // Delete Modal wraps this button
    const DeleteButton = ({ showModal }) => {
        const handleOnClick = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.EOI,
                event_name: 'delete application',
                event_version: props.analyticsPageName,
                event_status: getEventStatusByCurrentStep(stepper, t, initialInformation, props.analyticsPageName)
            });
            showModal();
        };
        return (<StyledDeleteButton type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-delete-button'} style={{ marginLeft: '0rem' }}>
        <Icon name={'trash'} color={'primaryDark'} size={'small'} style={{ marginRight: '0.5rem' }}/>
        <span style={{ color: themes.lincoln.palette.primaryDark }}>{t(SaveForLaterText.deleteApplication)}</span>
      </StyledDeleteButton>);
    };
    const [isDeleteOpen, deleteModal] = useDisclosure(false);
    const [isSaveOpen, saveModal] = useDisclosure(false);
    return (<>
      {savedInterview && (<>
          <StyledModal title={t(SaveForLaterText.deleteApplication)} opened={isDeleteOpen} centered={true} onClose={() => {
                deleteModal.close();
                trackClickWithPage('saveForLaterReturnToApplication', props.page);
                setDisplaySaveForLaterModal(false);
                setDisplaySaveForLaterError(false);
            }} cancelButtonText={t(SaveForLaterText.returnToApplication)} cancelButtonProps={{
                'data-testid': 'sfl-return-button',
                buttonType: 'text',
                'aria-label': 'return to application'
            }} submitButtonText={t(SaveForLaterText.deleteApplicationModal)} submitButtonProps={{
                'data-testid': 'sfl-delete-button',
                buttonType: 'primary',
                'aria-label': 'delete application'
            }} onSubmit={async () => {
                trackClickWithPage('saveForLaterDeleteApplicationConfirmation', props.page);
                try {
                    await DeleteInterviewData(authHeader());
                    window.location.assign('/customer/employee/home');
                }
                catch (error) {
                    setSaveForLaterError(t(SaveForLaterText.saveForLaterDeleteErrorMessage));
                    setDisplaySaveForLaterError(true);
                    deleteModal.close();
                }
            }} 
        // footerContentPosition={'flex-start'}
        theme={themes.lincoln} data-testid={'sfl-delete-modal'}>
            <p>
              <strong>{t(SaveForLaterText.deleteApplicationHeaderSuccess)}</strong>
              <br />
              <br />
              {t(SaveForLaterText.deleteApplicationTextSuccess)}
            </p>
          </StyledModal>
          <DeleteButton showModal={deleteModal.open}/>
        </>)}

      <StyledModal title={t(SaveForLaterText.saveForLater)} opened={isSaveOpen} centered={true} onClose={() => {
            saveModal.close();
            trackClickWithPage('saveForLaterReturnToApplication', props.page);
            setDisplaySaveForLaterModal(false);
            setDisplaySaveForLaterError(false);
        }} cancelButtonText={t(SaveForLaterText.returnToApplication)} cancelButtonProps={{
            'data-testid': 'sfl-return-button',
            buttonType: 'text',
            'aria-label': 'return to application'
        }} submitButtonText={t(SaveForLaterText.exitApplication)} submitButtonProps={{
            'data-testid': 'sfl-exit-button',
            buttonType: 'primary',
            'aria-label': 'exit application'
        }} onSubmit={() => {
            trackClickWithPage('saveForLaterExitApplication', props.page);
            navigate(`/employee/home`);
        }} 
    // footerContentPosition={'flex-start'}
    theme={themes.lincoln} data-testid={'sfl-save-modal'}>
        {sflModalHeaderText && sflModalBodyText ? (<p>
            <strong> {sflModalHeaderText} </strong>
            <br />
            <br />
            {sflModalBodyText}
          </p>) : (<Spinner id='sfl-buttons-spinner'/>)}
      </StyledModal>
      <SaveButton showModal={saveModal.open}/>
    </>);
};
export default SaveForLaterButtons;
