import { Button, NewModal as Modal } from '@digitools/honest-react';
import React from 'react';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import { withRouter } from 'src/utils/withRouter';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
const OpenModalButton = ({ showModal, step, pageTitle }) => {
    const { trackEvent } = useTealium();
    const showConfirmationModal = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_status: step,
            event_name: 'cancel',
            event_version: pageTitle,
        });
        showModal();
    };
    return (<Button className={'mr-3'} name='cancel-lci' onClick={showConfirmationModal} type='button' buttonType='secondary'>
      Cancel
    </Button>);
};
const CancelLci = (props) => {
    const navigate = useNavigate();
    const { trackEvent } = useTealium();
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <Modal opened={isOpen} id={'cancelModal'} title='Cancel Claim' onClose={() => {
            close();
        }} size={'419px'} cancelButtonText={'Cancel'} closeOnClickOutside={true} submitButtonText={'Ok'} onSubmit={() => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
                event_name: 'Cancel',
                event_status: TEALIUM_EVENT.EVENT_STATUS.CANCEL
            });
            navigate(`/employer/home`);
        }} cancelButtonProps={{
            'data-testid': 'cancelModal-cancel',
            buttonType: 'text',
            'aria-label': 'cancel return to application'
        }} submitButtonProps={{
            'data-testid': 'cancelModal-ok',
            buttonType: 'primary',
            'aria-label': 'ok, cancel claim'
        }}>
        <p>If you leave this page, data entered will be lost.</p>
      </Modal>
      <OpenModalButton step={props.step} pageTitle={props.pageTitle} showModal={open}/>
    </>);
};
export default withRouter((props) => (<CancelLci {...props}/>));
