import React, { useRef, useState, useMemo } from 'react';
import { NewModal as Modal, CompositeTable as Table, Button, Heading, Row, Col, themes } from '@digitools/honest-react';
import SubsLocsColumns from '../../../Constants/SubsLocsColumns';
import { useFormApi } from 'informed';
import { getSelectedClaimProductsFromFormApi } from '../FilterMyReportValidation';
import { useDisclosure } from '@mantine/hooks';
const ButtonLabelText = {
    SelectAll: 'Select all subsidiaries/locations',
    DeselectAll: 'Deselect all subsidiaries/locations',
};
const SubLocModal = ({ getSelected, data, selectedSubLocs, reportFilterCategory }) => {
    const tableApi = useRef({ setSelectedData: () => { } });
    const getSelectedRef = useRef([]);
    const formApi = useFormApi();
    const [buttonLabels, setDoSelectAll] = useState();
    const [tableReRender, setTableReRender] = useState(false);
    const [selectedLocationCount, setSelectedLocationCount] = useState(0);
    const getSelectAllButtonLabel = (selectedArrayLength) => {
        setSelectedLocationCount(selectedArrayLength);
        setDoSelectAll(selectedArrayLength === data.length ? ButtonLabelText.DeselectAll : ButtonLabelText.SelectAll);
    };
    const setTableApi = (api) => {
        tableApi.current = api;
    };
    const RenderOpenButton = ({ showModal, reportFilterCategory }) => {
        const selectedProducts = (typeof formApi.getValue === 'function' && getSelectedClaimProductsFromFormApi(formApi)) || [];
        const handleOnClick = () => {
            getSelectAllButtonLabel(getSelectedRef.current.length);
            setTableReRender(true);
            showModal();
        };
        return (<Button buttonType={'text'} type={'button'} onClick={handleOnClick} data-testid={'openModal'} aria-label='Modify subsidiaries/locations modal' disabled={reportFilterCategory === '' || (reportFilterCategory === 'Claim' && !selectedProducts.length)}>
        Modify subsidiaries/locations
      </Button>);
    };
    const FooterPrimaryRenderButton = () => {
        getSelected(getSelectedRef);
        setTableReRender(false);
    };
    const selectDeSelectSubsLoc = () => buttonLabels === ButtonLabelText.SelectAll
        ? tableApi.current.setSelectedData(data)
        : tableApi.current.setSelectedData([]);
    const table = useMemo(() => (<Table data={data} theme={themes.lincoln} defaultPageSize={10} columns={SubsLocsColumns} paginated={true} filterable={true} isSelectable={true} selectedRef={getSelectedRef} rowIdentifier={'locationId'} data-testid={'subLocTable'} tableApi={setTableApi} onSelectedChange={(selected) => getSelectAllButtonLabel(selected.length)}/>), [tableReRender]);
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <Modal style={{ margin: '2rem', overflowX: 'scroll' }} title={'Modify subsidiaries/locations'} centered={true} opened={isOpen} onClose={() => {
            tableApi.current.setSelectedData(selectedSubLocs);
            setTableReRender(false);
            close();
        }} cancelButtonText={'Cancel'} submitButtonText={'Save Changes'} onSubmit={() => {
            FooterPrimaryRenderButton();
            close();
        }} cancelButtonProps={{
            'data-testid': 'cancelSubLocs',
            buttonType: 'text',
            'aria-label': 'cancel subsidiaries/locations modal'
        }} submitButtonProps={{
            'data-testid': 'saveSubLocs',
            buttonType: 'primary',
            'aria-label': 'save subsidiaries/locations modal'
        }} theme={themes.lincoln} closeOnClickOutside={true}>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Heading style={{ marginBottom: '10px' }} data-testid={'selectedSubLocHeader'} elemType={'h6'}>
              {selectedLocationCount} of {data.length} subsidiaries/locations selected
            </Heading>
          </Col>
          <Col>
            <Button style={{ marginBottom: '15px', float: 'right', textAlign: 'right', paddingRight: '0rem' }} theme={themes.lincoln} data-testid={'selectSubs'} type={'button'} onClick={selectDeSelectSubsLoc}>
              {buttonLabels}
            </Button>
          </Col>
        </Row>
        {table}
      </Modal>
      <RenderOpenButton reportFilterCategory={reportFilterCategory} showModal={() => open()}/>
    </>);
};
export default SubLocModal;
