import { Icon, NewModal as Modal, Link } from '@digitools/honest-react';
import React from 'react';
import styled from 'styled-components';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useTranslate from 'src/hooks/useTranslate';
import { useDisclosure } from '@mantine/hooks';
const StyledPelement = styled('p') `
color: black;
font-weight:normal
margin:2rem 1rem;
`;
export const RemoveUserModalText = {
    headerText: {
        english: 'Confirm',
        spanish: 'Confirmar',
    },
    modalText: {
        english: 'If you cancel now, the user you selected will not be removed. By clicking OK, the selected user will permanently lose access to the Employer functions of the portal.',
        spanish: 'Si cancelas ahora, el usuario que seleccionaste no será eliminado. Al hacer clic en Aceptar, el usuario seleccionado perderá permanentemente el acceso a las funciones de Empleador del portal.',
    },
};
const RemoveUserModal = ({ removeSelectUser, user }) => {
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    const [isOpen, { open, close }] = useDisclosure(false);
    const RenderOpenButton = ({ showModal }) => {
        const handleOnClick = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.REMOVE_USER_MODAL,
                event_name: 'Remove Employer Access',
                event_status: TEALIUM_EVENT.EVENT_STATUS.OPEN,
            });
            showModal();
        };
        return (<Link id={'remove-user-openmodal'} data-testid='openModal' aria-label='Remove Employer Access' onClick={handleOnClick}>
        <b>
          <Icon name={'trash'} color={'accent1Light'} id='icon-remove-user' size={'xs'}/> Remove Employer Access
        </b>
      </Link>);
    };
    return (<>
      <Modal size={'690px'} title={`${t(RemoveUserModalText.headerText)}`} submitButtonText={'OK'} cancelButtonText={'Cancel'} onSubmit={() => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.REMOVE_USER_MODAL,
                event_name: 'Remove Employer Access',
                event_status: TEALIUM_EVENT.EVENT_STATUS.CONFIRM,
            });
            removeSelectUser(user);
            close();
        }} onClose={() => {
            close();
        }} opened={isOpen} submitButtonProps={{
            'data-testid': 'saveSubLocs',
            'aria-label': 'Ok remove Employer Access'
        }} cancelButtonProps={{
            'id': 'remove-user-closemodal',
            'data-testid': 'hideModal',
            'aria-label': 'Cancel remove Employer Access'
        }}>
        <StyledPelement id='Remove Employer Access Message'>
          {t(RemoveUserModalText.modalText)}
        </StyledPelement>
      </Modal>
      <RenderOpenButton showModal={open}/>
    </>);
};
export default RemoveUserModal;
