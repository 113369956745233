import { NewModal as Modal } from '@digitools/honest-react';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import React, { useEffect } from 'react';
import useAuthentication from '../../../hooks/useAuthentication';
import useTranslate from '../../../hooks/useTranslate';
import { DeleteInterviewData } from '../api/EoiApiCalls';
import { ApplicantCoverageText } from '../i18n/ApplicantCoverageText';
import { SaveForLaterText } from '../i18n/SaveForLaterText';
import { useDisclosure } from '@mantine/hooks';
const DuplicateApplicationModal = (props) => {
    const { t } = useTranslate();
    const { authHeader, user } = useAuthentication();
    const hasEoiStatusEnabled = user && user.applicationPermissions && user.applicationPermissions.indexOf('statusEE.eoiStatus') > -1;
    useEffect(() => {
        if (props?.isDuplicate === true) {
            open();
        }
    }, [props?.isDuplicate]);
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<Modal theme={themes.lincoln} closeOnClickOutside={true} opened={isOpen} centered={true} submitButtonText={t(SaveForLaterText.exitApplication)} submitButtonProps={{
            'data-testid': 'duplicateAppExitButton',
            buttonType: 'secondary',
            type: 'button',
            'aria-label': 'exit duplicate application'
        }} onSubmit={async () => {
            await DeleteInterviewData(authHeader());
            window.location.assign('/customer/employee/home');
            close();
        }} title={t(ApplicantCoverageText.duplicateApplication)} onClose={() => { }} cancelButton={false} size={'680px'}>
      {hasEoiStatusEnabled
            ? t(ApplicantCoverageText.duplicateApplicationModalTextEoiStatus)
            : t(ApplicantCoverageText.duplicateApplicationModalText)}
    </Modal>);
};
export default DuplicateApplicationModal;
