import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useTranslate from 'src/hooks/useTranslate';
import { Alert, Spinner } from '@digitools/honest-react';
import DentalText from 'src/packages/dental/constants/DentalText';
import React from 'react';
//TODO This is where the content will go in the subsequent stories, this is a placeholder
export const ClaimsInfo = () => {
    const { infoLoading } = useDentalDataContext();
    if (infoLoading) {
        return <Spinner data-testid='claims-spinner'/>;
    }
    return <div>Claims stuff</div>;
};
const Claims = () => {
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    return (<>
      {!!currentMember && !!subscriber && (<>
          <p>{t(DentalText.disclaimerText)}</p>
          {currentMember?.planOverview?.inNetworkOnlyClause && (<Alert style={{ padding: '.5rem' }} type={'info'}>{currentMember.planOverview.inNetworkOnlyClause}</Alert>)}
          <ClaimsInfo />
        </>)}
    </>);
};
export default Claims;
