export const AboutYouText = {
    pageTitleEE: {
        english: 'About you',
        spanish: 'Acerca de usted'
    },
    pageTitleER: {
        english: 'About your Employee',
        spanish: 'Sobre tu Empleado'
    },
    primaryWorkLocation: {
        english: 'Primary work location',
        spanish: 'Lugar de trabajo principal',
    },
    primaryWorkLocationER: {
        english: 'Employee\'s primary work location',
        spanish: 'Lugar de trabajo principal del empleado',
    },
    disclaimer: {
        english: '*If you are working from home, but are not designated a remote employee, please select the work state for your regular office assignment',
        spanish: '*Si trabaja desde casa, pero no está designado como empleado remoto, seleccione el estado de trabajo para su asignación habitual de oficina.',
    },
    countrySelect: {
        english: 'Country of employment',
        spanish: 'País de empleo'
    },
    stateSelect: {
        english: 'State of employment',
        spanish: 'Estado de empleo'
    },
    provinceSelect: {
        english: 'Province of employment',
        spanish: 'Provincia de empleo'
    },
    buttonText: {
        english: 'Continue',
        spanish: 'Seguir'
    },
    nameError: {
        english: 'Allowed characters are: A-Z a-z space . - \' with no trailing spaces',
        spanish: 'Los caracteres permitidos son: A-Z a-z. - \' sin espacios finales'
    },
    paidLeaveBlockText: {
        english: (state) => `To file a disability claim or ${state} paid family leave, call 866-783-2255.`,
        spanish: (state) => `Para presentar un reclamo por discapacidad o ${state} licencia familiar pagada, llame 866-783-2255.`,
    }
};
