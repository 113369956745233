import { Button, NewModal as Modal, StepperContext, themes } from '@digitools/honest-react';
import React, { useContext } from 'react';
import useTranslate from '../../../hooks/useTranslate';
import styled from 'styled-components';
import { SaveForLaterText } from '../../eoi/i18n/SaveForLaterText';
import { useNavigate } from 'react-router-dom';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useCliDataContext from '../hooks/useCliDataContext';
import { AboutYouText } from '../constants/AboutYouText';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { getEventStatusByCurrentStep } from '../utils/eventStatusStepUtil';
import { useDisclosure } from '@mantine/hooks';
const StyledCancelButton = styled(Button) `
  @media (max-width: 1200px) {
    padding: 0 1rem;
    margin-left: 0rem !important;
  }
`;
const StyledModal = styled(Modal) `
  .mantine-Modal-content{
    padding: 1rem 2.8125rem 1rem !important;
  }
  .ggdDYg {
    flex-direction: row-reverse;
    margin-left: -1rem;
  }
  p {
    margin: 0.875rem 0px 1.875rem 0px;
  }
`;
const CancelApplicationButton = ({ analyticsPageName }) => {
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const { resetCliDataContext, setIsSflModalOpen } = useCliDataContext();
    const navigate = useNavigate();
    const stepper = useContext(StepperContext);
    const aboutYouPageTitleER = CustomEmployeeText(t(AboutYouText.pageTitleER));
    ;
    // Cancel on ER only, Cancel Modal wraps this button
    const CancelButton = ({ showModal }) => {
        const handleOnClick = () => {
            setIsSflModalOpen(true);
            showModal();
        };
        return (<StyledCancelButton type={'button'} buttonType={'secondary'} theme={themes.lincoln} onClick={handleOnClick} aria-label='cancel application' data-testid={'sfl-cancel-button'}>
        {t(SaveForLaterText.cancelApplication)}
      </StyledCancelButton>);
    };
    const CancelApplicationButton = async () => {
        setIsSflModalOpen(false);
        resetCliDataContext();
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: 'cancel application',
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
            event_version: analyticsPageName,
            event_status: getEventStatusByCurrentStep(stepper, t, aboutYouPageTitleER),
        });
        navigate(0);
    };
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <StyledModal title={t(SaveForLaterText.cancelApplication)} centered={true} opened={isOpen} onClose={() => {
            setIsSflModalOpen(false);
            close();
        }} cancelButtonText={t(SaveForLaterText.returnToApplication)} submitButtonText={t(SaveForLaterText.cancelApplication)} onSubmit={CancelApplicationButton} cancelButtonProps={{
            'data-testid': 'sfl-return-button',
            buttonType: 'text',
            'aria-label': 'return to application'
        }} submitButtonProps={{
            'data-testid': 'sfl-cancel-application',
            buttonType: 'primary',
            'aria-label': 'cancel application'
        }} theme={themes.lincoln} data-testid={'sfl-cancel-modal'}>
        <p>
          <strong>{t(SaveForLaterText.cancelApplicatinModalText)}</strong>
        </p>
      </StyledModal>
      <CancelButton showModal={open}/>
    </>);
};
export default CancelApplicationButton;
