import React from 'react';
import { CompositeTable as DataTable, Spinner, Alert, Button, NewModal as Modal, Icon } from '@digitools/honest-react';
import { useLanguage } from 'status/hooks';
import useGet from '../../../../hooks/useGet';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import { useDisclosure } from '@mantine/hooks';

const Error = ({ error }) => (
  <Alert type={'error'}>
    <span>
      <strong>Error!</strong>&nbsp;Unable to load glossary data.
    </span>
  </Alert>
);

const OpenButton = ({ showModal }) => {
  const { trackEvent } = useTealium();

  const handleOnClick = () => {
    trackEvent({
      event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
      event_type: TEALIUM_EVENT.EVENT_TYPE.SHOW_GLOSSARY,
      event_name: 'Glossary',
    });
    showModal();
  };
  return (
    <Button aria-label='Glossary Modal' onClick={handleOnClick} style={{ marginLeft: '0', paddingLeft: 0 }}>
      <Icon name='info' color={'secondary'} style={{ paddingRight: '.25rem' }} />
      Glossary
    </Button>
  );
};

const ModalContent = ({ loading, error, glossaryList }) => {
  const { language } = useLanguage();

  const lang = language.toUpperCase();

  if (loading) {
    return <Spinner id='glossarySpinner' />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const columns = [
    {
      Cell: ({ data }) => {
        return <strong>{data[`fieldValueTerm_${lang}`]}</strong>;
      },
      Header: 'Value',
      accessor: `fieldValueTerm_${lang}`,
      id: `fieldValueTerm_${lang}`,
    },
    {
      Header: 'Description',
      accessor: `fieldValueDescription_${lang}`,
      id: `fieldValueDescription_${lang}`,
    },
  ];

  return (
    <div style={{ minWidth: '70vw' }}>
      <DataTable filterable={true} data={glossaryList} columns={columns} paginated={true} />
    </div>
  );
};

const Glossary = () => {
  const {
    error,
    loading,
    data: glossaryList,
  } = useGet({
    url: '/status-service/customer/statusGlossary',
    options: {
      params: {
        applicationInternalName: 'STATUS_ER',
      },
    },
  });

  const [isOpen, { open, close }] = useDisclosure(false); 

  return (
    <>
      <Modal
        withCloseButton={true}
        title='Glossary'
        size='auto'
        cancelButtonText='Close'
        cancelButtonProps={{
          buttonType: 'primary', 
          'data-testid': 'glossary-close',
          'aria-label': 'close glossary'
        }}
        centered={true}
        opened={isOpen}
        onClose={() => {
          close();
        }}
        submitButton={false}
        closeOnClickOutside={true}
      >
        <ModalContent loading={loading} glossaryList={glossaryList} error={error} />
      </Modal>
      <OpenButton showModal={open}/>
    </>
  );
};

export default Glossary;
