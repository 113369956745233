import React, { useState, useContext } from 'react';
import { Icon, NewModal as Modal, Row, Col, TextInput, SelectInput } from '@digitools/honest-react';
import styled from 'styled-components';
import { BDM_BENEFICIARY_TYPES, BDM_BENEFICIARY_TYPES_ES, PRIMARY } from '../constants/BdmConstants';
import { BdmAddBeneficiaryModalDisplayTextEn as LanguageEn } from '../language/BdmAddBeneficiaryModalDisplayTextEn';
import { BdmAddBeneficiaryModalDisplayTextEs as LanguageEs } from '../language/BdmAddBeneficiaryModalDisplayTextEs';
import { numbersOnly } from '../../../utils/validationUtils';
import BdmModalContent from './BdmModalContent';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import { useFormState } from 'informed';
import { useIsLci } from '../../../providers/IsLciProvider';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { Language } from '../../../types/Language';
import { useDisclosure } from '@mantine/hooks';
const StyledTextInput = styled(TextInput) `
  width: 5.75rem;
  min-width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  font-weight: bold;
  color: #002663;
  background-color: #e2edf6;
  padding: 0;

  ::placeholder {
    color: #002663;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #002663;
  }
`;
const StyledCategory = styled(TextInput) `
  ::placeholder {
    color: #000;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #000;
  }
`;
const StyledRow = styled(Row) `
  display: flex;
  align-items: flex-end;
`;
const StyledIcon = styled(Icon) `
  color: #23669a;
  margin: 0.5rem 0.625rem;
  font-size: 1.2rem;
`;
const StyledDiv = styled('div') `
  color: #23669a;
  cursor: pointer;
`;
const StyledModal = styled(Modal) `
  .mantine-Modal-content {
    padding: 1rem 2.1875rem 0 !important;
  }
  .mantine-Modal-body {
    padding-top: 1rem !important;
  }
  .ggdDYg {
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-left: -1rem;
  }
`;
const BdmModalEditBeneficiary = ({ beneficiaries, beneficiaryCategory, beneficiaryIndex, displayEditDeleteIconsFlag, setRerenderAllocation, toggleButtonState, updateBeneficiaries, }) => {
    const experienceContext = useContext(ExperienceContext);
    const displayText = experienceContext.language === Language.SPANISH ? LanguageEs : LanguageEn;
    const idPrefix = BdmBeneficiaryUtils.idBuilder(beneficiaryCategory, beneficiaryIndex);
    const displayIconsValue = displayEditDeleteIconsFlag ? 'inline-block' : 'none';
    const beneficiary = beneficiaries[beneficiaryIndex];
    let beneficiaryAtTypeChange;
    const isLci = useIsLci();
    const [formFilled, setFormFilled] = useState(false);
    const [formApi, setFormApi] = useState();
    const [beneficiaryTypeState, setBeneficiaryTypeState] = useState(beneficiary.beneficiaryType);
    const [rerenderAllocationInModal, setRerenderAllocationInModal] = useState(true);
    const formState = useFormState();
    const RenderOpenModalButton = ({ showModal }) => {
        const handleClick = async () => {
            await setRerenderAllocationInModal(false);
            await setRerenderAllocationInModal(true);
            if (formApi) {
                formApi.setValue(`${idPrefix}country`, beneficiary.country);
                if (beneficiary.postalCode) {
                    formApi.setValue(`${idPrefix}postalCode`, beneficiary.postalCode);
                }
            }
            showModal();
        };
        return (<StyledDiv id={`div-open-edit-modal-${beneficiaryCategory}${beneficiaryIndex}`} style={{ display: displayIconsValue }} onClick={handleClick}>
        <StyledIcon id={`btn-open-edit-modal-${beneficiaryCategory}${beneficiaryIndex}`} name={'edit'} size={'medium'}/>
        <span>{displayText.edit}</span>
      </StyledDiv>);
    };
    const handleSubmit = async (values) => {
        setRerenderAllocation(false);
        const formToDatabase = BdmBeneficiaryUtils.formToBeneficiary(values, beneficiaryCategory, beneficiaryIndex, 'edit');
        formToDatabase.beneficiaryCategory = beneficiaryCategory;
        formToDatabase.allocation = formToDatabase.allocation ? formToDatabase.allocation : '0';
        beneficiaries.splice(beneficiaryIndex, 1, formToDatabase);
        await updateBeneficiaries(beneficiaryCategory, beneficiaries);
        setRerenderAllocation(true);
        close();
    };
    const handleTypeChange = (event) => {
        const newType = event.target.value;
        setBeneficiaryTypeState(newType);
        beneficiaryAtTypeChange = BdmBeneficiaryUtils.handleEditFormTypeChange({ ...beneficiary }, newType, idPrefix);
    };
    const formCheck = (values) => {
        BdmBeneficiaryUtils.checkForFormCompletion(values, toggleButtonState, beneficiaryTypeState, beneficiaryCategory, setFormFilled, isLci, beneficiaryIndex, 'edit');
    };
    const getCategoryTitle = () => {
        if (beneficiaryCategory === PRIMARY) {
            return displayText.beneficiaryCategoryPrimary;
        }
        else {
            return displayText.beneficiaryCategoryContingent;
        }
    };
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <StyledModal isForm={true} centered={true} opened={isOpen} formProps={{
            id: idPrefix + '-form-edit',
            onValueChange: formCheck,
            onSubmit: handleSubmit,
            getApi: setFormApi
        }} onClose={async () => {
            await setBeneficiaryTypeState(beneficiary.beneficiaryType);
            if (formApi) {
                formApi.reset();
            }
            close();
        }} cancelButtonText={displayText.cancelButton} submitButtonText={displayText.editBeneficiary} cancelButtonProps={{
            'data-testid': `btn-hide-edit-modal-${beneficiaryCategory}${beneficiaryIndex}`,
            buttonType: 'text',
            'aria-label': 'cancel edit beneficiary'
        }} submitButtonProps={{
            'data-testid': `btn-confirm-edit-${idPrefix}`,
            buttonType: 'primary',
            type: 'submit',
            disabled: !formFilled || formState.invalid,
            'aria-label': 'confirm edit beneficiary'
        }} size={'1463px'} title={displayText.modalHeader} closeOnClickOutside={true}>
        <StyledRow>
          <Col>
            <StyledCategory field={`${idPrefix}beneficiaryCategory`} placeholder={getCategoryTitle()} label={displayText.beneficiaryCategory} type={'text'} readOnly={true}/>
          </Col>
          <Col>
            <SelectInput field={`${idPrefix}beneficiaryType`} label={displayText.beneficiaryType} aria-label={displayText.beneficiaryType} placeholderText={displayText.beneficiaryTypePlaceholder} validateOnBlur={true} options={experienceContext.language === Language.SPANISH ? BDM_BENEFICIARY_TYPES_ES : BDM_BENEFICIARY_TYPES} initialValue={beneficiaryTypeState} onChange={handleTypeChange}/>
          </Col>
          {!toggleButtonState && rerenderAllocationInModal ? (<Col>
              <TextInput style={{ width: '3.75rem', minWidth: '2.5rem' }} field={`${idPrefix}allocation`} label={displayText.allocation} mask={numbersOnly} maxLength={3} validate={BdmBeneficiaryUtils.validateAllocationInModal} validateOnBlur={true} type={'text'} initialValue={beneficiary.allocation ? beneficiary.allocation : ''}/>
            </Col>) : (<Col>
              <StyledTextInput field={`${idPrefix}equalAllocation`} label={displayText.allocation} type={'text'} readOnly={true} placeholder={displayText.equal}/>
            </Col>)}
        </StyledRow>
        <BdmModalContent beneficiaryType={beneficiaryTypeState} beneficiaryCategory={beneficiaryCategory} beneficiary={beneficiaryAtTypeChange ? beneficiaryAtTypeChange : beneficiary} beneficiaryIndex={beneficiaryIndex}/>
      </StyledModal>
      <RenderOpenModalButton showModal={open}/>
    </>);
};
export default BdmModalEditBeneficiary;
