import React, { useState } from 'react';
import { Alert, Button, Col, Container, Heading, Icon, Label, Link, Loader, NewModal as Modal, Row } from '@digitools/honest-react';
import { Language } from '../../types/Language';
import { DocumentUploadDisplayTextEs } from './language/DocumentUploadDisplayTextEs';
import { DocumentUploadDisplayTextEn } from './language/DocumentUploadDisplayTextEn';
import axios from 'axios';
import styled from 'styled-components';
import { getTraceId } from '../../utils/axiosUtils';
import useAuthentication from '../../hooks/useAuthentication';
import useExperience from '../../hooks/useExperience';
import useTealium from '../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../constants/tealiumConstants';
import { StyledButton } from '../Formatting/StyledComponents';
import { useDisclosure } from '@mantine/hooks';
const StyledCol = styled(Col) `
  border: 0.5px solid lightgrey;
  padding: 12px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
`;
const StyledLink = styled(Link) `
  font-weight: bold;
`;
const StyledFileInput = styled('input') `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
const supportedFiles = ['gif', 'jpg', 'pdf', 'pptx', 'tif', 'tiff', 'xls', 'xlsx', 'doc', 'docx'];
const DocumentUpload = (props) => {
    const { authHeader } = useAuthentication();
    const { language, experience } = useExperience();
    const { trackEvent } = useTealium();
    const languageFile = language === Language.SPANISH ? DocumentUploadDisplayTextEs : DocumentUploadDisplayTextEn;
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedDocumentsCount, setUploadedDocumentsCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [uploading, setUploading] = useState(false);
    // handle opening the modal when navigating here clicking an upload button
    const openOnLoad = window.sessionStorage.getItem('statusNav') === 'upload';
    const RenderOpenButton = ({ showModal }) => {
        const handleOnClick = () => {
            showModal();
        };
        return (<>
        {props.renderAsLink && (<div style={{ whiteSpace: 'nowrap' }} onClick={handleOnClick}>
            <Icon name='upload' color={'secondary'} style={{ paddingRight: '.25rem' }}/>
            <Link style={{ fontSize: '12px' }} data-testid='openModal' onClick={handleOnClick}>
              {languageFile.uploadLabels}
            </Link>
          </div>)}

        {!props.renderAsLink && ((props.isNewCard ? (<StyledButton buttonType={props.displayLabel ? 'secondary' : 'text'} size={'medium'} type={'button'} onClick={handleOnClick} data-testid={'openModal'} key={'openModal' + props.eventNum} aria-label={languageFile.uploadLabel}>
              <Icon name='upload' color={'secondary'} style={{ paddingRight: '.25rem' }}/>
              {props.displayLabel && languageFile.uploadLabel}
            </StyledButton>) : (<Button buttonType={props.displayLabel ? 'secondary' : 'text'} className={`${props.productName === 'STD' || props.productName === 'LEAVE' ? '' : 'w-100'}  w-md-auto'}`} size={'medium'} type={'button'} onClick={handleOnClick} data-testid={'openModal'} key={'openModal' + props.eventNum} aria-label={languageFile.uploadLabel} style={{ textTransform: 'inherit' }}>
              <Icon name='upload' color={'secondary'} style={{ paddingRight: '.25rem' }}/>
              {props.displayLabel && languageFile.uploadLabel}
            </Button>)))}
      </>);
    };
    const getProduct = () => {
        if (props.productName === 'Leave' || props.productName === 'LEAVE') {
            return languageFile.leave;
        }
        else if (props.productName === 'AS') {
            return languageFile.asEvent;
        }
        else {
            return languageFile.claim;
        }
    };
    const onUpload = (event) => {
        if (event.target.files.length >= 1) {
            const selectedFile = event.target.files[0];
            const fileName = selectedFile.name;
            if (fileName.match(/^[a-zA-Z0-9_.\s-]*$/)) {
                const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
                if (supportedFiles.indexOf(fileType.toLocaleLowerCase()) >= 0) {
                    if (selectedFile.size <= 6291456 && selectedFile.size !== 0) {
                        uploadDocument(selectedFile);
                        event.target.value = null;
                    }
                    else if (selectedFile.size === 0) {
                        setErrorMessage(languageFile.uploadFileEmpty);
                    }
                    else {
                        setErrorMessage(languageFile.uploadSizeExceeded);
                    }
                }
                else {
                    setErrorMessage(languageFile.uploadFileTypeInvalid);
                }
            }
            else {
                setErrorMessage(languageFile.uploadFilenameInvalid);
            }
        }
    };
    const uploadDocument = async (file) => {
        try {
            setUploading(true);
            const response = await axios.post(`/mlp-ui-service/api/documents/upload`, getFormData(file, props.productName.toUpperCase()), {
                headers: {
                    Authorization: authHeader(),
                },
            });
            setUploadedFiles(prev => [...prev, { documentName: file.name, documentId: response.data }]);
            setUploadedDocumentsCount(0);
            setErrorMessage('');
            setUploading(false);
        }
        catch (error) {
            setUploading(false);
            setErrorMessage(`${languageFile.failureMessage}${getTraceId(error)}`);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: 'confirmation',
                event_name: languageFile.failureMessage,
                event_version: 'upload document',
            });
        }
    };
    const getFormData = (file, interviewType) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('interviewType', interviewType);
        formData.append('experience', experience);
        return formData;
    };
    const renderColumns = () => {
        return (<>
        <Col md={6}>
          {' '}
          {uploadedFiles.map((documentDetail, index) => {
                if (index <= 4)
                    return getSelectedDocuments(documentDetail);
            })}{' '}
        </Col>

        <Col md={6}>
          {' '}
          {uploadedFiles.map((documentDetail, index) => {
                if (index > 4)
                    return getSelectedDocuments(documentDetail);
            })}{' '}
        </Col>
      </>);
    };
    const getSelectedDocuments = (documentDetail) => {
        const handleDelete = () => {
            const newDocumentList = uploadedFiles.filter(file => file.documentId !== documentDetail.documentId);
            setUploadedFiles(newDocumentList);
        };
        return (<>
        {' '}
        <Row key={documentDetail.documentId}>
          <StyledCol md={8}>
            <Row data-testid={'submitted-doc'} aria-labelledby={documentDetail.documentName}>
              <Icon style={{ margin: '2px .5rem 0 1rem' }} name='documents'/> {documentDetail.documentName}
            </Row>
          </StyledCol>
          <StyledCol md={3}>
            <StyledLink data-testid={'delete-doc'} aria-label={languageFile.delete} onClick={handleDelete}>
              <Icon color='secondary' name='trash'/> {languageFile.delete}
            </StyledLink>
          </StyledCol>
        </Row>
      </>);
    };
    const submitUploadedDocuments = async () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_name: 'submit',
            event_version: 'details',
            event_results: uploadedFiles.length.toString()
        });
        setSubmitting(true);
        const documentIds = uploadedFiles.map(file => file.documentId);
        const url = '/mlp-ui-service/api/documents/forward?submissionId=' + props.eventNum + '&documentIds=' + documentIds.toString();
        try {
            await axios.post(url, null, { headers: { Authorization: authHeader() } });
            setUploadedDocumentsCount(uploadedFiles.length);
            clearStates();
        }
        catch (error) {
            setSubmitting(false);
            setErrorMessage(`${languageFile.failureMessage}${getTraceId(error)}`);
        }
    };
    const clearStates = () => {
        setUploadedFiles([]);
        setErrorMessage('');
        setSubmitting(false);
        setUploading(false);
    };
    const [isOpen, { open, close }] = useDisclosure(false);
    return (<>
      <Modal data-testid={'uploadModal'} centered={true} title={<Heading color={'primaryDark'} elemStyle={'h1'} elemType={'h1'} style={{ lineHeight: 'normal' }}>
            {`${languageFile.headerText} ${getProduct()} #${props.eventNum}`}
          </Heading>} submitButtonProps={{
            'data-testid': 'submit',
            buttonType: 'primary',
            disabled: uploadedFiles.length < 1 || submitting || uploading,
            'aria-label': 'submit document upload'
        }} submitButtonText={`${languageFile.submitText}`} onSubmit={submitUploadedDocuments} cancelButtonText={`${uploadedDocumentsCount > 0 ? languageFile.closeText : languageFile.cancelText}`} onClose={() => {
            //reset this nav token so stray nav's won't re-trigger
            window.sessionStorage.setItem('statusNav', '');
            clearStates();
            close();
        }} cancelButtonProps={{
            'data-testid': 'cancel',
            buttonType: 'secondary',
            'aria-label': 'cancel document upload'
        }} size={'1100px'} opened={openOnLoad || isOpen}>
        <Loader id='document-upload-spinner' isShown={submitting || uploading}>
          <Container>
            {uploadedDocumentsCount > 0 && (<Alert data-testid={'success-alert'} type={'success'} aria-labelledby='success-alert'>
                {uploadedDocumentsCount} {languageFile.successMessage}
              </Alert>)}
            {errorMessage !== '' && (<Alert type={'error'} data-testid={'error-alert'}>
                <div>
                  {/* REVERTING CHANGE FROM OGS-3128 */}
                  {/* {t('claimErrorMessage')}
            <a href='/customer/home/contacts'>{t('contactUs')}</a> */}
                  {errorMessage}
                </div>
              </Alert>)}
            <Row data-testid={'informationText'}>
              {languageFile.informationalMessage1}
            </Row>
            <Row data-testid={'acceptableDocumentsText'}>
              {languageFile.informationalMessage2}
            </Row>
            {uploadedFiles.length < 10 && (<Row>
                <Col md={4}>
                  <Label htmlFor={'file-upload' + props.eventNum}>
                    <Icon color={'secondary'} name={'upload'} size={'medium'}/>
                    <Link style={{ marginLeft: '.5rem', fontSize: '16px', fontWeight: 'bold' }} data-testid={'uploadButton'}>
                      {languageFile.uploadButtonText}
                    </Link>
                  </Label>
                  <StyledFileInput data-testid={'fileUpload' + props.eventNum} type={'file'} id={'file-upload' + props.eventNum} onChange={onUpload} accept={'.gif, .jpg, .pdf, .pptx, .tif, .tiff, .xls, .xlsx, .doc, .docx'}/>
                </Col>
              </Row>)}
            <Row>{renderColumns()}</Row>
          </Container>
        </Loader>
      </Modal>
      <RenderOpenButton showModal={open}/>
    </>);
};
export default DocumentUpload;
