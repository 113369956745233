import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
function stringifyUploads(uploadedFiles) {
    let dataTypes = uploadedFiles.map((data) => data.label);
    let dataTypeString = '';
    const dataTypeCounts = {};
    if (Object.values(dataTypes).length) {
        dataTypes.forEach((element) => {
            dataTypeCounts[element] = (dataTypeCounts[element] || 0) + 1;
        });
        dataTypeString = 'gp_upload,';
        Object.keys(dataTypeCounts).forEach(data => {
            dataTypeString += data + ',' + dataTypeCounts[data] + ',';
        });
        dataTypeString = dataTypeString.replace(/,$/, '');
    }
    return dataTypeString;
}
function translateEvent(page = '', event = '', status = '', message, data) {
    return eventTranslations[[page, event, status].join(',')](message, data);
}
export { stringifyUploads, translateEvent };
const eventTranslations = {
    "details,upload,error": (message, data) => {
        return {
            event_name: `${message}`,
            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_version: TEALIUM_EVENT.EVENT_VERSION.UPLOAD,
        };
    },
    "confirmation,upload,error": (message, data) => {
        return {
            event_name: `${message}`,
            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
            event_version: TEALIUM_EVENT.EVENT_VERSION.UPLOAD,
        };
    },
    "details,submit,success": (message, data) => {
        return {
            event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_name: TEALIUM_EVENT.EVENT_NAME.SUBMIT,
            event_results: `${message}`,
            form_result: `${data}`,
            event_version: 'details',
        };
    },
    "confirmation,submit,success": (message, data) => {
        return {
            event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
            event_name: TEALIUM_EVENT.EVENT_NAME.SUBMIT,
            event_results: `${message}`,
            form_result: `${data}`,
            event_version: TEALIUM_EVENT.EVENT_VERSION.CONFIRMATION,
        };
    },
    "details,submit,failure": (message, data) => {
        return {
            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_name: `${message}`,
            event_version: TEALIUM_EVENT.EVENT_VERSION.UPLOAD,
        };
    },
    "confirmation,submit,failure": (message, data) => {
        return {
            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
            event_name: `${message}`,
            event_version: TEALIUM_EVENT.EVENT_VERSION.UPLOAD,
        };
    },
    "details,submit,error": (message, data) => {
        return {
            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_name: `${message}`,
            event_version: 'upload doc - details',
        };
    },
    "confirmation,submit,error": (message, data) => {
        return {
            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
            event_name: `${message}`,
            event_version: 'upload doc',
        };
    },
};
