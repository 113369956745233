import { Box, Col, Link, Paginator, Radio, RadioGroup, Row, Search, SearchResult, TextInput, } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import useTealium from '../../../hooks/useTealium';
import RemoveUserModal from './RemoveUserModal';
import useAuthentication from 'src/hooks/useAuthentication';
const StyledCustomerContainer = styled('div') `
  margin-top: 2rem;
`;
const StyledTitle = styled('div') `
  padding: 0 16px;
  font-weight: 900;
  margin-bottom: 1rem;
`;
const NoResults = styled('div') `
  padding: 0 16px;
  color: rgb(126, 134, 153);
`;
const StyledPaginatorContainer = styled('div') `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledBox = styled(Box) `
  margin-top: 0;
  border-radius: 0;
  border: none;
  align-self: stretch;
  &:nth-child(even) {
    background-color: rgb(244, 243, 244);
  }
`;
const UserSearch = (props) => {
    const { trackEvent } = useTealium();
    const [filteredUserList, setFilteredUserList] = useState(props.users);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchString, setSearchString] = useState(false);
    const { user } = useAuthentication();
    useEffect(() => {
        setFilteredUserList(props.users);
    }, [props.showAllIndicator]);
    const filterResultsCustomSearch = (query) => {
        const filteredList = props.users.filter((user) => {
            if (query.target.value) {
                setSearchString(true);
                setCurrentPage(0);
                const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
                const searchData = `${fullName} (${user.email})`.toLowerCase();
                return searchData.includes(query.target.value.toLowerCase());
            }
            else {
                setSearchString(false);
                return props.users;
            }
        });
        if (filteredList.length === 0) {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.CHANGE,
                event_type: TEALIUM_EVENT.EVENT_TYPE.ADMIN_USER_SEARCH,
                event_name: 'No user accounts matched',
            });
        }
        setFilteredUserList(filteredList);
        return filteredList;
    };
    const filterResults = async (query) => {
        if (query) {
            const filteredResults = props.users.filter((user) => {
                const userData = `${user.firstName} ${user.lastName} ${user.email}`.toLowerCase();
                return userData.includes(query);
            });
            return filteredResults;
        }
        return Promise.resolve([]);
    };
    const EmptyResults = () => (<SearchResult>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>No Results!</span>
      </div>
    </SearchResult>);
    const Result = ({ result }) => {
        return (<SearchResult key={`SearchResultItem-${result.userId}`} onClick={() => handleUser(result)}>
        <span>{`${result.firstName} ${result.lastName} (${result.email})`}</span>
      </SearchResult>);
    };
    const PagedUsers = ({ pagedData }) => {
        return (<>
        {pagedData.map((item, idx) => {
                return (<StyledBox key={idx} data-testid={`user-${idx}`}>
              <Row>
                <Col md={6} lg={6}>
                  <Link onClick={() => {
                        props.handleSelectUser(item);
                        setFilteredUserList([]);
                        setSearchString(false);
                    }} data-testid={`user-${idx}-link`}>
                    {`${item.lastName}, ${item.firstName} (${item.email})`}
                  </Link>
                </Col>
                <Col md={6} lg={6} style={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end' }}>
                  {(props.removeUser && user && !user?.ssoUser) && <RemoveUserModal removeSelectUser={props.removeUser} user={item}/>}
                </Col>
              </Row>
            </StyledBox>);
            })}
      </>);
    };
    const PagedUsersCopy = ({ pagedData }) => {
        return (<>
        <RadioGroup label={' '} field={'userId'}>
          <div role={'list'}>
            {pagedData.map((item, idx) => {
                return (<>
                  {props.selectedUser !== undefined && props.selectedUser.userId !== item.userId && (<>
                      <StyledBox key={idx} data-testid={`user-${idx}`} role={'listitem'}>
                        <Row>
                          <Col md={6} lg={6}>
                            <Radio labelStyle={{ display: 'block', width: '32vw' }} name={'userId'} id={item.userId} label={`${item.lastName}, ${item.firstName} (${item.email})`} value={item}/>{' '}
                          </Col>
                        </Row>
                      </StyledBox>
                    </>)}
                </>);
            })}
          </div>
        </RadioGroup>
      </>);
    };
    const handleUser = (item) => {
        if (item !== undefined) {
            props.handleSelectUser(item);
            setFilteredUserList(props.users);
            setSearchString(false);
        }
    };
    const handleShowAll = () => {
        setFilteredUserList(props.users);
        setSearchString(false);
        props.showAllUsers();
    };
    return (<>
      {props.showAllIndicator ? (<>
          {props.users.length === 0 ? (<p data-testid='NoUsersFound' aria-labelledby='NoUsersFound' data-public={true}>
              No Users Found
            </p>) : (<>
              <TextInput field={'user-name'} label={'user-name'} role={'textbox'} hideLabel={true} placeholder={'search by user name'} onChange={filterResultsCustomSearch} icon={'search'} aria-label='User Name' data-testid={'search-input'} data-public={true}/>
              <StyledCustomerContainer id={'user-list'} aria-label={'User List'} data-testid={'user-list'} data-public={true}>
                {props.showCopyPermissionsTable ? null : (<StyledTitle id='AllUsers' aria-labelledby='AllUsers'>
                    {'All Users'}
                  </StyledTitle>)}

                {filteredUserList.length === 0 ? (<NoResults data-testid={'NoResults'} id='NoResults' aria-labelledby='NoResults'>
                    {'No Results Found'}
                  </NoResults>) : (<StyledPaginatorContainer>
                    <Paginator data={filteredUserList} renderContent={props.showCopyPermissionsTable ? PagedUsersCopy : PagedUsers} managed={false} rowsPerPage={rowsPerPage} currentPage={currentPage} setRowsPerPage={(selected) => setRowsPerPage(selected)} setCurrentPage={setCurrentPage}/>
                  </StyledPaginatorContainer>)}
              </StyledCustomerContainer>
            </>)}
        </>) : (<Box color={'grayLighter'} style={{ marginBottom: '2rem', marginTop: '2rem', zIndex: 2 }} data-public={true}>
          <Row style={{ marginBottom: '5px' }}>
            <Col sm={6} md={6} lg={6} id='SearchUsers' aria-labelledby='SearchUsers'>
              <strong>Search Users</strong>
            </Col>
            <Col sm={6} md={6} lg={6}>
              <Link style={{ display: 'flex', justifyContent: 'flex-end' }} aria-label='View All Users' onClick={handleShowAll}>
                View All Users
              </Link>
            </Col>
          </Row>
          <Search label='User Search' field='' role={'searchbox'} emptyResultComponent={EmptyResults} resultComponent={({ result }) => <Result result={result}/>} placeholder={'Enter User Name'} data-testid={'search-input-new'} aria-label='User Search' onQueryChange={filterResults}/>
          {searchString && filteredUserList.length === 0 ? <NoResults>{'No Results Found'}</NoResults> : <></>}
        </Box>)}
    </>);
};
export default UserSearch;
